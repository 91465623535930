import {
  Snackbar,
  Button,
  LinearProgress,
  Typography,
  Alert,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import MainCard from "../MainCard";
import SubCard from "../SubCard";
import { IoMdArrowBack } from "react-icons/io";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import FieldsTable from "./FieldsTable";
import { GetAllRequest, ProvideApproval } from "../../Axios/Approval/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getStatus, postData } from "../../Axios/Leegality/api";
import Chip from "@mui/material/Chip";
import Paginate from "./Paginate";

function LoanApplicationForm() {
  // const style={width:'100%',display:'flex',gap:'30px',flexDirection:'row'}

  const [data, setData] = useState(null);
  const [value, setValue] = React.useState(null);
  const [Selected, setSelected] = React.useState(null);
  const [selectedProducts, setSelectedProducts] = React.useState(null);
  const [PostData, setPostData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const userType = sessionStorage.getItem("user_type");
  const user_id = sessionStorage.getItem("user_id");
  const [clickedIndex, setClickecIndex] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusid, setStatusid] = useState(null);
  const [currentName, setCurrentName] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [ActiveIndex, setActiveIndex] = useState(0);

  const smStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
    flexDirection: "column",
  };

  const mdLgXlStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
    flexDirection: "row",
  };

  const admin_token = sessionStorage.getItem("admin_token");

  // //console.log('Selected ', selectedProducts);

  // //console.log('value ', value);

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      //console.log("data ", data);
      // Selected.forEach(element => {
      //     //console.log(element);
      //     setRows(prevRows => [...prevRows, createData(element)]);
      // });
    }
  }, [data]);

  // useEffect(() => {
  //     GetAllRequest(setData, setIsLoading,0,'loan')
  // }, [])

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    if (data) {
      const filtered = data.data.filter(getApproved);
      setFilteredData(filtered);
    }
  }, [data]);

  const getApproved = (item) => {
    if (item.application_status === "approved") {
      return item;
    }
  };

  const handlePostData = (index) => {
    if (filteredData) {
      postData(filteredData[index]);
    }
  };

  const handleCheckStatues = (id, name) => {
    setCurrentName(name);
    getStatus(id, setStatus, handleStatusErr);
    setStatusid(id);
  };

  const [StatusErr, setStatusErr] = useState(false);

  const handleStatusErr = () => {
    setStatusErr(true);
  };

  const handleresClose = () => {
    setStatusErr(false);
  };

  const handleClose = () => {
    setSelected(null);
    setRows([]);
    setSelectedProducts(null);
    setValue(null);
    setPostData(null);
  };

  const fetchStatus = async (application_id, document_id) => {
    setClickecIndex(null);
    try {
      await getStatus(document_id).then((res) => {
        if (res.status === 200) {
          setClickecIndex(application_id);
          setStatus(res.data);
        } else {
          setClickecIndex(null);
        }
      });
    } catch (err) {
      //console.log(err)
    }
  };

  // //console.log("type ", userType);
  // //console.log("id ", user_id);

  useEffect(() => {
    GetAllRequest(setData, setIsLoading, ActiveIndex, "approved");
  }, [ActiveIndex]);

  const handlePaginate = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (PostData !== null)
      ProvideApproval(
        value._id,
        selectedProducts.product_id,
        user_id,
        PostData,
        setData,
        handleClose
      );
  }, [PostData]);

  const getTheUserStatus = (item) => {
    return item.name === currentName;
  };

  const findSignStatus = async () => {
    //console.log(status.data);
    let user = await status.data.find(getTheUserStatus);
    if (user) {
      setUserStatus(user.sign_status);
    }
    //console.log("status",user.sign_status);
  };

  useEffect(() => {
    if (status) findSignStatus();
  }, [status]);

  const handleReject = () => {
    if (userType === "Distributor") {
      setPostData({
        ds_id: user_id,
        ds_permission: "reject",
      });
    } else if (userType === "Teritiary_Manager") {
      setPostData({
        tm_id: user_id,
        tm_permission: "reject",
      });
    }
  };
  if (admin_token) {
    return isLoading ? (
      <LinearProgress />
    ) : (
      <div style={{ backgroundColor: "#EEF2F6", paddingBlock: "10px" }}>
        <MainCard
          sx={{ margin: "30px", height: "70vh", overflowY: "scroll" }}
          title="Loan Application"
        >
          <Snackbar
            open={StatusErr}
            // autoHideDuration={5000}
            onClose={handleresClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleresClose}
              variant="filled"
              severity="error"
              sx={{ width: "100%" }}
            >
              Something went wrong.
            </Alert>
          </Snackbar>
          {data && data.data.length > 0 ? (
            <div style={window.innerWidth >= 600 ? mdLgXlStyles : smStyles}>
              <div style={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Loan Id</TableCell>
                        <TableCell align="right">Application Status</TableCell>
                        <TableCell align="right">Loan Amount</TableCell>
                        <TableCell align="right">Loan Term</TableCell>
                        <TableCell align="right">
                          Loan Sanction & Agreement
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData &&
                        filteredData.map((item, index) => {
                          return item.application_status === "approved" ? (
                            <TableRow
                              key={item.application_status}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {item.loanid}
                              </TableCell>
                              <TableCell align="right">
                                {item.application_status}
                              </TableCell>
                              <TableCell align="right">
                                {item.loanamount}
                              </TableCell>
                              <TableCell align="right">
                                {item.tenure} months
                              </TableCell>
                              <TableCell align="right">
                                {item.legality_doc_sign_status ? (
                                  <Chip label="Signed" color="success" />
                                ) : item.application_status === "approved" &&
                                  !item.legality_doc_sign_status &&
                                  item.document_id &&
                                  statusid !== item.document_id ? (
                                  <Button
                                    onClick={() =>
                                      handleCheckStatues(
                                        item.document_id,
                                        item.name
                                      )
                                    }
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    sx={{ width: "8rem" }}
                                  >
                                    Check Status
                                  </Button>
                                ) : item.application_status === "approved" &&
                                  !item.legality_doc_sign_status &&
                                  status &&
                                  !userStatus &&
                                  item.document_id === statusid ? (
                                  <Button
                                    onClick={() =>
                                      handleCheckStatues(
                                        item.document_id,
                                        item.name
                                      )
                                    }
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    sx={{ width: "14rem" }}
                                  >
                                    Action Pending - User
                                  </Button>
                                ) : item.application_status === "approved" &&
                                  !item.legality_doc_sign_status &&
                                  status &&
                                  userStatus &&
                                  item.document_id === statusid ? (
                                  <Button
                                    onClick={() =>
                                      handleCheckStatues(
                                        item.document_id,
                                        item.name
                                      )
                                    }
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    sx={{ width: "14rem" }}
                                  >
                                    Action Pending - Admin
                                  </Button>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      color:
                                        item.application_status === "approved"
                                          ? "green"
                                          : item.application_status ===
                                            "pending"
                                          ? "#ed9f0e"
                                          : "red",
                                      fontWeight: 550,
                                    }}
                                  >
                                    {item.application_status === "pending"
                                      ? "Application pending"
                                      : item.application_status === "rejected"
                                      ? "Application Rejected"
                                      : ""}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ) : null;
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Paginate
                  data={data ? data.total_count : 0}
                  fun={handlePaginate}
                  ActiveIndex={ActiveIndex}
                />
              </div>
            </div>
          ) : (
            <Typography variant="subtitle1" color="grey">
              No data
            </Typography>
          )}
        </MainCard>
      </div>
    );
  } else window.location.href = "/AdminLogin";
}

export default LoanApplicationForm;
