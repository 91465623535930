import React, { useState } from "react";

// material-ui
// import { useTheme } from '@mui/material/styles';
import {
  Divider,
  Grid,
  // Stack, Typography, Link, useMediaQuery
} from "@mui/material";

// project imports
import AuthWrapper1 from "../AuthWrapper1";
import AuthCardWrapper from "../AuthCardWrapper";
import ExistingDetails from "../FormFields/ExistingDetails";
import BankDetails from "../FormFields/BankDetails";
import LoanDetails from "../FormFields/LoanDetails";
// import AloanDetails from '../FormFields/A-LoanDetails';

const Index = (props) => {
  // const theme = useTheme();
  // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  // const [Postdata,setPostData]=useState({})
  // //console.log("post data", Postdata);

  return (
    <AuthWrapper1>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "85vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(85vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  {/* <Grid item sx={{}}>
                    <Link to="#" underline="none">
                      <Logo />
                      <Typography sx={{color:"#A556B2"}} gutterBottom variant= 'h5'>
                        FI Lending
                      </Typography>
                    </Link>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography sx={{color:"#121926"}} gutterBottom variant='h6'>
                            Enter your Details of the Borrower(s)
                          </Typography>
                          <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                            Enter your credentials to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12}>
                    {/* <LoanDetails setPostData={props.setPostData} Postdata={props.Postdata}/> */}
                    {/* <Divider /> */}
                    <br />
                    {/* <BankDetails setPostData={props.setPostData} Postdata={props.Postdata}/> */}
                    {/* <Divider /> */}
                    <br />
                    <ExistingDetails />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid
                      item
                      container
                      direction="column"
                      xs={12}
                      sx={{ display: "flex" }}
                    ></Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          {/* <AuthFooter /> */}
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Index;
