import React from "react";
import not_found from "././FormStructure.js/images/500 Internal Server Error-amico.png";
import { Stack, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Stack width="100%" justifyContent="center" alignItems="center">
      <Box sx={{ width: "35%" }}>
        <img
          style={{ aspectRatio: "1/1", width: "100%", objectFit: "contain" }}
          src={not_found}
          alt="404"
        />
      </Box>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={() => navigate(-1)}
      >
        back
      </Button>
    </Stack>
  );
}

export default NotFoundPage;
