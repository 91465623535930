import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Button, Typography, TextField, Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import * as XLSX from 'xlsx'; // For handling Excel files
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios'; // Import axios for API calls

export default function ExcelFormcomp() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fileName, setFileName] = useState('');
  const [filters, setFilters] = useState({
    name: '',
    phone_number: '',
    pin_code: '',
    pan_number: '',
  });
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [customFileName, setCustomFileName] = useState('ExportedData.txt');
  const [textPreview, setTextPreview] = useState('');

  // Define the download column outside of the useEffect to prevent re-creation
  const downloadColumn = {
    field: 'download',
    headerName: 'Download',
    width: 100,
    renderCell: (params) => (
      <DownloadIcon
        style={{ cursor: 'pointer', color: '#1976d2' }}
        onClick={() => handleExportRow(params.row)}
      />
    ),
  };

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('API ENDPOINT'); // Replace with your API endpoint
        const jsonData = response.data;

        if (jsonData.length > 0) {
          const newColumns = Object.keys(jsonData[0]).map((key) => ({
            field: key,
            headerName: key.replace(/([A-Z])/g, ' $1').trim(),
            width: 150,
          }));
          setColumns([...newColumns, downloadColumn]);

          const uploadedRows = jsonData.map((row, index) => ({
            id: index + 1,
            ...row,
            phone_number: row.phone_number ? row.phone_number.toString() : '',
            pin_code: row.pin_code ? row.pin_code.toString() : '',
            pan_number: row.pan_number || '',
          }));
          setRows(uploadedRows);
        }
      } catch (error) {
        console.error("Error fetching data from the API:", error);
      }
    };

    fetchData();
  }, []); // Run only once when the component mounts

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file || !['.xlsx', '.xls'].some(ext => file.name.endsWith(ext))) {
      alert('Please upload a valid Excel file (.xlsx or .xls)');
      return;
    }

    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });

      if (jsonData.length > 0) {
        const newColumns = Object.keys(jsonData[0]).map((key) => ({
          field: key,
          headerName: key.replace(/([A-Z])/g, ' $1').trim(),
          width: 150,
        }));
        setColumns([...newColumns, downloadColumn]);

        const uploadedRows = jsonData.map((row, index) => ({
          id: index + 1,
          ...row,
          phone_number: row.phone_number ? row.phone_number.toString() : '',
          pin_code: row.pin_code ? row.pin_code.toString() : '',
          pan_number: row.pan_number || '',
        }));
        setRows(uploadedRows);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExportRow = (row) => {
    const dataToExport = [row];
    exportToExcel(dataToExport, `RowData_${row.id}.xlsx`);
  };

  const handleOpenExportDialog = () => {
    const textContent = convertToText(filteredRows);
    setTextPreview(textContent);
    setExportDialogOpen(true);
  };

  const handleExportToText = () => {
    downloadTextFile(textPreview, customFileName);
    setExportDialogOpen(false);
  };

  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  const convertToText = (data) => {
    if (data.length === 0) return '';

    const headers = Object.keys(data[0]);
    const headerLine = headers.map((header) => header.padEnd(20)).join('\t');
    const separator = '-'.repeat(headerLine.length);

    const rows = data.map((row) =>
      headers.map((key) => String(row[key] || '').padEnd(20)).join('\t')
    );

    return [headerLine, separator, ...rows].join('\n');
  };

  const downloadTextFile = (content, fileName) => {
    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const filteredRows = rows.filter((row) =>
    Object.keys(filters).every((key) => {
      const filterValue = filters[key].toLowerCase();
      const rowValue = (row[key]?.toString() || '').toLowerCase();
      return filterValue ? rowValue.includes(filterValue) : true;
    })
  );

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    setFilters({
      name: '',
      phone_number: '',
      pin_code: '',
      pan_number: '',
    });
  };

  const styles = {
    container: { maxWidth: '1200px', margin: 'auto', padding: '20px' },
    uploadContainer: { padding: '20px', backgroundColor: '#e3f2fd', borderRadius: '8px', marginBottom: '20px' },
    textFieldContainer: {
      display: 'flex', gap: '15px', marginBottom: '20px', backgroundColor: '#fafafa',
      padding: '15px', borderRadius: '8px', alignItems: 'center', flexWrap: 'wrap',
    },
    dataGridContainer: { height: 400, maxWidth: '1200px', marginTop: '10px', padding: '10px', backgroundColor: '#ffffff' },
    noData: { textAlign: 'center', marginTop: '20px', color: '#757575' },
  };

  return (
    <div style={styles.container}>
      <Typography variant="h4" gutterBottom color="primary" align="center" fontWeight="bold">
        Data Management
      </Typography>

      <Paper elevation={3} style={styles.uploadContainer}>
        <Typography variant="h6" gutterBottom>
          Upload your Excel file (.xlsx or .xls)
        </Typography>
        <Button variant="outlined" component="label" startIcon={<UploadFileIcon />} color="secondary">
          Upload Excel
          <input type="file" hidden accept=".xlsx, .xls" onChange={handleFileUpload} />
        </Button>
        {fileName && (
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
            Uploaded File: {fileName}
          </Typography>
        )}
      </Paper>

      {rows.length > 0 && (
        <Box style={styles.textFieldContainer}>
          {Object.keys(filters).map((filterKey) => (
            <TextField
              key={filterKey}
              label={filterKey.replace(/([A-Z])/g, ' $1')}
              variant="outlined"
              size="small"
              name={filterKey}
              value={filters[filterKey]}
              onChange={handleFilterChange}
              sx={{ flex: 1, minWidth: 150, maxWidth: 'calc(25% - 16px)' }}
            />
          ))}
          <Button variant="outlined" color="error" onClick={clearFilters}>
            Clear Filters
          </Button>
        </Box>
      )}

      <Paper elevation={3} style={styles.dataGridContainer}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <Typography variant="h6">Data Table</Typography>
          <Button variant="outlined" color="primary" onClick={handleOpenExportDialog}>
            Export To Text
          </Button>
        </Box>

        <div style={{ height: 300 }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            getRowId={(row) => row.id}
            autoHeight
          />
        </div>

        {filteredRows.length === 0 && (
          <Typography style={styles.noData} variant="body1">
            No data available.
          </Typography>
        )}
      </Paper>

      <Dialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)}>
        <DialogTitle>Export Data</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            You can export the filtered data as a text file.
          </Typography>
          <TextField
            label="Custom File Name"
            variant="outlined"
            value={customFileName}
            onChange={(e) => setCustomFileName(e.target.value)}
            fullWidth
          />
          <Typography variant="body1" style={{ marginTop: '15px' }}>
            Preview:
          </Typography>
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {textPreview}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setExportDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleExportToText} color="primary">
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
