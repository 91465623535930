import * as React from "react";
import MainCard from "../MainCard";
import SideMenu from "../Approval/SideMenu";

const ApprovalLayout = () => {
  return (
    <MainCard sx={{ height: "100vh", overflowX: "scroll" }}>
      <SideMenu />
    </MainCard>
  );
};

export default ApprovalLayout;
