import { Alert, Button, Chip, Snackbar, Typography, } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import MainCard from "../../MainCard";
import SubCard from "../../SubCard";
import { IoMdArrowBack } from "react-icons/io";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { GetAllRequest, ProvideApproval } from "../../../Axios/Approval/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getStatus, postData } from "../../../Axios/Leegality/api";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function LoanApplicationFormUser(props) {
  const [filteredData, setFilteredData] = useState(null);
  const [status, setStatus] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [statusid, setStatusid] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [rejectedReason, setRejectedReason] = useState(null);

  const smStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
    flexDirection: "column",
  };

  const mdLgXlStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
    flexDirection: "row",
  };

  const handleClickOpen = (reason) => {
    console.log(reason);
    setRejectedReason(reason.split("\n"));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user_token = sessionStorage.getItem("user_token");

  useEffect(() => {
    if (props.data) {
      const filtered = props.data.filter(getApproved);
      setFilteredData(filtered);
    }
  }, [props.data]);

  //get unique data from all data

  const getApproved = (item) => {
    if (
      item.application_status === "approved" &&
      item.phone === sessionStorage.getItem("mobile_no")
    ) {
      return item;
    }
  };

  const getTheUserStatus = (item) => {
    return item.name === sessionStorage.getItem("user_name");
  };

  const findSignStatus = async () => {
    //console.log(status.data);
    let user = await status.data.find(getTheUserStatus);
    if (user) {
      setUserStatus(user.sign_status);
    }
    //console.log("status",user.sign_status);
  };

  const handlePostData = (index) => {
    if (props.data) {
      postData(props.data[index]);
    }
  };

  useEffect(() => {
    if (status) {
      findSignStatus();
    }
  }, [status]);

  const [StatusErr,setStatusErr] = useState(false)
  const handleCheckStatues = (id) => {
    getStatus(id, setStatus,handleStatusErr);
    setStatusid(id);
  };

  const handleStatusErr = () => {
    setStatusErr(true)
  }

  const handleresClose = () => {
    setStatusErr(false)
  }

  if (user_token) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          paddingBlock: "10px",
          height: "50vh",
          overflowY: "scroll",
        }}
      >
        <Snackbar
          open={StatusErr}
          // autoHideDuration={5000}
          onClose={handleresClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleresClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong.
          </Alert>
        </Snackbar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Rejected"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {rejectedReason && Array.isArray(rejectedReason) ? (
                <Stack spacing={1} direction="row" flexWrap="wrap">
                  {rejectedReason.map((item, index) => (
                    <Chip label={item} key={index} />
                  ))}
                </Stack>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        {props.data && props.data.length > 0 ? (
          <div style={window.innerWidth >= 600 ? mdLgXlStyles : smStyles}>
            <div style={{ width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ position: "sticky", top: 0, zIndex: "1" }}>
                      <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Loan Id
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Application Status
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Loan Amount
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Loan Term
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        EMI Amount
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Loan Sanction & Agreement
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data &&
                      props.data.map((item, index) => {
                        return (
                          <TableRow
                            key={item.application_status}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontSize: "16px" }}
                            >
                              {item.loanid}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontSize: "16px",
                                color:
                                  item.application_status === "approved"
                                    ? "green"
                                    : item.application_status === "pending"
                                    ? "#ed9f0e"
                                    : "red",
                                fontWeight: 550,
                              }}
                            >
                              {item.application_status}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: "16px" }}>
                              ₹{item.loanamount}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: "16px" }}>
                              {item.tenure} months
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: "16px" }}>
                              ₹{item.emi}
                            </TableCell>
                            {/* <TableCell align="left"><Button variant='contained' size='small' color={item.user_sanction?'success':'secondary'} sx={{width:'8rem'}}>{!item.user_sanction?'Click to Sign':'Signed'}</Button></TableCell> */}
                            <TableCell align="left">
                              {item.legality_doc_sign_status ? (
                                <Button
                                  variant="contained"
                                  sx={{ width: "8rem" }}
                                  color="success"
                                >
                                  Signed
                                </Button>
                              ) : item.application_status === "approved" &&
                                !item.legality_doc_sign_status &&
                                item.document_id &&
                                statusid !== item.document_id ? (
                                <Button
                                  onClick={() =>
                                    handleCheckStatues(item.document_id)
                                  }
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  sx={{ width: "8rem" }}
                                >
                                  Check Status
                                </Button>
                              ) : item.application_status === "approved" &&
                                !item.legality_doc_sign_status &&
                                status &&
                                !userStatus &&
                                item.document_id === statusid ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  sx={{ width: "14rem" }}
                                >
                                  Action Pending - user
                                </Button>
                              ) : item.application_status === "approved" &&
                                !item.legality_doc_sign_status &&
                                status &&
                                userStatus &&
                                item.document_id === statusid ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  sx={{ width: "14rem" }}
                                >
                                  Action Pending - Admin
                                </Button>
                              ) : (
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color:
                                      item.application_status === "approved"
                                        ? "green"
                                        : item.application_status === "pending"
                                        ? "#ed9f0e"
                                        : "red",
                                    fontWeight: 550,
                                  }}
                                >
                                  {item.application_status === "pending" ? (
                                    "Application pending"
                                  ) : item.application_status === "rejected" ? (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() =>
                                        handleClickOpen(item.rejected_reason)
                                      }
                                    >
                                      View reason
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : (
          <Typography variant="subtitle1" color="grey" textAlign={"center"}>
            No user Data found
          </Typography>
        )}
      </div>
    );
  } else window.location.href = "/AdminLogin";
}

export default LoanApplicationFormUser;
