import PropTypes from "prop-types";

// material-ui
import { Box } from "@mui/material";

// project import
import MainCard from "./MainCard";
// import logo from '../LendingForm/FormStructure.js/images/FILending-logo.svg'

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const SigninCardWrapper = ({ children, ...other }) => (
  <MainCard
    sx={{
      maxWidth: { xs: 600, sm: 700, md: 600, lg: 600, xl: 600 },
      // backgroundImage: 'url("https://ik.imagekit.io/olbrn8viad/FILending-logo.svg?updatedAt=1710156582273")',
      margin: { xs: 2.5, md: 3 },
      "& > *": {
        flexGrow: 1,
        flexBasis: "50%",
      },
    }}
    content={false}
    {...other}
  >
    <Box sx={{ p: { xs: 2, sm: 3, xl: 3 } }}>{children}</Box>
  </MainCard>
);

SigninCardWrapper.propTypes = {
  children: PropTypes.node,
};

export default SigninCardWrapper;
