import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  IconButton,
  DialogContent,
  Dialog,
  Link,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
  Button,
  Stack,
} from "@mui/material";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const TermsandCondition = () => {
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [chargeopen, setChargeOpen] = useState(false);

  const handleClickOpen = () => {
    //console.log('clicked');
    setOpen(true);
  };
  const handleClickChargeOpen = () => {
    setChargeOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickChargeClose = () => {
    setChargeOpen(false);
  };

  const rows = [
    createData("1", "Processing Fees", "NIL for the CSPs"),
    createData(
      "2",
      "Credit Bureau report charges",
      "Rs. 100/- to Rs. 500/- based on type of report extracted from the Credit Bureau site. Or As may be amended by the Credit Bureau from time to time. In case credit report from more than one Credit Bureau is required, the above charges will apply additionally."
    ),
    createData(
      "3",
      "Dishonour of Cheque /ECS/NACH Charges",
      "Rs. 1,000/- per instance of Cheque/ ECS(Debit)/ NACH(Debit) dishonour"
    ),
    createData(
      "4",
      "Overdue interest ",
      "4% p.a. of overdue amount for the period for which it has remained overdue."
    ),
    createData(
      "5",
      "Fore-closure charges",
      "2% p.a. on the outstanding amount as on the date of foreclosure for the remaining tenor of the loan."
    ),
    createData(
      "6",
      "CERSAI Charges",
      "Rs. 250/- or as may be amended by CERSAI from time to time. (wherever applicable)"
    ),
    createData(
      "7",
      "Documentation Charges",
      "NIL if provided by borrower. Actual basis if provided by FILPL. "
    ),
    createData(
      "8",
      "Any other charges",
      "As may be decided after intimation to the borrower"
    ),
  ];

  return (
    <>
      {/*  */}
      <Dialog
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="500px !important"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Terms & Conditions, Declaration and Confirmation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom mb={2}>
            1. I/we declare that all the particulars and information and details
            given/filled in this application form are true, correct, complete
            and up-to-date in all respects and that I/we have not withheld any
            material information whatsoever. I/We shall furnish such additional
            information/details as may be required in connection with the loan
            requested by me/us.
          </Typography>
          <Typography gutterBottom mb={2}>
            2. I/We agree and confirm to the schedule of charges including the
            key facts informed to me and as mentioned in the Schedule of Charges
            hereto.
          </Typography>
          <Typography gutterBottom mb={2}>
            3. I/We understand, agree and confirm to the interest/tenure
            /repayment terms as mentioned in Key Fact Statement /other terms and
            conditions of the loan.
          </Typography>
          <Typography gutterBottom mb={2}>
            4. Except to the extend disclosed to FI Lending Solutions Pvt. Ltd.,
            I/we confirm that no insolvency proceedings or suits for recovery of
            outstanding dues or monies whatsoever or for attachment of my/our
            assets or properties and/or any criminal proceedings have been
            initiated and/or are pending against me/us and that I/We have never
            been adjudicated insolvent by any court or other authority.
          </Typography>
          <Typography gutterBottom mb={2}>
            5. I/we confirm that no director or a relative/near relation of a
            director of a banking/finance company (including FI Lending
            Solutions Pvt. Ltd.) or a relative/near relation of a senior officer
            of FI Lending Solutions Pvt. Ltd. is/are the Applicants(s), or a
            partner of our concern, or a trustee, member, director, employee or
            our concern, or of our subsidiary, or our holding company, or a
            guarantor on my/our behalf, or holds substantial interest in our
            concern or in my/our subsidiary or holding company.
          </Typography>
          <Typography gutterBottom mb={2}>
            6. No action nor other steps have been taken or legal proceedings
            started by or against me/us in any court of law / other authorities
            for winding up, dissolution, administration or re-organisation or
            the appointment of a receiver, administrator, administrative
            reviver, trustee or similar officer or for my/our assets.
          </Typography>
          <Typography gutterBottom mb={2}>
            7. I/We hereby authorize and give consent to FI Lending Solutions
            Pvt. Ltd. to disclose, without notice to me/us, information
            furnished by me/us in application form(s)/ related documents
            executed in relation to the facilities availed from FI Lending
            Solutions Pvt. Ltd., to FI Lending Solutions Pvt. Ltd.'s other
            branches/subsidiaries/affiliates, Credit Bureaus/Rating Agencies,
            Service Providers, banks/financial institution, governmental/
            regulatory authorities, third parties for KYC information
            verification, credit risk analysis, or for other related purposes
            that FI Lending Solutions Pvt. Ltd.may deem fit.
          </Typography>
          <Typography gutterBottom mb={2}>
            8. I/We waive the privilege of privacy and privity of contract.
          </Typography>
          <Typography gutterBottom mb={2}>
            9. I/We understand, agree and confirm to the interest/tenure
            /repayment /other terms and conditions of the loan are subject to
            changes as a consequence to any regulatory policy changes or changes
            in the money market conditions or on account of any other statutory
            or regulatory requirements or at FI Lending Solutions Pvt. Ltd.’s
            discretion. FI Lending Solutions Pvt. Ltd. reserves that right to
            review and amend the terms of the loan to such extent as it may deem
            fit.
          </Typography>
          <Typography gutterBottom mb={2}>
            10.I/we understand and acknowledge that FI Lending Solutions Pvt.
            Ltd. shall have the absolute discretion, without assigning any
            reasons (unless required by applicable law), to reject my/our
            application and that FI Lending Solutions Pvt. Ltd. shall not be
            responsible/liable in any manner whatsoever to me/us for such
            rejection or any delay in notifying me/us of such rejection and any
            costs, losses, damages or expenses, or other consequences, caused by
            reason of such rejection, or any delay in notifying me/us of such
            rejection, of my/our application.
          </Typography>
          <Typography gutterBottom mb={2}>
            11.I/we undertake to inform FI Lending Solutions Pvt. Ltd. regarding
            any changes whatsoever in my/our entity and more so which affects
            the validity/legality of the loan, as specified herein above or
            employment/profession and to promptly provide such further
            information that FI Lending Solutions Pvt. Ltd. (or its designated
            group companies or agents or representatives) may require.
          </Typography>
          <Typography gutterBottom mb={2}>
            12.I/We confirm that the funds shall be used for the stated purpose
            and will not be used for any speculative or antisocial purpose(s).
          </Typography>
          <Typography gutterBottom mb={2}>
            13. I/We agree/consent that the proceeds of the loan amount
            disbursed shall be credited only to the account maintained in the
            name of the borrower(s)/joint account with the co-applicant
            /proprietory firm/partnership firm/company, as the case may be.
          </Typography>
          <Typography gutterBottom mb={2}>
            14. I/we agree that FI Lending Solutions Pvt. Ltd./its Group
            Companies/subsidiaris reserve(s) the right to retain the KYC
            documents/photographs and other documents submitted with the
            application and at any subsequent date during the process /currency
            of the loan and will not return those to the
            applicant(s)/borrower(s). I/We also authorize FI Lending Solutions
            Pvt. Ltd./its Group Companies/subsidiaries to take photograph of the
            shop/office/residence of the applicant(s)/borrower(s) and retain the
            same on record of FI Lending Solutions Pvt. Ltd.
          </Typography>
          <Typography gutterBottom mb={2}>
            15. I/we also authorise FI Lending Solutions Pvt. Ltd. to check
            references about me/us from any bank/persons
          </Typography>
          <Typography gutterBottom mb={2}>
            16.The Applicant(s) has/have no objection to FI Lending Solutions
            Pvt. Ltd., its group/subsidiary companies, agents/representatives to
            provide me/us information on various products, offers and services
            provided by FI Lending Solutions Pvt. Ltd./ its group/subsidiary
            companies through any mode (including without limitation through
            telephone calls / SMSs / emails) and authorise FI Lending Solutions
            Pvt. Ltd. Limited, its group/subsidiary companies, agents /
            representatives for the above purpose.
          </Typography>
          <Typography gutterBottom mb={2}>
            I/We understand, agree and confirm to the interest/tenure /repayment
            terms as mentioned in Key Fact Statement /other terms and conditions
            of the loan.17. I/we declare that: (i) I/we am/are competent and
            fully authorised to issue such declarations, confirmations,
            agreements and undertaking and submit this application form for the
            purposes of borrowing/availing of the requested facility, and to
            execute all other documents required by FI Lending Solutions Pvt.
            Ltd. for such purpose; (ii) all such letter(s) of authorities/power
            (s) of attorney, if any, executed by me/us in favour of any person/s
            and which is/are submitted to the FI Lending Solutions Pvt. Ltd.,
            is/are valid, subsisting and has/have not been revoked by me/us.
          </Typography>
          <Typography gutterBottom mb={2}>
            18. I/we acknowledge and agree that i) the grant of the facility by
            FI Lending Solutions Pvt. Ltd. to me/us shall be subject to
            compliance by me/us of the terms and conditions set out under this
            Application Form and its schedules; (ii) All the terms and
            conditions set out in the Application Form and any other documents
            in relation to this loan shall govern and apply to the facility and
            all my/our obligations (as well as FI Lending Solutions Pvt. Ltd.'s
            rights and remedies) in relation thereto, if the facility applied
            for by me/us herein is sanctioned and granted by FI Lending
            Solutions Pvt. Ltd. based on my/our application
          </Typography>
          <Typography gutterBottom mb={2}>
            19. I/We confirm that I/we have read and understood the above
            Declaration/Confirmation and Terms & Conditions and that the details
            provided on the form are correct.
          </Typography>
        </DialogContent>
      </Dialog>
      {/*  */}
      <Grid container alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              name="checked"
              color="secondary"
            />
            <Typography>Agree with</Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Button
            size="small"
            sx={{ textDecoration: "underline" }}
            onClick={handleClickOpen}
          >
            Terms & Condition,
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={handleClickChargeOpen}
            sx={{ paddingLeft: "0px", textDecoration: "underline" }}
          >
            Schedule of Charges.
          </Button>
        </Grid>
      </Grid>

      {/*  */}
      <Dialog onClose={handleClickChargeClose} open={chargeopen} maxWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Schedule of Charges
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickChargeClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <DialogContent dividers>
          <TableContainer component={Paper} sx={{ minwidth: "950px" }}>
            <Table sx={{ minWidth: 950 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr.No.</TableCell>
                  <TableCell align="left">Description of charges</TableCell>
                  <TableCell align="left">Amount/Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.calories}</TableCell>
                    <TableCell align="left">{row.fat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          *GST at prescribed rate of 18% will charged on all the above items
        </DialogContent>
      </Dialog>
      {/*  */}
    </>
  );
};

export default TermsandCondition;
