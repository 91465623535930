import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { PostApi } from "../../Axios/ServiceApi";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Snackbar,
  OutlinedInput,
  Box,
  FormHelperText,
  MenuItem,
  IconButton,
  Toolbar,
  Button,
  Slide,
  AppBar,
  ListItemButton,
  Dialog,
  List,
  ListItemText,
  Divider,
  FormLabel,
  debounce,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import * as Yup from "yup";
import TermsandCondition from "./TermsandCondition";
import PreviewForm from "./PreviewForm";
import ExistingLoan from "./PreviewForm/ExistingLoan";
import Borrowerloan from "./PreviewForm/Borrowerloan";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BorrowerDetails = ({ ...others }) => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const indexdata = location.state ? location.state.indexdata : "";
  const detailsdata = location.state ? location.state.detailvalues : "";
  const imagefile = location.state ? location.state.Image : "";
  const idfile = location.state ? location.state.Imageid : "";
  const cheque1 = location.state ? location.state.cheque1 : "";
  const cheque2 = location.state ? location.state.cheque2 : "";
  const AadharImage = location.state ? location.state.AadharImage : "";
  const ItrUpload = location.state ? location.state.ItrUpload : "";
  const PanImage = location.state ? location.state.PanImage : "";
  const poi = location.state ? location.state.poi : "";
  console.log("poi", poi, PanImage);
  const borrowerdetails = location.state
    ? location.state.borrowerdetails
    : null;
  // console.log("detailsdata", detailsdata, indexdata,imagefile,idfile,cheque1,cheque2);
  const [open, setOpen] = useState(false);
  const [ErrOpen, setErrOpen] = useState(false);
  const [SubmitRes, setSubmitRes] = useState(false);
  const [ImageErr, setImageError] = useState(false);
  const [ImgErrMessage, setImageErrorMessage] = useState("");
  // current date and time
  // var today = new Date();
  // var time = today.toLocaleTimeString()
  // //console.log('time', time);
  // var dd = String(today.getDate()).padStart(2, '0');
  // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  // var yyyy = today.getFullYear();

  // today = mm + '/' + dd + '/' + yyyy;
  //  //console.log("today",today);

  //  end
  // preview section start
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleClickPreviewOpen = () => {
    setPreviewOpen(true);
  };
  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  // preview section end

  const handleClick = () => {
    // //console.log("success open");
    setOpen(true);
    setIsLoading(false);
  };

  const handleErrOpen = () => {
    // //console.log("error open");
    setErrOpen(true);
    setIsLoading(false);
  };
  const handleresClose = () => {
    setSubmitRes(false);
    setTokenErr(false);
  };

  const handleResErrOpen = () => {
    setSubmitRes(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageError(false);
    setErrOpen(false);
  };

  const Token = sessionStorage.getItem("user_token");

  const handleonSubmit = () => {
    handleClickPreviewOpen();
  };
  const [IsLoading, setIsLoading] = useState(false);
  const [TokenErr, setTokenErr] = useState(false);

  const handleTokErrOpen = () => {
    setTokenErr(true);
  };
  const PostData = (values) => {
    PostApi(
      {
        indexdata,
        detailsdata,
        values,
      },
      imagefile,
      idfile,
      cheque1,
      cheque2,
      ItrUpload,
      AadharImage,
      PanImage,
      poi,
      handleTokErrOpen,
      handleClick,
      setIsLoading,
      handleErrOpen,
      handleResErrOpen,
      setImageError,
      setImageErrorMessage
    );
    //console.log("values", values);
  };

  if (Token) {
    return (
      <>
        {/*  */}

        {/*  */}
        {/*  */}
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            successfully submitted your form
          </Alert>
        </Snackbar>
        <Snackbar
          open={ImageErr}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {ImgErrMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={SubmitRes}
          // autoHideDuration={5000}
          onClose={handleresClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleresClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            Loan request already pending/approved
          </Alert>
        </Snackbar>
        <Snackbar
          open={TokenErr}
          // autoHideDuration={5000}
          onClose={handleresClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleresClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            Session expired. Please login again.
          </Alert>
        </Snackbar>
        <Snackbar
          open={ErrOpen}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Something went wrong Please try again later...!!!
          </Alert>
        </Snackbar>
        {/*  */}
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography sx={{ color: "#121926" }} gutterBottom variant="h6">
              Enter your Borrower&apos;s Details
            </Typography>
          </Stack>
        </Grid>
        <Formik
          initialValues={{
            borrowertype: borrowerdetails ? borrowerdetails.borrowertype : "",
            borrowertype: "Individual",
            referred_by: borrowerdetails ? borrowerdetails.referred_by : "",
            aadhaar_reference_no: sessionStorage.getItem("reference_id"),
            // borrowername: "",
            // borrowerage: "",
            // borroweremail: "",
            // borrowerphone: "",
            // borrowerfatherspouse: "",
            // permanentaddress: "",
            // firm: "",
            // soleproprietor: "",
            // addresscommunication: "",
            // permanentaddresssole: "",
            // emailsole: "",
            // phonesole: "",
            // partnershipfirm: "",
            // allpartner: "",
            // principalplace: "",
            // addresscommunicationpartner: "",
            // emailpartner: "",
            // phonepartner: "",
            // companyname: "",
            // registeredoffice: "",
            // submitdate: today,
            // submittime: time
          }}
          validationSchema={Yup.object().shape({
            borrowertype: Yup.string()
              .max(50)
              .required("Borrwer type is required"),
            referred_by: Yup.string().required("Refferal name is required"),
            //   borrowername: Yup.string().max(50).required("Name is required"),
            // borrowerage: Yup.string().max(5).matches(/^[0-9]{1,3}$/, 'Invalid Age format').required("Age is required"),
            // borrowerfatherspouse: Yup.string()
            //   .max(20)
            //   .required("Father and Spouse name is required"),
            // permanentaddress: Yup.string()
            //   .max(255)
            //   .required("Permanent Address is required"),
            //   borrowerphone: Yup.string("Must be a number")
            //   .min(10, "Phone number must have min 10 digit ")
            //   .max(10, "Phone number must have max 10 digit")
            //   .required("Phone number is required"),
            //   borroweremail: Yup.string()
            //   .matches(
            //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     "Please enter a valid email address"
            //   )
            //   .email("Please enter a valid email address")
            //   .max(255)
            //   .required("Email is required"),
            // phonesole: Yup.string("Must be a number")
            //   .min(10, "Phone number must have min 10 digit ")
            //   .max(10, "Phone number must have max 10 digit")
            //   .required("Phone number is required"),
            // emailsole: Yup.string()
            //   .matches(
            //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     "Please enter a valid email address"
            //   )
            //   .email("Please enter a valid email address")
            //   .max(255)
            //   .required("Email is required"),
            // firm: Yup.string().max(50).required("Name of the Firm is required"),
            // soleproprietor: Yup.string()
            //   .max(50)
            //   .required("Name of the Sole Proprietor is required"),
            // permanentaddresssole: Yup.string()
            //   .max(255)
            //   .required("Permanent Address is required"),
            // addresscommunication: Yup.string()
            //   .max(255)
            //   .required("Address for Communication is required"),
            // partnershipfirm: Yup.string()
            //   .max(50)
            //   .required("Name of the Partnership Firm is required"),
            // allpartner: Yup.string()
            //   .max(50)
            //   .required("Names of all Partners is required"),
            // principalplace: Yup.string()
            //   .max(50)
            //   .required("Address of the principal place of business is required"),
            // addresscommunicationpartner: Yup.string()
            //   .max(50)
            //   .required("Address for Communication is required"),
            // emailpartner: Yup.string()
            //   .max(50)
            //   .matches(
            //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //     "Please enter a valid email address"
            //   )
            //   .required("E-mail Id of the Borrower(s) is required"),
            // phonepartner: Yup.string("Must be a number")
            //   .min(10, "Phone number must have min 10 digit ")
            //   .max(10, "Phone number must have max 10 digit")
            //   .required("Contact Number of the Borrower(s) is required"),
            // companyname: Yup.string()
            //   .max(50)
            //   .required("Name of the Company is required"),
            // registeredoffice: Yup.string()
            //   .max(50)
            //   .required("Address of registered office is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values) {
                setStatus({ success: true });
                setSubmitting(false);
                handleonSubmit(values);
              }
            } catch (err) {
              console.error(err);
              if (values) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            isValid,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Dialog
                fullScreen
                open={previewOpen}
                onClose={handlePreviewClose}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: "sticky" }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handlePreviewClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        ml: 0,
                        flex: 1,
                        display: {
                          xs: "none",
                          sm: "initial",
                          md: "initial",
                          lg: "initial",
                          xl: "initial",
                        },
                      }}
                      variant="h6"
                      component="div"
                    >
                      Form Preview
                    </Typography>
                    <Button
                      sx={{
                        mr: 1,
                      }}
                      autoFocus
                      color="inherit"
                      variant="outlined"
                      onClick={() =>
                        Navigate("/loan", {
                          state: {
                            indexdata,
                            detailvalues: detailsdata,
                            borrowerdetails: values,
                            aadhar: AadharImage,
                            pan: PanImage,
                            proofid: idfile,
                            userimage: imagefile,
                            userincomeproof: poi,
                            datecheque1: cheque1,
                            datecheque2: cheque2,
                            dataitr: ItrUpload,
                          },
                        })
                      }
                    >
                      Edit
                    </Button>
                    <LoadingButton
                      autoFocus
                      sx={{
                        backgroundColor: "#f0efed",
                        color: "black",
                        width: { md: 130, sm: 130, xs: 130 },
                        "&:hover": {
                          bgcolor: "white",
                          color: "black",
                        },
                      }}
                      variant="contained"
                      onClick={debounce(() => PostData(values), 2000)}
                      loading={IsLoading}
                      loadingPosition="start"
                    >
                      Submit
                    </LoadingButton>
                  </Toolbar>
                </AppBar>
                <List>
                  <Grid m={2}>
                    <PreviewForm indexdata={indexdata} />
                  </Grid>
                  <Divider />
                  <Grid m={2}>
                    <ExistingLoan detailsdata={detailsdata} />
                  </Grid>
                  <Divider />
                  <Grid m={2}>
                    <Borrowerloan values={values} />
                  </Grid>
                </List>
              </Dialog>
              <Stack direction="row" sx={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.borrowertype && errors.borrowertype
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Borrower(s) Type
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <Select
                          id="select"
                          value={"Individual"}
                          name="borrowertype"
                          disabled
                          onChange={handleChange}
                          label="Borrower(s) Type"
                          input={<OutlinedInput label="Borrower(s) Type" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Individual">Individual</MenuItem>
                        </Select>
                        {touched.borrowertype && errors.borrowertype && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-borrowertype-register"
                          >
                            {errors.borrowertype}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.referred_by && errors.referred_by
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-borrowername-register">
                          Reffered By
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label=" Reffered By"
                          id="outlined-adornment-name-register"
                          type="text"
                          value={values.referred_by}
                          name="referred_by"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.referred_by && errors.referred_by && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-borrowername-register"
                          >
                            {errors.referred_by}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.borrowername && errors.borrowername)}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-borrowername-register">
                            Name&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.borrowername}
                            name="borrowername"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.borrowername && errors.borrowername && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-borrowername-register"
                            >
                              {errors.borrowername}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.borrowerfatherspouse && errors.borrowerfatherspouse
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Father&apos;s/Spouse&apos;s Name&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Father's/Spouse's Name&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.borrowerfatherspouse}
                            name="borrowerfatherspouse"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.borrowerfatherspouse && errors.borrowerfatherspouse && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-borrowerfatherspouse-register"
                            >
                              {errors.borrowerfatherspouse}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.borrowerage && errors.borrowerage)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Age&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Age&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.borrowerage}
                            name="borrowerage"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.borrowerage && errors.borrowerage && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-borrowerage-register"
                            >
                              {errors.borrowerage}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.borroweremail && errors.borroweremail)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-borroweremail-register">
                            Email&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="email&#42;"
                            id="outlined-adornment-borroweremail-register"
                            type="text"
                            value={values.borroweremail}
                            name="borroweremail"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.borroweremail && errors.borroweremail && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-borroweremail-register"
                            >
                              {errors.borroweremail}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.borrowerphone && errors.borrowerphone)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-borrowerphone-register">
                            Contact Number&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Contact Number&#42;"
                            id="outlined-adornment-borrowerphone-register"
                            type="text"
                            value={values.borrowerphone}
                            name="borrowerphone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.borrowerphone && errors.borrowerphone && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-borrowerphone-register"
                            >
                              {errors.borrowerphone}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.permanentaddress && errors.permanentaddress
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-permanentaddress-register">
                            Permanent Address&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Permanent Address&#42;"
                            id="outlined-adornment-permanentaddress-register"
                            fullWidth
                            type="text"
                            value={values.permanentaddress}
                            name="permanentaddress"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.permanentaddress &&
                            errors.permanentaddress && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-permanentaddress-regpermanentaddressister"
                              >
                                {errors.permanentaddress}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid> */}
                  {/* Individual section start */}
                  {/* {values.borrowerType === "Individual" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.name && errors.name)}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-name-register">
                            Name&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.name}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.name && errors.name && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-name-register"
                            >
                              {errors.name}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.fatherspouse && errors.fatherspouse
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Father&apos;s/Spouse&apos;s Name&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Father's/Spouse's Name&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.fatherspouse}
                            name="fatherspouse"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.fatherspouse && errors.fatherspouse && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-fatherspouse-register"
                            >
                              {errors.fatherspouse}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.age && errors.age)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Age&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Age&#42;"
                            id="outlined-adornment-full_name-register"
                            type="number"
                            value={values.age}
                            name="age"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.age && errors.age && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-age-register"
                            >
                              {errors.age}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Email&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="email&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-register"
                            >
                              {errors.email}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.phone && errors.phone)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Contact Number&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Contact Number&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.phone}
                            name="phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.phone && errors.phone && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-phone-register"
                            >
                              {errors.phone}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.permanentaddress && errors.permanentaddress
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-permanentaddress-register">
                            Permanent Address&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Permanent Address&#42;"
                            id="outlined-adornment-permanentaddress-register"
                            fullWidth
                            type="text"
                            value={values.permanentaddress}
                            name="permanentaddress"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.permanentaddress &&
                            errors.permanentaddress && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-permanentaddress-regpermanentaddressister"
                              >
                                {errors.permanentaddress}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )} */}
                  {/* Individual section end */}

                  {/* sole Proprietorship Firm section start */}
                  {/* {values.borrowerType === "Sole Proprietorship Firm" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.firm && errors.firm)}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-firm-register">
                            Name of the Firm&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name of the Firm&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.firm}
                            name="firm"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.firm && errors.firm && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-firm-register"
                            >
                              {errors.firm}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.soleproprietor && errors.soleproprietor
                          )}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-soleproprietor-register">
                            Name of the Sole Proprietor&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name of the Sole Proprietor&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.soleproprietor}
                            name="soleproprietor"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.soleproprietor && errors.soleproprietor && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-soleproprietor-register"
                            >
                              {errors.soleproprietor}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.permanentaddresssole &&
                              errors.permanentaddresssole
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-permanentaddresssole-register">
                            Permanent Address&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Permanent Address&#42;"
                            id="outlined-adornment-permanentaddresssole-register"
                            fullWidth
                            type="text"
                            value={values.permanentaddresssole}
                            name="permanentaddresssole"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.permanentaddresssole &&
                            errors.permanentaddresssole && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-permanentaddresssole-regpermanentaddresssoleister"
                              >
                                {errors.permanentaddresssole}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.addresscommunication &&
                              errors.addresscommunication
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Address for Communication&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Address for Communication&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.addresscommunication}
                            name="addresscommunication"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.addresscommunication &&
                            errors.addresscommunication && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-addresscommunication-register"
                              >
                                {errors.addresscommunication}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.emailsole && errors.emailsole)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Email&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="email&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.emailsole}
                            name="emailsole"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.emailsole && errors.emailsole && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-emailsole-register"
                            >
                              {errors.emailsole}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.phonesole && errors.phonesole)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Contact Number&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Contact Number&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.phonesole}
                            name="phonesole"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.phonesole && errors.phonesole && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-phonesole-register"
                            >
                              {errors.phonesole}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )} */}
                  {/* sole Proprietorship Firm section end */}

                  {/* Partnership section start */}
                  {/* {values.borrowerType === "Partnership" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.partnershipfirm && errors.partnershipfirm
                          )}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-partnershipfirm-register">
                            Name of the Partnership Firm&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name of the Partnership Firm&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.partnershipfirm}
                            name="partnershipfirm"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.partnershipfirm && errors.partnershipfirm && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-partnershipfirm-register"
                            >
                              {errors.partnershipfirm}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={6} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.allpartner && errors.allpartner)}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-allpartner-register">
                            Name of all Partner &#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name of all Partner&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.allpartner}
                            name="allpartner"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.allpartner && errors.allpartner && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-allpartner-register"
                            >
                              {errors.allpartner}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.principalplace && errors.principalplace
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-principalplace-register">
                            Address of the principal place of business&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Address of the principal place of business&#42;"
                            id="outlined-adornment-principalplace-register"
                            fullWidth
                            type="text"
                            value={values.principalplace}
                            name="principalplace"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.principalplace && errors.principalplace && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-principalplace-regprincipalplaceister"
                            >
                              {errors.principalplace}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.addresscommunicationpartner &&
                              errors.addresscommunicationpartner
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Address for Communication&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Address for Communication&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.addresscommunicationpartner}
                            name="addresscommunicationpartner"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.addresscommunicationpartner &&
                            errors.addresscommunicationpartner && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-addresscommunicationpartner-register"
                              >
                                {errors.addresscommunicationpartner}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.emailpartner && errors.emailpartner
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            E-mail Id of the Borrower(s)&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="E-mail Id of the Borrower(s)&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.emailpartner}
                            name="emailpartner"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.emailpartner && errors.emailpartner && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-emailpartner-register"
                            >
                              {errors.emailpartner}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.phonepartner && errors.phonepartner
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-full_name-register">
                            Contact Number of the Borrower(s)&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Contact Number of the Borrower(s)&#42;"
                            id="outlined-adornment-full_name-register"
                            type="text"
                            value={values.phonepartner}
                            name="phonepartner"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.phonepartner && errors.phonepartner && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-phonepartner-register"
                            >
                              {errors.phonesole}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )} */}
                  {/* Partnership section end */}

                  {/* Company section start */}
                  {/* {values.borrowerType === "Company" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12} xl={12} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.companyname && errors.companyname
                          )}
                          sx={{ mb: "10px", ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-companyname-register">
                            Name of the Company&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Name of the Company&#42;"
                            id="outlined-adornment-name-register"
                            type="text"
                            value={values.companyname}
                            name="companyname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.companyname && errors.companyname && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-companyname-register"
                            >
                              {errors.companyname}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12} xl={12} lg={12} mb={0}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.registeredoffice && errors.registeredoffice
                          )}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-registeredoffice-register">
                            Address of registered office&#42;
                          </InputLabel>
                          <OutlinedInput
                            label="Address of registered office&#42;"
                            id="outlined-adornment-registeredoffice-register"
                            fullWidth
                            type="text"
                            value={values.registeredoffice}
                            name="registeredoffice"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{}}
                          />
                          {touched.registeredoffice &&
                            errors.registeredoffice && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-registeredoffice-regregisteredofficeister"
                              >
                                {errors.registeredoffice}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )} */}
                  {/* Company section end */}

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Stack>
              <TermsandCondition />
              <Grid
                container
                spacing={4}
                display={"flex"}
                justifyContent={"space-between"}
              >
                {/* <Grid item sm={6} xs={6} md={4} lg={4} xl={2}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      disabled={!isSubmitting || isValid}
                      // fullWidth
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        // borderColor: "#A556B2",
                        // color: "#A556B2",
                        // "&:hover": { borderColor: "#A556B2" },
                      }}
                    >
                      Aggrement
                    </Button>
                  </Box>
                </Grid> */}
                <Grid item sm={6} xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      // disabled={isSubmitting || !isValid}
                      fullWidth
                      size="large"
                      // type="submit"
                      variant="outlined"
                      sx={{
                        borderColor: "#A556B2",
                        color: "#A556B2",
                        "&:hover": { borderColor: "#A556B2" },
                      }}
                      onClick={() => Navigate(-1)}
                    >
                      back
                    </Button>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      disabled={isSubmitting || !isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#A556B2",
                        color: "#fff",
                        "&:hover": { backgroundColor: "#A556B2" },
                      }}
                      // onClick={()=>
                      //   {//console.log("enter onclick")
                      //   handleClickPreviewOpen()}}
                      // onClick={() => { handleonSubmit(values) }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    );
  } else {
    window.location.href = "/";
  }
};

export default BorrowerDetails;
