import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  FormControl,
  Grid,
  Typography,
  OutlinedInput,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  FormLabel,
} from "@mui/material";
// import { Field } from "formik";

const Index = (props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const values = props.values;
  // //console.log("values", values);

  return (
    <>
      <form noValidate>
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography sx={{ color: "#121926" }} gutterBottom variant="h6">
              Borrower's Details
            </Typography>
          </Stack>
        </Grid>
        <Stack direction="column">
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.name && errors.name)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-full_name-register">
                    Borrower(s) Type&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Borrower(s) Type&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.borrowertype}
                    name="name"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.name && errors.name && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-name-register"
                        >
                          {errors.name}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.name && errors.name)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-full_name-register">
                    Reffered By&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Reffered By&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.referred_by}
                    name="name"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.name && errors.name && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-name-register"
                        >
                          {errors.name}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
            </Grid>
            {/* {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )} */}
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default Index;
