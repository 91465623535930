// material-ui
import { styled } from "@mui/material/styles";

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled("div")(({ theme }) => ({
  backgroundColor: "#EEF2F6",
  minHeight: "100vh",
  minWidth: "",
}));

export default AuthWrapper1;
