import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  FormHelperText,
  OutlinedInput,
  CircularProgress,Backdrop
} from "@mui/material";
import Chip from "@mui/material/Chip";
import OptionModal from "./OptionModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GetImage, LoanUpdateapi } from "../../Axios/Approval/api";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import * as Yup from "yup";
import CreditForm from "./Components/CreditForm";
import { AdminAadharData } from "../../Axios/ServiceApi";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FieldsTable = (props, { ...others }) => {
  const theme = useTheme();

  // console.log("props",props);

  const [editopen, setEditOpen] = React.useState(false);

  const handleEditClickOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setCreditOpen(false);
  };

  const MyStyledTextField = styled(TextField)({
    "& .MuiInputBase-root.Mui-disabled": {
      color: "blue",
    },
  });
  // const rows = props.selectedItem;
  const [rows, setRows] = useState(props.selectedItem);
  // console.log("row", rows);
  const AppID = rows.application_id;
  const emi = Math.round(rows.loanamount * 0.095530139);
  // console.log("emi",emi);
  const convertAppid = AppID.toString();
  //console.log(rows);
  const [option, setOption] = React.useState(null);

  const handleSelect = (name) => {
    // //console.log("click handled ",props.selectedItem[name]);
    setOption(props.selectedItem[name]);
  };

  const [openImage, setOpenImage] = React.useState(false);
  const [selectedItem, setselectedItem] = React.useState(null);
  const [Data, setData] = React.useState({
    loanpurpose: "",
    tenure: "",
    emi: "",
  });
  const [ErrMessage, setErrMessage] = React.useState("");

  const [Error, setError] = React.useState({
    loanpurpose: false,
    loanamount: false,
    loanscheme: false,
    tenure: false,
    roi: false,
    emi: false,
    feescharge: false,
  });
  const handleImageOpen = (name) => {
    // //console.log(name);
    // //console.log(rows["file_urls"][name]);
    GetImage(
      rows["file_urls"][name],
      setselectedItem,
      setOpenImage,
      handleResImage,
      handleResCloseImage,
      handleErrImgae
    );
  };
  const [Err, setErr] = useState(false);
  const [Res, setRes] = useState(false);

  const handleErrImgae = () => {
    setErr(true);
  };
  const handleResImage = () => {
    setRes(true);
  };

  const handleResCloseImage = () => {
    setRes(false);
  };

  const data = [
    {
      category: "Credit Score Section",
      fields: [{ name: "pan", displayname: "Pan Card" }],
    },
    {
      category: "CSP Details",
      fields: [
        { name: "name", displayname: "Name of Applicant" },
        { name: "fatherspouse", displayname: "Father / Spouse Name" },
        { name: "dob", displayname: "DOB" },
        { name: "gender", displayname: "Gender" },
        { name: "martailstatus", displayname: "Marital Status" },
        { name: "nationality", displayname: "Nationality" },
        { name: "aadhar", displayname: "Aadhar Card" },
        { name: "pan", displayname: "Pan Card" },
        { name: "phone", displayname: "Contact No" },
        { name: "email", displayname: "Email" },

        { name: "cspcode", displayname: "CSP Code" },
        { name: "bankname", displayname: "Bank Name" },
        { name: "branch", displayname: "Bank Branch" },
        { name: "resaddress", displayname: "Residential Address" },
        { name: "resaddresstype", displayname: "Residential Type" },
        {
          name: "residingyear",
          displayname: "No of years at residing at above residence",
        },
        { name: "cspaddress", displayname: "CSP Centre Address" },
        { name: "cspyear", displayname: "No of years of CSP Centre" },
        { name: "cspcenter", displayname: "Type of CSP Center" },
        { name: "annualincome", displayname: "Annual Income" },
      ],
    },
    {
      category: "Existing Loan",
      fields: [
        { name: "existingloandetails", displayname: "Existing Loan Details" },
        { name: "existingloanpurpose", displayname: "Purpose of Loan" },
        { name: "existingloanamount", displayname: "Loan Amount" },
        { name: "existingtenure", displayname: "Tenure1" },
        {
          name: "existingmonthlyinstalment",
          displayname: "Monthly Instalment",
        },
        { name: "existingpresentoutstand", displayname: "Present outstanding" },
        { name: "existingbalancetenure", displayname: "Balance Tenure" },
      ],
    },
    {
      category: "Bank Details",
      fields: [
        { name: "accname", displayname: "Name of Account Holder" },
        { name: "bankbranch", displayname: "Name of Bank and branch" },
        { name: "accountno", displayname: "Account Number" },
        { name: "ifsc", displayname: "IFSC Code" },
        { name: "accounttype", displayname: "Type of Account" },
      ],
    },
    {
      category: "Loan Request Details",
      fields: [
        // {name:'companyaddress',displayname:'Name and Address of the Company'},
        { name: "loanpurpose", displayname: "Purpose of Loan" },
        { name: "loanamount", displayname: "Loan Amount" },

        // {name:'loantype',displayname:'Type of the Loan'},

        { name: "loanscheme", displayname: "Loan Scheme" },

        { name: "tenure", displayname: "Tenure (Months)" },
        { name: "roi", displayname: "Rate of Interest" },
        { name: "emi", displayname: "EMI (INR)" },
        // {name:'commencementdate',displayname:'Date of Commencement of EMI'},
        // {name:'overdueintrest',displayname:'Overdue interest (%) p. a.'},
        { name: "feescharge", displayname: "Fees / Charges" },
        // {name:'loanid',displayname:'The Company’s Loan ID'},
        // {name:'annualpercentage',displayname:' Annual Percentage Rate (APR)'},
        // {name:'availableperiod',displayname:'Availability Period (Months)'},
        // {name:'modeofrepayment',displayname:'Mode of Repayment'},
        // {name:'repaymentfrequency',displayname:'Frequency of Repayment'},
        // {name:'instalmentsrepayments',displayname:'No of Instalments for Repayment'},
      ],
    },
    {
      category: "Borrower's Details",
      fields: [
        { name: "borrowertype", displayname: "Borrowers Type" },
        { name: "referred_by", displayname: "Reffered by" },
      ],
    },
    {
      category: "Uploaded Documents",
      fields: [
        { name: "aadhar_image_url", displayname: "Aadhar Card" },
        { name: "pan_image_url", displayname: "Pan Card" },
        { name: "id_proof_url", displayname: "Address Proof" },
        { name: "profile_image_url", displayname: "Profile Image" },
        { name: "cheque2_url", displayname: "Cheque 1" },
        { name: "cheque1_url", displayname: "Cheque 2" },
        { name: "itr_upload_url", displayname: "ITRs for minimum 2 years" },
      ],
    },
  ];

  const loandetails = [
    {
      id: 1,
      Loanscheme: "Micro Loan (P1)",
      Loanpurpose: "SBI Tab",
      Loanamount: "45,030",
      EMI: "3,048",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 2,
      Loanscheme: "Micro Loan (P2)",
      Loanpurpose: "EPSON PLQ 50CS Printer",
      Loanamount: "39,490",
      EMI: "2,673",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 3,
      Loanscheme: "Micro Loan (P20)",
      Loanpurpose: "EPSON PLQ 50CS Printer 24 Months",
      Loanamount: "39,490",
      EMI: "2,128",
      Tenure: "24",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 4,
      Loanscheme: "Micro Loan (P21)",
      Loanpurpose: "EPSON PLQ 50CS Printer 30 Months",
      Loanamount: "39,490",
      EMI: "1,804",
      Tenure: "30",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 5,
      Loanscheme: "Micro Loan (P3)",
      Loanpurpose: "Part Payment",
      Loanamount: "20,000",
      EMI: "1,911",
      Tenure: "12",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 6,
      Loanscheme: "Micro Loan (P4)",
      Loanpurpose: "SBI mATM",
      Loanamount: "7,250",
      EMI: "2,522",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 7,
      Loanscheme: "Micro Loan (P5)",
      Loanpurpose: "Morpho (L1) FID",
      Loanamount: "5,000",
      EMI: "1,739",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 8,
      Loanscheme: "Micro Loan (P6)",
      Loanpurpose: "Mantra (L1)  FID",
      Loanamount: "4,500",
      EMI: "1,565",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 9,
      Loanscheme: "Micro Loan (P7)",
      Loanpurpose: "SBI Tab 24 Months",
      Loanamount: "45,030",
      EMI: "2,426",
      Tenure: "24",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
    {
      id: 10,
      Loanscheme: "Micro Loan (P8)",
      Loanpurpose: "SBI Tab 30 Months",
      Loanamount: "45,030",
      EMI: "2,057",
      Tenure: "30",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
    },
  ];

  const [selectedLoanDetails, setSelectedLoanDetails] = useState(null);
  const handleLoanPurposeChange = (event) => {
    const selectedPurpose = event.target.value;
    console.log("selectedPurpose", selectedPurpose);
    const details = loandetails.find(
      (detail) => detail.Loanpurpose === selectedPurpose
    );
    console.log("details", details);
    setSelectedLoanDetails(details);
  };

  const handleEditSubmit = (purloan, loanemi, loanten) => {
    console.log("enter in handlechange", purloan, loanemi, loanten);
    LoanUpdateapi(
      selectedLoanDetails.Loanpurpose,
      selectedLoanDetails.EMI,
      selectedLoanDetails.Tenure,
      selectedLoanDetails.Loanscheme,
      rows.phone,
      convertAppid,
      handleEditClick,
      handleEditErr
    );
  };

  // const [Errors, setErrors] = useState({status:false, message:null})

  const handleAmountChange = (value, name) => {
    let postemi = Math.round(value * 0.095530139);
    setRows({ ...rows, [name]: value, emi: postemi });
    props.setSelectedItem({
      ...props.selectedItem,
      [name]: value,
      emi: postemi,
    });
    console.log("values", value, "emi", postemi);
    // if(value%1000===0){
    //   setErrors({...Error,status:false,message:null})
    // }
    // else{
    //   setErrors({...Error,status:true,message:"Loan amount must be in thousands (e.g., 1000, 2000)"})
    // }
  };

  // console.log(rows);

  const [EditRes, setEditRes] = useState(false);
  const [EditErr, setEditErr] = useState(false);

  const handleEditClick = () => {
    setEditRes(true);
    setEditOpen(false);
  };

  const handleEditErr = () => {
    setEditErr(true);
    console.log("error");
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // //console.log('open triggered');
    setOpen(true);
  };
  const handleClose = () => {
    setEditErr(false);
    setEditRes(false);
    setOpen(false);
    setOption(null);
    setErr(false);
    setRes(false);
  };

  useEffect(() => {
    if (option) handleClickOpen();
  }, [option]);

  const handleImageClose = (name) => {
    setOpenImage(false);
  };

  const [CreditOpen, setCreditOpen] = useState(false);

  const handleCreditOpen = () => {
    setCreditOpen(true);
  };
  const [Pincode, setPincode] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [Dob, setDob] = useState("");
  const [Gender, setGender] = useState("");
  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");
  const [IsLoading,setIsLoading] = useState(false)

  // aadhar data
  useEffect(() => {
    if (rows.aadhaar_reference_no) {
      AdminAadharData(
        setIsLoading,
        setState,
        setPincode,
        setCity,
        setName,
        setDob,
        setGender,
        setAddress,
        rows.aadhaar_reference_no
      );
    }
  }, []);
  // console.log("State", State, Pincode, City,rows.aadhaar_reference_no);

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={IsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* error handle start */}
      <Snackbar
        open={EditErr}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Something went wrong.
        </Alert>
      </Snackbar>
      <Snackbar
        open={EditRes}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Loan data updated successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={Res}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} variant="filled" severity="warning">
          Loading Image.....
        </Alert>
      </Snackbar>
      <Snackbar
        open={Err}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" onClose={handleClose} severity="error">
          Error Loading Image.....
        </Alert>
      </Snackbar>
      {/* error handle end */}
      {/* <Grid item justifyContent={"center"} mt={4}>
          <Typography variant="h6" fontWeight={550} textAlign={"center"}>
            Credit Score Section
          </Typography>
          <Grid item xs={6} sm={6} md={4} xl={6} lg={6}>
                    <TextField
                      label='pancard number'
                      disabled={false}
                      size="small"
                      name='pancard number'
                      inputProps={{
                        style: { color: "#252525" },
                        readOnly: true,
                      }}
                      sx={{mb:"5px"}}
                      fullWidth
                    />
                  </Grid>
                  <Button variant="contained" color="secondary">
                    Check your Cibil score
                  </Button>
          </Grid> */}
      {data.map((item, index) => (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Dialog
            open={openImage}
            onClose={handleImageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <img
                  style={{
                    width: "100%",
                    aspectRatio: "1/1",
                    objectFit: "contain",
                  }}
                  src={selectedItem}
                  alt="img"
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageClose} autoFocus>
                close
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item justifyContent={"center"}>
            {/* <Grid item> */}
            <Typography variant="h6" fontWeight={550}>
              {item.category}
            </Typography>
          </Grid>

          {/* </Grid> */}
          <Grid container spacing={2} mt={1}>
            {item.category === "Uploaded Documents"
              ? item.fields.map((field, imageIndex) => (
                  <Grid item xl={3} key={imageIndex}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography variant="h6" fontSize={14} fontWeight={545}>
                        {field.displayname}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          handleImageOpen(field.name, index, imageIndex)
                        }
                      >
                        View
                      </Button>
                    </Stack>
                  </Grid>
                ))
              : item.fields.map((field, fieldIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={fieldIndex}>
                    <TextField
                      error={
                        !rows[field.name] || rows[field.name] === ""
                          ? true
                          : false
                      }
                      label={field.displayname}
                      disabled={false}
                      size={
                        item.category === "Credit Score Section"
                          ? "medium"
                          : "small"
                      }
                      name={field.name}
                      value={
                        field.name === "emi" && rows.loanpurpose === "Hardware"
                          ? emi
                          : rows[field.name]
                      }
                      onChange={(e) =>
                        handleAmountChange(e.target.value, field.name)
                      }
                      inputProps={{
                        style: { color: "#252525" },
                        readOnly:
                          field.name === "loanamount" &&
                          rows.loanpurpose === "Hardware"
                            ? false
                            : true,
                      }}
                      fullWidth
                    />
                    {/* {Errors} */}
                  </Grid>
                ))}
            {item.category === "Credit Score Section" ? (
              <Box ml={2} mt={1} alignContent={"center"} alignItems={"center"}>
                <Button
                  variant="contained"
                  color="secondary"
                  // disabled
                  onClick={handleCreditOpen}
                >
                  Check Cibil score
                </Button>
              </Box>
            ) : null}
            {}
            {(rows.loanpurpose === "SBI Tab" ||
              rows.loanpurpose === "EPSON PLQ 50CS Printer") &&
            item.category === "Loan Request Details" ? (
              <Button
                variant="contained"
                size="small"
                sx={{ height: "50%", mt: "20px", ml: "10px" }}
                color="secondary"
                onClick={() => handleEditClickOpen()}
              >
                {" "}
                edit&nbsp;
                <EditIcon sx={{ fontSize: "15px" }} />
              </Button>
            ) : null}
            {/* <Grid item display={"flex"} justifyContent={"flex-end"}>
          {item.category === 'Loan Request Details' && Edited ?
          <Chip
        label="Edited"
        color="success"
      /> : null }
            </Grid> */}
          </Grid>
        </div>
      ))}
      <Stack
        display={props.page && props.page === "pending" ? "flex" : "none"}
        ml={2}
        direction="row"
        mt={1}
        mb={6}
        spacing={1}
        visibility={props.userType === "agent" ? "hidden" : "visible"}
      >
        {/* <Button color='success' variant='contained' >Approve</Button> */}
        <LoadingButton
          sx={{ width: { md: 130, sm: 140, lg: 140, xl: 140, xs: 140 } }}
          loading={props.updatestatus === "approved" && props.isLoading}
          loadingPosition="start"
          onClick={props.handleApprove}
          color="success"
          variant="contained"
        >
          Approve
        </LoadingButton>
        <LoadingButton
          sx={{ width: { md: 130, sm: 140, lg: 140, xl: 140, xs: 140 } }}
          loading={props.updatestatus === "rejected" && props.isLoading}
          loadingPosition="start"
          onClick={props.handleReject}
          color="error"
          variant="contained"
        >
          Reject
        </LoadingButton>
        {/* credit dialog start */}
        <BootstrapDialog
          onClose={handleEditClose}
          aria-labelledby="customized-dialog-title"
          open={CreditOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Credit score details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleEditClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <CreditForm
              rows={props.selectedItem}
              city={City}
              state={State}
              dob={Dob}
              gender={Gender}
              name={Name}
              address={Address}
              pincode={Pincode}
            />
          </DialogContent>
          {/* <DialogActions>
              <Button autoFocus onClick={handleSubmit}>
                Save changes
              </Button>
            </DialogActions> */}
        </BootstrapDialog>
        {/* credit dialog end */}
        {/*sbi tab product start */}
        <React.Fragment>
          <BootstrapDialog
            onClose={handleEditClose}
            aria-labelledby="customized-dialog-title"
            open={editopen}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Loan Request Details
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleEditClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Formik
                initialValues={{
                  loanscheme: selectedLoanDetails
                    ? selectedLoanDetails.Loanscheme
                    : "",
                  purposeloan: "",
                  loanamount: selectedLoanDetails
                    ? "₹ " + selectedLoanDetails.Loanamount
                    : "",
                  Tenure: selectedLoanDetails ? selectedLoanDetails.Tenure : "",
                  roi: selectedLoanDetails
                    ? selectedLoanDetails.Rateofinterest
                    : "",
                  emi: selectedLoanDetails
                    ? "₹ " + selectedLoanDetails.EMI
                    : "",
                  feescharge: "As per schedule charges",
                }}
                validationSchema={Yup.object().shape({
                  loanscheme: Yup.string(),
                  purposeloan: Yup.string().required(
                    "Purpose of Loan is required"
                  ),
                  loanamount: Yup.string(),
                  Tenure: Yup.string(),
                  roi: Yup.string(),
                  emi: Yup.string(),
                  feescharge: Yup.string(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    if (values) {
                      setStatus({ success: true });
                      setSubmitting(false);
                      handleEditSubmit(
                        values.Tenure,
                        values.emi,
                        values.purposeloan
                      );
                      console.log("values", values);
                    }
                  } catch (err) {
                    console.error(err);
                    if (values) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...others}>
                    <Stack direction="row">
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.purposeloan && errors.purposeloan
                              )}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-lname-register">
                                Purpose of Loan&#42;
                              </InputLabel>
                              <Select
                                id="select"
                                value={values.purposeloan}
                                name="purposeloan"
                                onChange={(event) => {
                                  handleChange(event);
                                  handleLoanPurposeChange(event);
                                }}
                                label="Borrower(s) Type&#42;"
                                input={
                                  <OutlinedInput label="Borrower(s) Type&#42;" />
                                }
                                sx={{
                                  height: "62px",
                                  padding: "15.5px 14px 0px 0px !important",
                                }}
                              >
                                {loandetails.map((data) => (
                                  <MenuItem
                                    value={data.Loanpurpose}
                                    key={data.id}
                                  >
                                    {data.Loanpurpose}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.purposeloan && errors.purposeloan && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-purposeloan-register"
                                >
                                  {errors.purposeloan}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.loanamount && errors.loanamount
                              )}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-loanamount-register">
                                Loan Amount&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="Loan Amount&#42;"
                                id="outlined-adornment-loanamount-register"
                                type="text"
                                // disabled
                                value={
                                  selectedLoanDetails
                                    ? "₹ " + selectedLoanDetails.Loanamount
                                    : ""
                                }
                                name="loanamount"
                                onBlur={handleBlur}
                                onChange={(event) => handleChange(event)}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.loanamount && errors.loanamount && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-loanamount-register"
                                >
                                  {errors.loanamount}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.loanscheme && errors.loanscheme
                              )}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-loanscheme-register">
                                Loan scheme&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="loanscheme&#42;"
                                id="outlined-adornment-loanscheme-register"
                                type="text"
                                // disabled
                                value={
                                  selectedLoanDetails
                                    ? selectedLoanDetails.Loanscheme
                                    : ""
                                }
                                name="loanscheme"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.loanscheme && errors.loanscheme && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-loanscheme-register"
                                >
                                  {errors.loanscheme}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(touched.Tenure && errors.Tenure)}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-Tenure-register">
                                Tenure&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="Tenure&#42;"
                                id="outlined-adornment-Tenure-register"
                                type="text"
                                // disabled
                                value={
                                  selectedLoanDetails
                                    ? selectedLoanDetails.Tenure
                                    : ""
                                }
                                name="Tenure"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.Tenure && errors.Tenure && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-Tenure-register"
                                >
                                  {errors.Tenure}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xl={3} lg={3} md={4} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(touched.roi && errors.roi)}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-roi-register">
                                Rate of Interest&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="Rate of Interest&#42;"
                                id="outlined-adornment-roi-register"
                                type="text"
                                // disabled
                                value={
                                  selectedLoanDetails
                                    ? selectedLoanDetails.Rateofinterest
                                    : ""
                                }
                                name="roi"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.roi && errors.roi && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-roi-register"
                                >
                                  {errors.roi}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(touched.emi && errors.emi)}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-emi-register">
                                EMI&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="EMI&#42;"
                                id="outlined-adornment-emi-register"
                                type="text"
                                // disabled
                                value={
                                  selectedLoanDetails
                                    ? "₹ " + selectedLoanDetails.EMI
                                    : ""
                                }
                                name="emi"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.emi && errors.emi && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-emi-register"
                                >
                                  {errors.emi}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xl={6} lg={6} md={5} xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.feescharge && errors.feescharge
                              )}
                              sx={{ ...theme.typography.customInput }}
                            >
                              <InputLabel htmlFor="outlined-adornment-feescharge-register">
                                Fee / Charges&#42;
                              </InputLabel>
                              <OutlinedInput
                                label="Fee / Charges&#42;"
                                id="outlined-adornment-feescharge-register"
                                type="text"
                                // disabled
                                value={"As per schedule charges"}
                                name="feescharge"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                              {touched.feescharge && errors.feescharge && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-feescharge-register"
                                >
                                  {errors.feescharge}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Grid
                      container
                      spacing={4}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Grid item sm={6} xs={6}>
                        <Box sx={{ mt: 2 }}>
                          <Button
                            disableElevation
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{
                              backgroundColor: "#A556B2",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#A556B2" },
                            }}
                            // onClick={() => Navigate("/existingdetails")}
                          >
                            Save changes
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </DialogContent>
            {/* <DialogActions>
              <Button autoFocus onClick={handleSubmit}>
                Save changes
              </Button>
            </DialogActions> */}
          </BootstrapDialog>
        </React.Fragment>
        {/*sbi tab product end */}
        {/* <Button color='error' variant='contained' onClick={props.handleReject}>Reject</Button> */}
      </Stack>
    </div>
  );
};

export default FieldsTable;
