import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Box, Stack } from "@mui/system";
import FILending from "../../../assets/images/FILending-logo.png";
import axios from "axios";
import JSZip from "jszip";
import html2pdf from "html2pdf.js";
import { Typography } from "@mui/material";
import { GetCloudDocument } from "../../../Axios/Leegality/api";
import { IoMdArrowRoundBack } from "react-icons/io";

function ApplicationStatus(props) {
  const data = props.data;
  //console.log('props ',data);

  const DownloadCloud = (index) => {
    let url = data[index].cloud_storage_url;
    if (url) {
      GetCloudDocument(url);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const DownloadForm = async (index) => {
    //console.log("for downloading ", data[index]);
    const formHtml = `
        <html>
    <head>
      <style>
        /* Add your custom CSS styles here */
        body {
          font-family: Arial, sans-serif;
          padding: 20px;
        }

        label {
          font-weight:550;
          background-color: white;
          font-size: 12px;
          margin-bottom: 5px;
          color:grey

        }

        .Container{
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }
        .Container2{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

        .item{
            display:flex;
            flex-direction:column;
            margin-top: 5px;
        }

        img{
            width: 100%;
            object-fit:contain;
            aspect-ratio:16/9
            max-width:none;
            max-height:none;
        }



        .category{
            text-align: center;
            font-size: 16px;
        }

        input{
            padding: 5px;
            border-radius: 3px;
            border:0.5px solid grey;
            width: 1fr;
            font-size:1em;
            line-height: 1.5;
            box-sizing: border-box;
            color:grey;
        }

        textarea{
            color:grey
        }



      </style>
    </head>
    <body>
      <div class="Container">
        <div class="item">
            <img src=${FILending}  alt="logo" />
          </div>
        </div>
      <h1 class="category">CSP Details</h1>
      <div class="Container">
        <div class="item">
            <label>Name of applicant</label>
            <input value="${data[index].name}"></input>
        </div>
        <div class="item">
            <label>Father / Spouse's Name</label>
            <input value="${data[index].fatherspouse}"></input>
        </div>
        <div class="item">
            <label>Dob</label>
            <input value="${data[index].dob}"></input>
        </div>
        <div class="item">
            <label>Gender</label>
            <input value="${
              data[index].gender === "M" || data[index].gender === "Male"
                ? "Male"
                : data[index].gender === "F" || data[index].gender === "Female"
                ? "Female"
                : "Others"
            }"></input>
        </div>
        <div class="item">
            <label>Marital Status</label>
            <input value="${data[index].martailstatus}"></input>
        </div>
        <div class="item">
            <label>Nationality</label>
            <input value="${data[index].nationality}"></input>
        </div>
        <div class="item">
            <label>Aadhar Number</label>
            <input value="${data[index].aadhar}"></input>
        </div>
        <div class="item">
            <label>Pan Number</label>
            <input value="${data[index].pan}"></input>
        </div>
        <div class="item">
            <label>Contact No</label>
            <input value="${data[index].phone}"></input>
        </div>
        <div class="item">
            <label>Email</label>
            <input value="${data[index].email}"></input>
        </div>
        <div class="item">
            <label>CSP Code</label>
            <input value="${data[index].cspcode}"></input>
        </div>
      </div>
      <div class="Container2">
        <div class="item">
            <label>Bank Name</label>
            <input value="${data[index].bankname}"></input>
        </div>
        <div class="item">
            <label>Branch Name</label>
            <input value=${data[index].branch}></input>
        </div>
        <div class="item">
            <label>Residential Address</label>
            <textarea >"${data[index].resaddress}"</textarea>
        </div>
        <div class="item">
            <label>Residential Type</label>
            <input value="${data[index].resaddresstype}"></input>
        </div>
      </div>
      <div class="Container">
        <div class="item">
            <label>No of years at above address</label>
            <input value="${data[index].residingyear}"></input>
        </div>
        <div class="item">
            <label>CSP Centre Address</label>
        <input value="${data[index].cspaddress}"></input>
        </div>
        <div class="item">
            <label>No of years of CSP Center</label>
            <input value="${data[index].cspyear}"></input>
        </div>
        <div class="item">
            <label>Owned/Rented</label>
            <input value="${data[index].cspcenter}"></input>
        </div>
        <div class="item">
            <label>Annual Income</label>
            <input value="${data[index].annualincome}"></input>
        </div>
      </div>
      <br/>
      <h1 class="category">Banking Details</h1>
      <div class="Container2">
        <div class="item">
            <label>Name of Account Holder</label>
            <input value="${data[index].accname}"></input>
        </div>
        <div class="item">
            <label>Name of Bank and branch</label>
            <input value="${data[index].bankbranch}"></input>
        </div>
        <div class="item">
            <label>Account Number</label>
            <input value="${data[index].accountno}"></input>
        </div>
        <div class="item">
            <label>IFSC Code</label>
            <input value=${data[index].ifsc}></input>
        </div>
        <div class="item">
            <label>Type of Account</label>
            <input value=${data[index].accounttype}></input>
        </div>
      </div>
      <br/>
      <h1 class="category">Loan request details</h1>
      <div class="Container">
        <div class="item">
            <label>Purpose of Loan</label>
            <input value="${data[index].loanpurpose}"></input>
        </div>
        <div class="item">
            <label>Loan Amount</label>
            <input value=${data[index].loanamount}></input>
        </div>
        <div class="item">
            <label>Loan Scheme</label>
            <input value=${data[index].loanscheme}></input>
        </div>
        <div class="item">
            <label>Tenure (Months)</label>
            <input value=${data[index].tenure}></input>
        </div>
        <div class="item">
            <label>Rate of Interest (Reducing)</label>
            <input value=${data[index].roi}></input>
        </div>
        <div class="item">
            <label>EMI (INR)</label>
            <input value=${data[index].emi}></input>
        </div>

        <div class="item">
            <label>Fee / charges</label>
            <input value="${data[index].feescharge}"></input>
        </div>

        <div class="item">
            <label>Borrower type</label>
            <input value="${data[index].borrowertype}"></input>
        </div>

        <div class="item">
          <label>Referred by</label>
          <input value="${data[index].referred_by}"></input>
         </div>


      </div>
    </body>
</html>
    `;
    const options = {
      margin: 16,
      filename: `${data[index].name}.pdf`,
      image: { type: "jpeg", quality: 100 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const pdfBlob = await html2pdf().from(formHtml).set(options).output("blob");
    const imageRequest = Object.entries(data[index].file_urls).map(
      ([key, value]) =>
      value?
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/download`,
          { url: value },
          { responseType: "blob" ,
          headers:{
            Authorization:sessionStorage.getItem('user_token')
          }
        }
        ) : null
    );
    const imageResponses = await Promise.all(imageRequest);

    const zip = new JSZip();
    zip.file(`${data[index].name}.pdf`, pdfBlob, { binary: true });
    imageResponses.forEach((response, subIndex) => {
      const temp = Object.keys(data[index].file_urls)[subIndex];
      const split = temp.split("_");
      const imageName = `${split[0]}.jpg`;
      zip.file(imageName, response?response.data:'none', { binary: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${data[index].name}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "60vh",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Button
            sx={{ width: "fit-content", fontSize: "22px", color: "black" }}
            onClick={() => props.setSelected(null)}
          >
            <IoMdArrowRoundBack />
          </Button>
          <Box width="50%"></Box>
        </Stack>
        {props.active === 1 ? (
          <TableContainer component={Paper}>
            <Typography m={1} variant="h6">
              Application Detail
            </Typography>
            <Table
              sx={{ minWidth: 650, margin: "10px" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Loan Id
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Date of Agreement
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Expire Date
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  Array.isArray(data) &&
                  data.map((item, index) => (
                    <TableRow
                      key={item.application_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontSize: "16px" }}
                      >
                        {item.loanid}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "16px" }}>
                        {item.application_status}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "16px" }}>
                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "16px" }}>
                        {item.legality_doc_sign_status
                          ? ""
                          : item.expiry_date
                          ? formatDate(item.expiry_date)
                          : "N/A"}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "16px" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => DownloadForm(index)}
                        >
                          <FaCloudDownloadAlt />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : props.active === 2 ? (
          <TableContainer component={Paper}>
            <>
              <Typography m={1} variant="h6">
                Loan Sanction & Agreement Detail
              </Typography>
            </>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Loan Id
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Loan Sanction Date
                  </TableCell>
                  {/* <TableCell align="right" sx={{fontSize:"16px", fontWeight:"600"}}>Digital Signature</TableCell> */}
                  <TableCell
                    align="right"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) &&
                  data.map((item, index) => (
                    <TableRow
                      key={item.application_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        x={{ fontSize: "16px" }}
                      >
                        {item.loanid}
                      </TableCell>
                      <TableCell align="right" x={{ fontSize: "16px" }}>
                        {item.application_status}
                      </TableCell>
                      <TableCell align="right" x={{ fontSize: "16px" }}>
                        {item.updated_at ? formatDate(item.updated_at) : "N/A"}
                      </TableCell>
                      {/* <TableCell align="right" x={{fontSize:"16px"}}><Button variant='outlined'>Click here to sign</Button></TableCell> */}
                      {item.legality_doc_sign_status ? (
                        <TableCell align="right" sx={{ fontSize: "16px" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={{ width: "2rem", display: "flex" }}
                            onClick={() => DownloadCloud(index)}
                          >
                            <FaCloudDownloadAlt />
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "red",
                          }}
                        >
                          process incomplete
                        </TableCell>
                      )}
                      {/* <TableCell align="right" x={{fontSize:"16px"}}><Button variant='contained' color='secondary' sx={{width:'2rem',display:item.legality_doc_sign_status?'block':'none'}} onClick={()=>DownloadCloud(index)}><FaCloudDownloadAlt /></Button></TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Loan Id</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Apply Date</TableCell>
                  <TableCell align="right">Digital Signature</TableCell>
                  <TableCell align="right">Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={data.application_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.application_id}
                  </TableCell>
                  <TableCell align="right">{data.application_status}</TableCell>
                  <TableCell align="right">{data.name}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined">Click here to sign</Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ width: "2rem" }}
                      fullWidth
                      onClick={DownloadForm}
                    >
                      <FaCloudDownloadAlt />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Stack direction="row" justifyContent="space-between">
          <Box width="50%"></Box>
          <Button
            sx={{ width: "fit-content" }}
            variant="contained"
            color="secondary"
            onClick={() => props.setSelected(null)}
          >
            Back
          </Button>
        </Stack>
      </div>
    </>
  );
}

export default ApplicationStatus;
