import { Button, Stack } from "@mui/material";
import React from "react";
import Pagination from "@mui/material/Pagination";

const Paginate = (props) => {
  return (
    <div>
      <Stack spacing={2} mt={2}>
        <Pagination  count={Math.ceil(props.data / 20)} onChange={props.fun} />
      </Stack>

      {/* <Stack direction='row' spacing={1}>
    {
      [...Array(Math.min(Math.ceil(props.data / 10), 3))].map((_, index) => (
        <Button onClick={() => props.fun(index)} key={index} size='small' variant={props.ActiveIndex === index ? 'contained' : 'outlined'} color="primary">
          {index + 1}
        </Button>
      ))
    }
    {props.ActiveIndex < 3&&!props.ActiveIndex+1&& <Button disabled>{'...'}</Button>}
    {props.ActiveIndex < 3 && [...Array(props.ActiveIndex)].map((_, index) => (
      <Button onClick={() => props.fun(index )} key={index + 3} size='small'  variant={props.ActiveIndex === index ? 'contained' : 'outlined'} color="primary">
        {index }
      </Button>
    ))}
    {/* <Button onClick={() => props.fun(Math.ceil(props.data / 10) - 1)} size='small' variant='outlined' color="primary">{Math.ceil(props.data / 10)}</Button> */}
      {/* </Stack>  */}
    </div>
  );
};

export default Paginate;
