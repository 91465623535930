import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

export const postData = async (data) => {
  try {
    await axios
      .post(`${url}/leegality/create_sign_request`, data)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
        }
      });
  } catch (error) {
    //console.log("error",error)
  }
};

export const getStatus = async (id, setStatus, handleStatusErr) => {
  try {
    await axios
      .get(`${url}/leegality/status_check`, {
        headers: {
          Authorization: sessionStorage.getItem("user_token"),
        },
        params: {
          document_id: id,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setStatus(res);
        }
      });
  } catch (error) {
    handleStatusErr();
    //console.log("error",error)
  }
};

export const GetCloudDocument = async (imgUrl) => {
  try {
    await axios
      .post(
        `${url}/user/application_download`,
        { url: imgUrl },
        {
          headers: {
            Authorization: sessionStorage.getItem("user_token"),
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Agreement.pdf";
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      });
  } catch (error) {
    //console.log("error",error)
  }
};
