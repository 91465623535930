import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Box,
  FormHelperText,
  OutlinedInput,
  FormControl,
  InputLabel,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import jsPDF from "jspdf";
import "jspdf-autotable";
import LoadingButton from "@mui/lab/LoadingButton";
import { CreditApi } from "../../../Axios/ServiceApi";
import logo from '../../../assets/images/equifax-removebg-preview.png'
// import CryptoJS, { AES } from 'crypto-js';

const CreditForm = (props, { ...others }) => {
  const theme = useTheme();
  const Data = props.rows;
  console.log("props", props.rows);
  const [CreditSuccess, setCreditSuccess] = useState(false);
  const [CreditErr, setCreditErr] = useState(false);
  const [CreditData, setCreditData] = useState("");
  const [MapData, setMapData] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [ErrRes, setErrRes] = useState(false);
  const [CreditErrDes, setCreditErrDes] = useState(false);
  const [ClientId,setClientId] = useState(false)

  // const generateSecretKey = () => {
  //   const keyLength = 32; // 32 bytes = 256 bits (AES-256)
  //   const buffer = new Uint8Array(keyLength);
  //   crypto.getRandomValues(buffer);
  //   return Array.from(buffer, (byte) =>
  //       byte.toString(16).padStart(2, '0')
  //   ).join('');
  // };
  // console.log("generateSecretKey",generateSecretKey());

  const handleApi = (postdata) => {
    CreditApi(
      postdata,
      handleCreditRes,
      handleCredirErr,
      handleCreditErrdesc,
      setCreditErrDes,
      setCreditData,
      setClientId,
      setIsLoading
    );
  };
  const handleCreditRes = () => {
    setCreditSuccess(true);
    setIsLoading(false);
  };

  const handleCreditErrdesc = () => {
    setErrRes(true);
    setIsLoading(false);
  };

  const handleCredirErr = () => {
    setCreditErr(true);
    setIsLoading(false);
  };

  const handleClose = () => {
    setCreditErr(false);
    setCreditSuccess(false);
    setErrRes(false);
  };

  // const flattenData = (CreditData) => {
  //   return Array.isArray(CreditData) && CreditData.reduce((acc, item) => {
  //     if (item.CIRReportData && item.CIRReportData.EnquirySummary) {
  //       const enquirySummary = item.CIRReportData.EnquirySummary;
  //       if (item.CIRReportData.IDAndContactInfo && Array.isArray(item.CIRReportData.IDAndContactInfo.AddressInfo)) {
  //         const addressInfo = item.CIRReportData.IDAndContactInfo.AddressInfo.map(addr => ({
  //           'Past 12 Months': enquirySummary.Past12Months,
  //           'Past 24 Months': enquirySummary.Past24Months,
  //           'Past 30 Days': enquirySummary.Past30Days,
  //           'Purpose': enquirySummary.Purpose,
  //           'Recent': enquirySummary.Recent,
  //           'Total': enquirySummary.Total,
  //           'Address': addr.Address,
  //           'Postal': addr.Postal,
  //           'Reported Date': addr.ReportedDate,
  //           'Seq': addr.Seq,
  //           'State': addr.State,
  //           'Type': addr.Type,
  //         }));
  //         acc.push(...addressInfo);
  //       }
  //     }
  //     return acc;
  //   }, []);
  // };

  //

  const generatePDF = () => {
    if (!CreditData) return;
    // console.log("CreditData", CreditData);

    const doc = new jsPDF();

    // const logo = 'data:image/jpeg;base64,...'; // Replace with your logo in base64 format or use a URL.

    // Dynamic values for reference number, client ID, and date/time
    // const referenceNo = '100005754';
    const clientId = ClientId?ClientId.ClientID:'-';
    const title = 'PRESCREEN CREDIT SCORE';
    const orderId = CreditData.InquiryResponseHeader?CreditData.InquiryResponseHeader.ReportOrderNO:'-'
    const date = CreditData.InquiryResponseHeader?CreditData.InquiryResponseHeader.Date:new Date().toLocaleDateString();
    const time = CreditData.InquiryResponseHeader?CreditData.InquiryResponseHeader.Time:new Date().toLocaleTimeString();

    // Header layout
    const addHeader = () => {
      // Add Logo (adjust the x, y, width, height values)
      doc.addImage(logo, 'JPEG', 10, 10, 45, 10); // X: 10, Y: 10, Width: 30, Height: 15

      // Add Reference No and Client ID below the logo
      doc.setFontSize(10);
      doc.text(`Client ID: ${clientId}`,10,35);
      doc.text(`Report Order NO : ${orderId}`,10,40);
      // doc.text(`Reference Number: ${referenceNo}`,10,40);
      // Add Date and Time on the right side
      doc.setFontSize(10);
      doc.text(`Date: ${date}`, 200, 35, { align: 'right' });
      doc.text(`Time: ${time}`, 200, 40, { align: 'right' });

      // Add Centered Title
      doc.setFontSize(18);
      const offset = 10;
      doc.text(title, doc.internal.pageSize.getWidth() / 2 + offset, 20, { align: 'center' });


      // Add a line below the header
      doc.setLineWidth(0.5);
      doc.line(10, 44, 200, 44); // Draw line from (x1, y1) to (x2, y2)
    };



    // Custom formatting for the PDF
    doc.setFontSize(16);
    // doc.text(`${Data.name} Credit Report`);

    const PersonalInfoDetailsName = CreditData.CIRReportData.IDAndContactInfo
      .PersonalInfo.Name.FullName
      ? CreditData.CIRReportData.IDAndContactInfo.PersonalInfo.Name.FullName
      : "";
    // const PersonalInfoDetailDob = CreditData.CIRReportData.IDAndContactInfo
    //   .PersonalInfo.DateOfBirth
    //   ? CreditData.CIRReportData.IDAndContactInfo.PersonalInfo.DateOfBirth
    //   : "";
    // const PersonalInfoGender = CreditData.CIRReportData.IDAndContactInfo
    //   .PersonalInfo.Gender
    //   ? CreditData.CIRReportData.IDAndContactInfo.PersonalInfo.Gender
    //   : "";
    // const PersonalInfoAge = CreditData.CIRReportData.IDAndContactInfo
    //   .PersonalInfo.Age.Age
    //   ? CreditData.CIRReportData.IDAndContactInfo.PersonalInfo.Age.Age
    //   : "";
    // const PersonalInfoEmail = CreditData.CIRReportData.IDAndContactInfo
    //   .EmailAddressInfo[0].EmailAddress
    //   ? CreditData.CIRReportData.IDAndContactInfo.EmailAddressInfo[0]
    //       .EmailAddress
    //   : "no email found";
    const PersonalInfoNumber = CreditData.InquiryRequestInfo.InquiryPhones[0]
      .Number
      ? CreditData.InquiryRequestInfo.InquiryPhones[0].Number
      : "";
    const PersonalInfoScoreDetails = CreditData.CIRReportData.ScoreDetails[0]
      .Value
      ? CreditData.CIRReportData.ScoreDetails[0].Value
      : "";

    // console.log("PersonalInfoDetailsName",PersonalInfoDetail);

    // Convert JSON data to a formatted string
    // const jsonData = JSON.stringify(CreditData, null, 20);
    //   const lines = [
    //     `${Data.name} Credit Report`,
    //     `Name: ${PersonalInfoDetailsName}`,
    //     `Date of birth: ${PersonalInfoDetailDob}`,
    //     `Credit Score: ${PersonalInfoScoreDetails}`,
    //     `Age: ${PersonalInfoAge}`,
    //     `Gender: ${PersonalInfoGender}`,
    //     `Email: ${PersonalInfoEmail}`,
    //     `Mobile number: ${PersonalInfoNumber}`,

    // ];

    //   // Split the JSON string into lines to fit the
    //   let x = 10;
    //           let y = 10;
    //           const lineHeight = 10; // Adjust line height as needed

    //           // Add each line of text at the specified position
    //           lines.forEach(line => {
    //               doc.text(line, x, y);
    //               y += lineHeight; // Move y position for the next line
    //           });

    addHeader();
let startY = 50;
    const personalInfode =
      CreditData.CIRReportData.IDAndContactInfo.PersonalInfo;
    // doc.text(`${personalInfode.Name.FullName} Credit Report`, 14, doc.autoTable.previous.finalY + 10);
    // doc.text(`${personalInfode.Name.FullName} Credit Report`,14, doc.autoTable.previous.finalY + 10,);
    doc.autoTable({
      startY,
      head: [["Name", "Value"]],
      body: [
        [
          "Full Name",
          personalInfode.Name.FullName ? personalInfode.Name.FullName : "",
        ],
        [
          "First Name",
          personalInfode.Name.FirstName ? personalInfode.Name.FirstName : "",
        ],
        [
          "Last Name",
          personalInfode.Name.LastName ? personalInfode.Name.LastName : "",
        ],
        ["Credit score(ERS)", `${PersonalInfoScoreDetails}`],
        ["Mobile number", `${PersonalInfoNumber}`],
        // ["Email", `${PersonalInfoEmail}`],
        ["Age", personalInfode.Age.Age ? personalInfode.Age.Age : ""],
        [
          "Date of Birth",
          personalInfode.DateOfBirth ? personalInfode.DateOfBirth : "",
        ],
        ["Gender", personalInfode.Gender ? personalInfode.Gender : ""],
        [
          "Occupation",
          personalInfode.Occupation ? personalInfode.Occupation : "",
        ],
        [
          "Total Income",
          personalInfode.TotalIncome ? personalInfode.TotalIncome : "",
        ],
      ],
      headStyles: {
        textColor: [255, 255, 255], // White font color
        fillColor: [158, 28, 51], // #9E1C33 background color
      },
    });

    // doc.text(text, 10, 10);

    // const lines = doc.splitTextToSize(jsonData, 380);
    // doc.text(lines, 40, 50);
    // doc.addPage();

    // Address start

    // const AddressInfo = CreditData.CIRReportData.IDAndContactInfo.AddressInfo[0]
    // console.log("AddressInfo",AddressInfo);

    // // Define the columns and rows for the table
    // const AddressInfocolumns = Object.keys(AddressInfo).map(key => ({ title: key, dataKey: key }));
    // const AddressInforows = Object.values(AddressInfo).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // // const tableTitle = 'Address Info';
    // doc.autoTable({
    //   head: [AddressInfocolumns.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [AddressInforows.map(col => col.dataKey)],
    //   // didDrawPage: (data) => {
    //   //   // Add the title to each page
    //   //   doc.setFontSize(15);
    //   //   doc.text(tableTitle, data.settings.margin.left, 20);
    //   // }
    // });
    // const AddressInfo1 = CreditData.CIRReportData.IDAndContactInfo.AddressInfo[1]
    // console.log("AddressInfo",AddressInfo);

    // // Define the columns and rows for the table
    // const AddressInfocolumns1 = Object.keys(AddressInfo1).map(key => ({ title: key, dataKey: key }));
    // const AddressInforows1 = Object.values(AddressInfo1).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [AddressInfocolumns1.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [AddressInforows1.map(col => col.dataKey)],

    // });
    // const AddressInfo2 = CreditData.CIRReportData.IDAndContactInfo.AddressInfo[2]

    // // Define the columns and rows for the table
    // const AddressInfocolumns2 = Object.keys(AddressInfo2).map(key => ({ title: key, dataKey: key }));
    // const AddressInforows2 = Object.values(AddressInfo2).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [AddressInfocolumns2.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [AddressInforows2.map(col => col.dataKey)],

    // });
    // const AddressInfo3 = CreditData.CIRReportData.IDAndContactInfo.AddressInfo[3]
    // console.log("AddressInfo",AddressInfo);

    // // Define the columns and rows for the table
    // const AddressInfocolumns3 = Object.keys(AddressInfo3).map(key => ({ title: key, dataKey: key }));
    // const AddressInforows3 = Object.values(AddressInfo3).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [AddressInfocolumns3.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [AddressInforows3.map(col => col.dataKey)],

    // });
    // const AddressInfo4 = CreditData.CIRReportData.IDAndContactInfo.AddressInfo[4]

    // // Define the columns and rows for the table
    // const AddressInfocolumns4 = Object.keys(AddressInfo4).map(key => ({ title: key, dataKey: key }));
    // const AddressInforows4 = Object.values(AddressInfo4).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [AddressInfocolumns4.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [AddressInforows4.map(col => col.dataKey)],

    // });

    // Address Info Section
    const addresses = CreditData.CIRReportData.IDAndContactInfo.AddressInfo;
    if (addresses) {
      const addressRows = addresses.map((address) => [
        address.Seq,
        address.Type,
        address.Address,
        address.Postal,
        address.State,
        address.ReportedDate,
      ]);
      doc.text("Address", 14, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [["Seq", "Type", "Address", "Postal", "State", "Reported Date"]],
        body: addressRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No address available.", 14, 36);
    }

    // Address end

    // email start

    const EmailAddressInfo =
      CreditData.CIRReportData.IDAndContactInfo.EmailAddressInfo;
    console.log("EmailAddressInfo", EmailAddressInfo);

    // Define the columns and rows for the table
    if (EmailAddressInfo) {
      // const EmailAddressInfocolumn = Object.keys(EmailAddressInfo).map(
      //   (key) => ({
      //     title: key,
      //     dataKey: key,
      //   })
      // );
      // const EmailAddressInforow = Object.values(EmailAddressInfo).map(
      //   (key) => ({
      //     title: key,
      //     dataKey: key,
      //   })
      // );

      // // Generate the table in the PDF
      // doc.setFontSize(16);
      const tableData = [
        ...EmailAddressInfo.map((item) => [
          "Email ID",
          item.EmailAddress,
          item.ReportedDate,
          item.seq,
        ]),
        // ...EmailAddressInfo.OtherId.map(item => ["Other ID", item.IdNumber, item.ReportedDate, item.seq]),
        // ...EmailAddressInfo.PANId.map(item => ["PAN ID", item.IdNumber, item.ReportedDate, item.seq])
      ];
      doc.text("Email", 14, doc.autoTable.previous.finalY + 10);

      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [["EmailAddress", "ReportedDate", "Seq"]],
        body: tableData,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
      // doc.autoTable({
      //   startY: doc.autoTable.previous.finalY + 14,
      //   head: [EmailAddressInfocolumn.map((col) => col.dataKey)],
      //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
      //   body: [EmailAddressInforow.map((col) => col.dataKey)],
      // });
    } else {
      // doc.text("No email available.", 14, 36);
      console.log("email error");
    }

    // email end

    // PhoneInfo start

    const PhoneInfo = CreditData.CIRReportData.IDAndContactInfo.PhoneInfo;
    console.log("PhoneInfo", PhoneInfo);

    // Define the columns and rows for the table
    if (PhoneInfo) {
      const PhoneInfocolumn = PhoneInfo.map((data) => {
        return Object.entries(data).map(([item, itemdata]) => {
          return { title: item, dataKey: itemdata };
        });
      });
      console.log("PhoneInfocolumn", PhoneInfocolumn);

      const resultphone = PhoneInfocolumn[0].map((col) => col.title);
      console.log("resultphone", resultphone);

      const resultitemdata = PhoneInfocolumn.map((col) =>
        col.map((res) => {
          return res.dataKey;
        })
      );
      console.log("resultitemdata", resultitemdata);
      // const PhoneInforow = Object.values(PhoneInfo).map(key => ({ title: key, dataKey: key }));

      // Generate the table in the PDF
      doc.setFontSize(16);
      doc.text("Contact", 14, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [resultphone],
        // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
        body: resultitemdata,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No contacts available.", 14, 36);
    }

    // PhoneInfo end

    // IdentityInfo start

    const NationalIDCard =
      CreditData.CIRReportData.IDAndContactInfo.IdentityInfo;

    if (NationalIDCard) {
      const tableData = [
        ...(Array.isArray(NationalIDCard.NationalIDCard)
          ? NationalIDCard.NationalIDCard.map((item) => [
              "National ID Card",
              item.IdNumber,
              item.ReportedDate,
              item.seq,
            ])
          : []),
        ...(Array.isArray(NationalIDCard.OtherId)
          ? NationalIDCard.OtherId.map((item) => [
              "Other ID",
              item.IdNumber,
              item.ReportedDate,
              item.seq,
            ])
          : []),
        ...(Array.isArray(NationalIDCard.PANId)
          ? NationalIDCard.PANId.map((item) => [
              "PAN ID",
              item.IdNumber,
              item.ReportedDate,
              item.seq,
            ])
          : []),
        ...(Array.isArray(NationalIDCard.RationCard)
          ? NationalIDCard.RationCard.map((item) => [
              "Ration ID Card",
              item.IdNumber,
              item.ReportedDate,
              item.seq,
            ])
          : []),
          ...(Array.isArray(NationalIDCard.VoterID)
          ? NationalIDCard.VoterID.map((item) => [
              "Voter ID Card",
              item.IdNumber,
              item.ReportedDate,
              item.seq,
            ])
          : [])
      ];
      doc.text("ID Proof", 14, doc.autoTable.previous.finalY + 10);

      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [["ID Type", "ID Number", "Reported Date", "Seq"]],
        body: tableData,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No ID Proof available.", 14, 36);
    }
    // Define the columns and rows for the table
    // if (NationalIDCard) {
    //   const NationalIDCardcolumn = Object.keys(NationalIDCard).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));
    //   const NationalIDCardrow = Object.values(NationalIDCard).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));

    //   // Generate the table in the PDF
    //   doc.setFontSize(16);
    //   doc.text("Id proof", 14, doc.autoTable.previous.finalY + 10);

    //   doc.autoTable({
    //     startY: doc.autoTable.previous.finalY + 14,
    //     head: [NationalIDCardcolumn.map((col) => col.dataKey)],
    //     // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //     body: [NationalIDCardrow.map((col) => col.dataKey)],
    //   });
    // } else {
    //   doc.text("No national ID available.", 14, 36);
    // }

    // const PANId =
    //   CreditData.CIRReportData.IDAndContactInfo.IdentityInfo.PANId[0];

    // // Define the columns and rows for the table
    // if (PANId) {
    //   const PANIdcolumn = Object.keys(PANId).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));
    //   const PANIdrow = Object.values(PANId).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));

    //   // Generate the table in the PDF
    //   doc.setFontSize(16);
    //   doc.autoTable({
    //     head: [PANIdcolumn.map((col) => col.dataKey)],
    //     // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //     body: [PANIdrow.map((col) => col.dataKey)],
    //   });
    // } else {
    //   doc.text("No pan ID available.", 14, 36);
    // }

    // const VoterID =
    //   CreditData.CIRReportData.IDAndContactInfo.IdentityInfo.VoterID[0];

    // // Define the columns and rows for the table
    // if (VoterID) {
    //   const VoterIDcolumn = Object.keys(VoterID).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));
    //   const VoterIDrow = Object.values(VoterID).map((key) => ({
    //     title: key,
    //     dataKey: key,
    //   }));

    //   // Generate the table in the PDF
    //   doc.setFontSize(16);
    //   doc.autoTable({
    //     head: [VoterIDcolumn.map((col) => col.dataKey)],
    //     // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //     body: [VoterIDrow.map((col) => col.dataKey)],
    //   });
    // } else {
    //   doc.text("No Voter ID available.", 14, 36);
    // }

    // IdentityInfo end

    // personal info start

    // const PersonalInfo = CreditData.CIRReportData.IDAndContactInfo.PersonalInfo

    // // Define the columns and rows for the table
    // const PersonalInfocolumn = Object.keys(PersonalInfo).map(key => ({ title: key, dataKey: key }));
    // const PersonalInforow = Object.values(PersonalInfo).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [PersonalInfocolumn.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [PersonalInforow.map(col => col.dataKey)],

    // });

    // const personalInfo = CreditData.CIRReportData.IDAndContactInfo.PersonalInfo;
    // doc.text('Personal Info', 14, doc.autoTable.previous.finalY + 10);
    // doc.autoTable({
    //   startY: doc.autoTable.previous.finalY + 14,
    //   head: [['Field', 'Value']],
    //   body: [
    //     ['Full Name', personalInfo.Name.FullName],
    //     ['First Name', personalInfo.Name.FirstName],
    //     ['Last Name', personalInfo.Name.LastName],
    //     ['Age', personalInfo.Age.Age],
    //     ['Date of Birth', personalInfo.DateOfBirth],
    //     ['Gender', personalInfo.Gender],
    //     ['Occupation', personalInfo.Occupation],
    //     ['Total Income', personalInfo.TotalIncome]
    //   ]
    // });
    // personal info end

    // Recent activities start

    const RecentActivities = CreditData.CIRReportData.RecentActivities;
    // console.log("RecentActivities", RecentActivities);

    // Define the columns and rows for the table
    if (RecentActivities) {
      const RecentActivitiescolumn = Object.keys(RecentActivities).map(
        (key) => ({
          title: key,
          dataKey: key,
        })
      );
      const RecentActivitiesrow = Object.values(RecentActivities).map(
        (key) => ({
          title: key,
          dataKey: key,
        })
      );

      // Generate the table in the PDF
      doc.setFontSize(16);
      doc.text("Recent activities", 14, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [RecentActivitiescolumn.map((col) => col.dataKey)],
        // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
        body: [RecentActivitiesrow.map((col) => col.dataKey)],
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Recent activities available.", 14, 36);
    }

    // Recent activities end

    // RetailAccountsSummary start

    // const RetailAccountsSummary = CreditData.CIRReportData.RetailAccountsSummary
    // console.log("RetailAccountsSummary",RetailAccountsSummary);

    // // Define the columns and rows for the table
    // const RetailAccountsSummarycolumns = Object.keys(RetailAccountsSummary).map(key => ({ title: key, dataKey: key }));
    // const RetailAccountsSummaryrows = Object.values(RetailAccountsSummary).map(key => ({ title: key, dataKey: key }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [RetailAccountsSummarycolumns.map(col => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [RetailAccountsSummaryrows.map(col => col.dataKey)],

    // });

    // RetailAccountsSummary end

    // // score details start

    // const ScoreDetails = CreditData.CIRReportData.ScoreDetails[0];
    // console.log("ScoreDetails", ScoreDetails);

    // // Define the columns and rows for the table
    // const ScoreDetailscolumns = Object.keys(ScoreDetails).map((key) => ({
    //   title: key,
    //   dataKey: key,
    // }));
    // const ScoreDetailsrows = Object.values(ScoreDetails).map((key) => ({
    //   title: key,
    //   dataKey: key,
    // }));

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.text("Scoring Elements", 14, doc.autoTable.previous.finalY + 10);
    // doc.autoTable({
    //   startY: doc.autoTable.previous.finalY + 14,
    //   head: [ScoreDetailscolumns.map((col) => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [ScoreDetailsrows.map((col) => col.dataKey)],
    // });

    // // score details end

    // ScoringElements start

    const ScoreDetail =
      CreditData.CIRReportData.ScoreDetails[0].ScoringElements;

    // Define the columns and rows for the table
    if (ScoreDetail) {
      const ScoreDetailscolumn = ScoreDetail.map((data) => {
        return Object.entries(data).map(([item, itemdata]) => {
          return { title: item, dataKey: itemdata };
        });
      });

      const ScoreDetailsphone = ScoreDetailscolumn[0].map((col) => col.title);

      const ScoreDetailsitemdata = ScoreDetailscolumn.map((col) =>
        col.map((res) => {
          return res.dataKey;
        })
      );

      // Generate the table in the PDF
      doc.setFontSize(16);
      // doc.text("Credit Score Details", 14, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        head: [ScoreDetailsphone],
        // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
        body: ScoreDetailsitemdata,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Scoreing elemen available.", 14, 36);
    }

    // ScoringElements end

    // enquiry details start
    const EnquirySummary = CreditData.CIRReportData.EnquirySummary;
    // console.log("EnquirySummary", EnquirySummary);

    // Define the columns and rows for the table
    if (EnquirySummary) {
      const columns = Object.keys(EnquirySummary).map((key) => ({
        title: key,
        dataKey: key,
      }));
      const rows = Object.values(EnquirySummary).map((key) => ({
        title: key,
        dataKey: key,
      }));

      // console.log("flatData", rows);
      // const tableTitle = 'Enquiry Summary';

      // Generate the table in the PDF
      // doc.text("Enquiry Summary", 20, 20);
      doc.text("Enquiry Summary", 14, doc.autoTable.previous.finalY + 10);
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [columns.map((col) => col.dataKey)],
        // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
        body: [rows.map((col) => col.dataKey)],
        margin: { top: 25 },
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
        // headStyles: { fontSize: 14 },
        // bodyStyles: { fontSize: 12 },
      });
    } else {
      doc.text("No enquiry details available.", 14, 36);
    }

    // enquiry details end

    // OtherKeyInd start
    const OtherKeyInd = CreditData.CIRReportData.OtherKeyInd;

    // Check if RetailAccountsSummary exists
    if (OtherKeyInd) {
      const summaryData = OtherKeyInd;
      const summaryRows = [
        ["Age of oldest trade", summaryData.AgeOfOldestTrade],
        ["All lines ever written", summaryData.AllLinesEVERWritten],
        [
          "All lines ever written in 6months",
          summaryData.AllLinesEVERWrittenIn6Months,
        ],
        [
          "All lines ever written in 9months",
          summaryData.AllLinesEVERWrittenIn9Months,
        ],
        ["Number of open trades", summaryData.NumberOfOpenTrades],
      ];
      doc.text("Other Key Ind", 14, doc.autoTable.previous.finalY + 10);

      // Add table for RetailAccountsSummary
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        // startY: 36,
        head: [["Name", "Value"]],
        body: summaryRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Other Key Ind available.", 14, 36);
    }
    // OtherKeyInd end

    // // InquiryRequestInfo start

    // const InquiryRequestInfo = CreditData.InquiryRequestInfo;
    // console.log("InquiryRequestInfo", InquiryRequestInfo);

    // // Define the columns and rows for the table
    // const InquiryRequestInfocolumn = Object.keys(InquiryRequestInfo).map(
    //   (key) => ({ title: key, dataKey: key })
    // );
    // const InquiryRequestInforow = Object.values(InquiryRequestInfo).map(
    //   (key) => ({ title: key, dataKey: key })
    // );

    // // Generate the table in the PDF
    // doc.setFontSize(16);
    // doc.autoTable({
    //   head: [InquiryRequestInfocolumn.map((col) => col.dataKey)],
    //   // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
    //   body: [InquiryRequestInforow.map((col) => col.dataKey)],
    // });

    // // InquiryRequestInfo end

    // Inquiry address start

    const InquiryAddresses = CreditData.InquiryRequestInfo.InquiryAddresses[0];
    // console.log("InquiryAddresses", InquiryAddresses);

    // Define the columns and rows for the table
    const InquiryAddressescolumn = Object.keys(InquiryAddresses).map((key) => ({
      title: key,
      dataKey: key,
    }));
    const InquiryAddressesrow = Object.values(InquiryAddresses).map((key) => ({
      title: key,
      dataKey: key,
    }));

    // Generate the table in the PDF
    doc.setFontSize(16);
    doc.text("Inquiry Addresses", 14, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 14,
      head: [InquiryAddressescolumn.map((col) => col.dataKey)],
      // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
      body: [InquiryAddressesrow.map((col) => col.dataKey)],
      headStyles: {
        textColor: [255, 255, 255], // White font color
        fillColor: [158, 28, 51], // #9E1C33 background color
      },
    });

    // Inquiry adress end

    // inquiry IDDetails start

    const IDDetails = CreditData.InquiryRequestInfo.IDDetails[0];
    // console.log("IDDetails", IDDetails);

    // Define the columns and rows for the table
    const IDDetailscolumn = Object.keys(IDDetails).map((key) => ({
      title: key,
      dataKey: key,
    }));
    const IDDetailsrow = Object.values(IDDetails).map((key) => ({
      title: key,
      dataKey: key,
    }));

    // Generate the table in the PDF
    doc.setFontSize(16);
    doc.text("Inquiry Idproof", 14, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 14,
      head: [IDDetailscolumn.map((col) => col.dataKey)],
      // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
      body: [IDDetailsrow.map((col) => col.dataKey)],
      headStyles: {
        textColor: [255, 255, 255], // White font color
        fillColor: [158, 28, 51], // #9E1C33 background color
      },
    });

    // inquiry IDDetails end

    // InquiryPhones start

    const InquiryPhones = CreditData.InquiryRequestInfo.InquiryPhones[0];
    // console.log("InquiryPhones", InquiryPhones);

    // Define the columns and rows for the table
    const InquiryPhonescolumn = Object.keys(InquiryPhones).map((key) => ({
      title: key,
      dataKey: key,
    }));
    const InquiryPhonesrow = Object.values(InquiryPhones).map((key) => ({
      title: key,
      dataKey: key,
    }));

    // Generate the table in the PDF
    doc.setFontSize(16);
    doc.text("Inquiry phone", 14, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 14,
      head: [InquiryPhonescolumn.map((col) => col.dataKey)],
      // body: Array.isArray(rows) && rows.map(row => columns.map(col => row[col.dataKey])),
      body: [InquiryPhonesrow.map((col) => col.dataKey)],
      headStyles: {
        textColor: [255, 255, 255], // White font color
        fillColor: [158, 28, 51], // #9E1C33 background color
      },
    });

    // InquiryPhones end

    // RetailAccountsSummary start
    const retailAccountsSummary =
      CreditData.CIRReportData.RetailAccountsSummary;

    // Check if RetailAccountsSummary exists
    if (retailAccountsSummary) {
      const summaryData = retailAccountsSummary;
      const summaryRows = [
        ["Average Open Balance", summaryData.AverageOpenBalance],
        ["No Of Accounts", summaryData.NoOfAccounts],
        ["No Of Active Accounts", summaryData.NoOfActiveAccounts],
        ["No Of Past Due Accounts", summaryData.NoOfPastDueAccounts],
        ["No Of Write Offs", summaryData.NoOfWriteOffs],
        ["Oldest Account", summaryData.OldestAccount],
        ["Single Highest Balance", summaryData.SingleHighestBalance],
        ["Recent Account", summaryData.RecentAccount],
        ["Single Highest Credit", summaryData.SingleHighestCredit],
        [
          "Single Highest Sanction Amount",
          summaryData.SingleHighestSanctionAmount,
        ],
        ["Total Balance Amount", summaryData.TotalBalanceAmount],
        ["Total Credit Limit", summaryData.TotalCreditLimit],
        ["Total High Credit", summaryData.TotalHighCredit],
        ["Total Monthly Payment Amount", summaryData.TotalMonthlyPaymentAmount],
        ["Total Past Due", summaryData.TotalPastDue],
        ["Total Sanction Amount", summaryData.TotalSanctionAmount],
      ];
      doc.text(
        "Retail account summary",
        14,
        doc.autoTable.previous.finalY + 10
      );

      // Add table for RetailAccountsSummary
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        // startY: 36,
        head: [["Name", "Value"]],
        body: summaryRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Retail Accounts Summary available.", 14, 36);
    }
    //RetailAccountsSummary end

    // Inquiry Response Header start

    const inquiryResponseHeader = CreditData.InquiryResponseHeader;
    // console.log("inquiryResponseHeader", inquiryResponseHeader);

    // Check if InquiryResponseHeader exists
    if (inquiryResponseHeader) {
      const headerData = inquiryResponseHeader;
      // console.log("headerData", headerData);
      const headerRows = [
        ["Customer Code", headerData.CustomerCode],
        ["Customer Name", headerData.CustomerName],
        ["Date", headerData.Date],
        ["Hit Code", headerData.HitCode],
        ["Product Code", headerData.ProductCode],
        ["Report Order No", headerData.ReportOrderNO],
        ["Success Code", headerData.SuccessCode],
        ["Time", headerData.Time],
      ];
      doc.text(
        "Inquiry Response Header",
        14,
        doc.autoTable.previous.finalY + 10
      );

      // Add table for InquiryResponseHeader
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [["Name", "Value"]],
        body: headerRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Inquiry Response Header available.", 14, 36);
    }

    // Inquiry Response Header end

    //RetailAccountDetails start
    const retailAccountDetails = CreditData.CIRReportData.RetailAccountDetails;
    // console.log("retailAccountDetails", retailAccountDetails);

    // Check if RetailAccountDetails exists and is an array
    if (retailAccountDetails && Array.isArray(retailAccountDetails)) {
      const accountRows = retailAccountDetails.map((detail) => [
        detail.AccountNumber,
        detail.AccountType,
        detail.AccountStatus,
        detail.Balance,
        detail.CreditLimit,
        detail.DateOpened,
        detail.DateReported,
        detail.HighCredit,

      ]);
      doc.text(
        "Retail Account Details(1)",
        14,
        doc.autoTable.previous.finalY + 10
      );

      // Add table for RetailAccountDetails
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [
          [
            "Account Number",
            "Account Type",
            "Account Status",
            "Balance",
            "Credit Limit",
            "Date Opened",
            "Date Reported",
            "High Credit",

          ],
        ],
        body: accountRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Retail Account Details available.", 14, 36);
    }

    // RetailAccountDetails end

    //
    const retailAccountDetails2 = CreditData.CIRReportData.RetailAccountDetails;
    // console.log("retailAccountDetails", retailAccountDetails);

    // Check if RetailAccountDetails exists and is an array
    if (retailAccountDetails2 && Array.isArray(retailAccountDetails2)) {
      const accountRows = retailAccountDetails2.map((detail) => [
        detail.Institution,
        detail.LastPaymentDate,
        detail.LastPayment,
        detail.InstallmentAmount,
          detail.Open,
          detail.OwnershipType,
          detail.RepaymentTenure,
          detail.SanctionAmount,
          detail.TermFrequency,
          detail.seq,
          detail.source,

      ]);
      doc.text(
        "Retail Account Details(2)",
        14,
        doc.autoTable.previous.finalY + 10
      );

      // Add table for RetailAccountDetails
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [
          [
            "Institution",
            "LastPaymentDate",
            "LastPayment",
            "Install Amount",
            "Open",
            "Type",
            "Tenure",
            "Amount",
            "Term",
            "Seq",
            "Source",
          ],
        ],
        body: accountRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No Retail Account Details available.", 14, 36);
    }
    //

    // History48Months start

    const history48Months =
      CreditData.CIRReportData.RetailAccountDetails[0].History48Months;
    // console.log("history48Months", history48Months);

    // Check if History48Months exists and is an array
    if (history48Months && Array.isArray(history48Months)) {
      const historyRows = history48Months.map((detail) => [
        detail.AssetClassificationStatus,
        detail.PaymentStatus,
        detail.SuitFiledStatus,
        detail.key,
      ]);
      doc.text(
        "Retail Account 48Months History",
        14,
        doc.autoTable.previous.finalY + 10
      );
      // Add table for History48Months
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 14,
        head: [
          [
            "AssetClassificationStatus",
            "PaymentStatus",
            "SuitFiledStatus",
            "key",
          ],
        ],
        body: historyRows,
        headStyles: {
          textColor: [255, 255, 255], // White font color
          fillColor: [158, 28, 51], // #9E1C33 background color
        },
      });
    } else {
      doc.text("No History 48 Months available.", 14, 36);
    }

    //  History48Months end

    // Save the PDF
    doc.save(`${PersonalInfoDetailsName} Credit Report.pdf`);
  };

  const getInitialValue = (value) => {
    console.log("value", value);
    if (value === "Tamil Nadu") {
      return "TN";
    } else if (value === "Andaman & Nicobar Islands") {
      return "AN";
    } else if (value === "Andhra Pradesh") {
      return "AP";
    } else if (value === "Arunachal Pradesh") {
      return "AR";
    } else if (value === "Assam") {
      return "AS";
    } else if (value === "Bihar") {
      return "BR";
    } else if (value === "Chhattisgarh") {
      return "CG";
    } else if (value === "Chandigarh") {
      return "CH";
    } else if (value === "Daman & Diu" || value === "Daman and Diu") {
      return "DD";
    } else if (value === "Delhi") {
      return "DL";
    } else if (
      value === "Dadra & Nagar Haveli" ||
      value === "Dadra and Nagar Haveli"
    ) {
      return "DN";
    } else if (value === "Goa") {
      return "GA";
    } else if (value === "Gujarat") {
      return "GJ";
    } else if (value === "Himachal Pradesh") {
      return "HP";
    } else if (value === "Haryana") {
      return "HR";
    } else if (value === "Jharkhand") {
      return "JH";
    } else if (value === "Jammu & Kashmir" || value === "Jammu and Kashmir") {
      return "JK";
    } else if (value === "Karnataka") {
      return "KA";
    } else if (value === "Kerala") {
      return "KL";
    } else if (value === "Lakshadweep") {
      return "LD";
    } else if (value === "Maharashtra") {
      return "MH";
    } else if (value === "Meghalaya") {
      return "ML";
    } else if (value === "Manipur") {
      return "MN";
    } else if (value === "Madhya Pradesh") {
      return "MP";
    } else if (value === "Mizoram") {
      return "MZ";
    } else if (value === "Nagaland") {
      return "NL";
    } else if (value === "Orissa" || value === "Odisha") {
      return "OR";
    } else if (value === "Punjab") {
      return "PB";
    } else if (
      value === "Pondicherry" ||
      value === "Puducherry" ||
      value === "Puducheri"
    ) {
      return "PY";
    } else if (value === "Rajasthan") {
      return "RJ";
    } else if (value === "Sikkim") {
      return "SK";
    } else if (value === "Telangana") {
      return "TG";
    } else if (value === "Tripura") {
      return "TR";
    } else if (value === "Uttaranchal" || value === "Uttarakhand") {
      return "UL";
    } else if (value === "Uttar Pradesh") {
      return "UP";
    } else if (value === "West Bengal") {
      return "WB";
    } else {
      return "DL";
    }
  };

  return (
    <>
      {/* alert message */}
      <Snackbar
        open={CreditSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Successfully retrieve credit details.
        </Alert>
      </Snackbar>
      <Snackbar
        open={CreditErr}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Unable to retrieve Credit details. Please try again.
        </Alert>
      </Snackbar>
      <Snackbar
        open={ErrRes}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {CreditErrDes !== "" ? CreditErrDes : "Something went wrong!!!"}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          name: props.name,
          dob: props.dob,
          gender: props.gender,
          address: props.address,
          id_value: Data.pan,
          city: props.city,
          state: getInitialValue(props.state),
          postal_code: props.pincode,
          phone_number: Data.phone,
          // test credentials
          // name: "SATHISH KUMAR",
          // dob: "1947-08-15",
          // gender:
          //   Data.gender === "Female"
          //     ? "F"
          //     : Data.gender === "Male"
          //     ? "M"
          //     : Data.gender === "Others"
          //     ? "O"
          //     : "",
          // address:
          //   "NO-223 NACHIANNAN ANGANNAN STREET  RAMANA THAPURAM RAMANATHAPURAM COIMBATORE",
          // id_value: "BUXPS2681D",
          // city: "Coimbatore",
          // state: "TN",
          // postal_code: "641045",
          // phone_number: "8122244473",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("This field is required"),
          dob: Yup.string().required("This field is required"),
          gender: Yup.string().required("This field is required"),
          address: Yup.string().required("This field is required"),
          id_value: Yup.string().required("This field is required"),
          city: Yup.string().required("This field is required"),
          state: Yup.string().required("This field is required"),
          postal_code: Yup.string().required("This field is required"),
          phone_number: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (values) {
              setStatus({ success: true });
              setSubmitting(false);
              handleApi(values);
              console.log("values", values);
            }
          } catch (err) {
            console.error(err);
            if (values) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Stack direction="row">
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-name-register">
                        First Name&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="First Name&#42;"
                        id="outlined-adornment-name-register"
                        type="text"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={(event) => handleChange(event)}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-name-register"
                        >
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.dob && errors.dob)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-dob-register">
                        Date of birth&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Date of birth&#42;"
                        id="outlined-adornment-dob-register"
                        type="text"
                        value={values.dob}
                        name="dob"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.dob && errors.dob && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-dob-register"
                        >
                          {errors.dob}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.gender && errors.gender)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-gender-register">
                        Gender&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Gender&#42;"
                        id="outlined-adornment-gender-register"
                        type="text"
                        value={values.gender}
                        name="gender"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.gender && errors.gender && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-gender-register"
                        >
                          {errors.gender}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.phone_number && errors.phone_number
                      )}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-phone_number-register">
                        Phone Number&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Phone Number&#42;"
                        id="outlined-adornment-phone_number-register"
                        type="text"
                        value={values.phone_number}
                        name="phone_number"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.phone_number && errors.phone_number && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-phone_number-register"
                        >
                          {errors.phone_number}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.address && errors.address)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-address-register">
                        Address&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Address&#42;"
                        id="outlined-adornment-address-register"
                        type="text"
                        value={values.address}
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.address && errors.address && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-address-register"
                        >
                          {errors.address}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={6} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.id_value && errors.id_value)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-id_value-register">
                        Pan Number&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Pan Number&#42;"
                        id="outlined-adornment-id_value-register"
                        type="text"
                        value={values.id_value}
                        name="id_value"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.id_value && errors.id_value && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-id_value-register"
                        >
                          {errors.id_value}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.city && errors.city)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-city-register">
                        City&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="city&#42;"
                        id="outlined-adornment-city-register"
                        type="text"
                        value={values.city}
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.city && errors.city && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-city-register"
                        >
                          {errors.city}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.state && errors.state)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-state-register">
                        State Code&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="State Code&#42;"
                        id="outlined-adornment-state-register"
                        type="text"
                        value={values.state}
                        name="state"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.state && errors.state && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-state-register"
                        >
                          {errors.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} xs={12} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.postal_code && errors.postal_code)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-postal_code-register">
                        Postal Code&#42;
                      </InputLabel>
                      <OutlinedInput
                        label="Postal Code&#42;"
                        id="outlined-adornment-postal_code-register"
                        type="text"
                        value={values.postal_code}
                        name="postal_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // inputProps={{
                        //   readOnly: true,
                        // }}
                      />
                      {touched.postal_code && errors.postal_code && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-postal_code-register"
                        >
                          {errors.postal_code}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Grid
              container
              spacing={4}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Grid item sm={6} xs={6}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                    disabled={!CreditData}
                  >
                    Download PDF
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={6} xs={6}>
                <Box sx={{ mt: 2 }}>
                  <LoadingButton
                    disableElevation
                    loading={IsLoading}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#A556B2",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#A556B2" },
                    }}
                    // onClick={() => Navigate("/existingdetails")}
                  >
                    submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreditForm;
