import React, { useState } from "react";
import {
  Paper,
  Chip,
  Button,
  DialogActions,
  TextField,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  Alert,
  Snackbar,
  Typography,
  FormControl,
  Stack,
  CircularProgress,
  InputAdornment,
  Box
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { RegenrateExpiredDocumentsApi } from "../../Axios/Approval/api";
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';

// import VisibilityIcon from '@mui/icons-material/Visibility';
// import DownloadIcon from '@mui/icons-material/Download';

export default function ExpiredDocument(props) {
  const DataTable = props.Data;

  console.log("DataTable", DataTable);

  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [page, setPage] = useState(0);
  const [ID, setID] = useState("");
  const [Phone, setPhone] = useState("");
  const [Data, setData] = useState("");
  const [SuccOpen, setSuccOpen] = useState(false);
  const [ErrOpen, setErrOpen] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [Res, setRes] = useState(false);
  const [DateType,setDateType] = useState(false)
  const [FIlterData,setFIlterData] = useState(DataTable)
console.log("FIlterData",FIlterData);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = (value) => {
    const filteredData = DataTable.filter((rows) =>
      rows.application_submission_response.data.invitees.some((row) =>
        row.name.toLowerCase().includes(value.toLowerCase())
      )
    );
    setFIlterData(filteredData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (phone, id) => {
    setOpen(true);
    setPhone(phone);
    setID(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setSuccOpen(true);
    setOpen(false)
  };
  const handleError = () => {
    setErrOpen(true);
  };
  const handleErrClose = () => {
    setSuccOpen(false);
    setErrOpen(false);
    setDateType(false)
  };
  const handleChange = (name, event) => {
    setData({ ...Data, [name]: event });
  };
  const handleSubmit = () => {
    if (!Data.date) {
      setDateType(true)
    } else {
      RegenrateExpiredDocumentsApi(
        setIsLoading,
        Phone,
        ID,
        Data,
        handleClick,
        setRes,
        handleError
      );
    }
  };

  return (
    <>
      <Snackbar
        open={SuccOpen}
        autoHideDuration={5000}
        onClose={handleErrClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleErrClose}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {Res !== "" ? Res : "somthing went wrong"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={ErrOpen}
        autoHideDuration={5000}
        onClose={handleErrClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleErrClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {Res !== "" ? Res : "somthing went wrong"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={DateType}
        autoHideDuration={5000}
        onClose={handleErrClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleErrClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          Select the date
        </Alert>
      </Snackbar>
      {/* no of renew */}
      <React.Fragment>
        <Dialog
          open={open}
          // onClose={handleClose}
        >
          <DialogTitle>Renew your Date</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              To send leegality link, please enter your renewal date here. We
              will send updates occasionally.
            </DialogContentText>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              {/* <InputLabel sx={{ mb: '10px' }}>Date of Agreement</InputLabel> */}
              <TextField
                id="outlined-basic"
                fullWidth
                value={Data.date}
                type="date"
                variant="outlined"
                name="date_of_agreement"
                onChange={(e) => handleChange("date", e.target.value)}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={IsLoading}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Paper>
        <Box sx={{display:"flex",mt: {xl:'0px',lg:'0px',md:'0px',}}}>
        <Typography variant="h5" m={2}>
            Expired Documents
        </Typography>
      <TextField
            placeholder="Search..."
            sx={{ mb: 1 }}
            onChange={(e) => handleFilterChange(e.target.value)}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
            }}
          />
                  </Box>

        <TableContainer
          sx={{ maxHeight: 500, width: { xl: 1200, lg: 1000, md: 800 } }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell>S.No</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {FIlterData &&
                FIlterData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((rows, index) => {
                  return rows.application_submission_response.data.invitees.map(
                    (row, subindex) => {
                      return subindex === 0 ? (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={subindex}
                        >
                          {/* <TableCell sx={{ width: 150 }}>{index}</TableCell> */}
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell
                            sx={{ cursor: "pointer", textAlign: "left" }}
                          >
                            <Chip
                              label="Renew Agreement"
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                handleClickOpen(row.phone, rows.application_id)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ) :
                    //   <Stack sx={{ color: 'grey.500', textAlign: 'center' }} spacing={2} direction="row">
                    //   <CircularProgress color='primary' />
                    // </Stack>;
                    null
                    }
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ width: "100%" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={DataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
    // </Box>
  );
}
