import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  Popover,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  FormHelperText,
  MenuItem,
  Snackbar,
  Alert,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Checkbox,
  FormGroup,
  FormLabel,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BankAccNumberVerify } from "../../Axios/ServiceApi";
import { Verified } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ExistingDetails = ({ ...others }) => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const indexdata = location.state ? location.state.Indexvalues : "";
  const BankNameData = indexdata.bankname;
  console.log("BankNameData", BankNameData);
  const Imageid = location.state ? location.state.id : "";
  const Image = location.state ? location.state.image : "";
  const AadharImage = location.state ? location.state.aadhar : "";
  const PanImage = location.state ? location.state.pan : "";
  const poi = location.state ? location.state.poi : "";
  const detailvalues = location.state
    ? location.state.detailvalues
      ? location.state.detailvalues
      : null
    : null;
  const borrowerdetails = location.state
    ? location.state.borrowerdetails
      ? location.state.borrowerdetails
      : null
    : null;
  const postcheque = location.state
    ? location.state.datecheque1
      ? location.state.datecheque1
      : null
    : null;
  const postcheque1 = location.state
    ? location.state.datecheque2
      ? location.state.datecheque2
      : null
    : null;
  const postitr = location.state
    ? location.state.dataitr
      ? location.state.dataitr
      : null
    : null;

  //console.log("cheque",postcheque,postcheque1 );

  //console.log("indexdata", detailvalues);

  const open = Boolean(anchorEl);
  const id = open ? "my-popover" : undefined;

  const [cheque1, setCheque1] = useState(postcheque);
  const [cheque2, setCheque2] = useState(postcheque1);
  const [ItrUpload, setItrUpload] = useState(postitr);
  const [AccNoSuccess, setAccNoSuccess] = useState(false);
  const [AccNoErr, setAccNoErr] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  // const [EmiAmount,setEmiAmount]=useState(0)

  const handleImageUpload = (e) => {
    setCheque1(e.target.files[0]);
  };

  const handleCheque1Close = () =>{
    setCheque1(false)
  }

  const handleImageUpload2 = (e) => {
    setCheque2(e.target.files[0]);
  };

  const handlecheque2Close = () => {
    setCheque2(false)
  }

  const handlePdfUpload = (e) => {
    setItrUpload(e.target.files[0]);
    console.log("ItrUpload", ItrUpload);
  };
  const handleItrUploadClose = () => {
    setItrUpload(false)
  }

  const loandetails = [
    {
      id: 1,
      Loanscheme: "Micro Loan (P1)",
      Loanpurpose: "SBI Tab",
      Loanamount: "45,030",
      EMI: "3,048",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 2,
      Loanscheme: "Micro Loan (P2)",
      Loanpurpose: "EPSON PLQ 50CS Printer",
      Loanamount: "39,490",
      EMI: "2,673",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 3,
      Loanscheme: "Micro Loan (P3)",
      Loanpurpose: "Part Payment",
      Loanamount: "20,000",
      EMI: "1,911",
      Tenure: "12",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 4,
      Loanscheme: "Micro Loan (P4)",
      Loanpurpose: "SBI mATM",
      Loanamount: "7,250",
      EMI: "2,522",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 5,
      Loanscheme: "Micro Loan (P5)",
      Loanpurpose: "Mantra (L1)  FID",
      Loanamount: "5,000",
      EMI: "1,739",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 6,
      Loanscheme: "Micro Loan (P6)",
      Loanpurpose: "Mantra (L1)  FID",
      Loanamount: "4,500",
      EMI: "1,565",
      Tenure: "3",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "SBI",
    },
    {
      id: 7,
      Loanscheme: "Micro Loan (P9)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "30,000",
      EMI: "2,031",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "BOB",
    },
    {
      id: 8,
      Loanscheme: "Micro Loan (P10)",
      Loanpurpose: "Tablet-Leo-273-A37-leopard",
      Loanamount: "39,250",
      EMI: "2,657",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "BOB",
    },
    {
      id: 9,
      Loanscheme: "Micro Loan (P11)",
      Loanpurpose: "Tablet-Leo-273-A37-leopard",
      Loanamount: "39,250",
      EMI: "2,657",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "UCO",
    },
    {
      id: 10,
      Loanscheme: "Micro Loan (P12)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "30,000",
      EMI: "2,031",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "PNB",
    },
    {
      id: 11,
      Loanscheme: "Micro Loan (P13)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "30,000",
      EMI: "2,031",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "AGVB",
    },
    {
      id: 12,
      Loanscheme: "Micro Loan (P14)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "30,000",
      EMI: "2,031",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "UBI",
    },
    {
      id: 13,
      Loanscheme: "Micro Loan (P15)",
      Loanpurpose: "Falcon-Evolute",
      Loanamount: "14,000",
      EMI: "1,337",
      Tenure: "12",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "UBI",
    },
    {
      id: 14,
      Loanscheme: "Micro Loan (P16)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "39,490",
      EMI: "2,673",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "BOI",
    },
    {
      id: 15,
      Loanscheme: "Micro Loan (P17)",
      Loanpurpose: "Printer-EPSON PLQ35",
      Loanamount: "30,000",
      EMI: "2,031",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "CBI",
    },
    {
      id: 16,
      Loanscheme: "Micro Loan (P18)",
      Loanpurpose: "Tablet-Visiontek VA21",
      Loanamount: "31,500",
      EMI: "2,132",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "IB",
    },
    {
      id: 17,
      Loanscheme: "Micro Loan (P19)",
      Loanpurpose: "Tablet-Visiontek VA21",
      Loanamount: "31,500",
      EMI: "2,132",
      Tenure: "18",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: "IOB",
    },
    {
      id: 17,
      Loanscheme: "Micro Loan (P22)",
      Loanpurpose: "Hardware",
      Loanamount: "1000",
      EMI: "Will be filled by loan officer",
      Tenure: "12",
      Rateofinterest: "26%",
      OverdueInterest: "4%",
      Bank: [
        "IOB",
        "IB",
        "BOI",
        "CBI",
        "SBI",
        "AGVB",
        "BOB",
        "PNB",
        "UBI",
        "UCO",
      ],
    },
  ];

  const [Error, setError] = useState({ status: false, message: null });

  const handleAmountChange = (value, setFieldValue) => {
    console.log(selectedLoanDetails);
    if (selectedLoanDetails && selectedLoanDetails.Loanamount) {
      console.log("condition 1");
      setSelectedLoanDetails({ ...selectedLoanDetails, Loanamount: value });
      setFieldValue("loanamount", value);

      if (value % 1000 === 0) {
        setError({ ...Error, status: false, message: null });
      } else {
        setError({
          ...Error,
          status: true,
          message: "Loan amount must be in thousands (e.g., 1000, 2000)",
        });
      }
    } else {
      console.log("condition 2");
      setSelectedLoanDetails({ ...selectedLoanDetails, Loanamount: value });
      setFieldValue("loanamount", value);

      if (value % 1000 === 0) {
        setError({ ...Error, status: false, message: null });
      } else {
        setError({
          ...Error,
          status: true,
          message: "Loan amount must be in thousands (e.g., 1000, 2000)",
        });
      }
    }
  };

  // console.log("error",Error);

  const filteredProducts = BankNameData
    ? loandetails.filter((product) => {
        if (Array.isArray(product.Bank)) {
          return product.Bank.includes(BankNameData);
        } else {
          return product.Bank === BankNameData;
        }
      })
    : [];
  // console.log("filteredProducts", filteredProducts);

  const [selectedLoanDetails, setSelectedLoanDetails] = useState(null);
  const handleLoanPurposeChange = (event, setFieldValue) => {
    const selectedPurpose = event.target.value;
    const details = loandetails.find(
      (detail) => detail.Loanpurpose === selectedPurpose
    );
    setSelectedLoanDetails(details);
    setFieldValue("loanpurpose", selectedPurpose);
  };
  //   useEffect(() => {
  //   if (selectedLoanDetails) {
  //     const emiamount = selectedLoanDetails.Loanamount * 0.347880976782301;
  //     setEmiAmount(Math.round(emiamount));
  //     console.log("emiamount", emiamount);
  //   } else {
  //     console.log("error");
  //   }
  // }, [selectedLoanDetails]);

  const [postdetails, setPostdetails] = useState(null);
  // //console.log("postdetails", postdetails);

  const detailsexist = (values) => {
    //  //console.log("values",values.existingloandetails);
    setPostdetails(values);
    if (values.existingloandetails === "No") {
      // e.preventDefault()
      // //console.log("enter if");
      //console.log();
      setPostdetails({
        ...values,
        existinglendname: "None",
        existingloanpurpose: "None",
        existingloanamount: "None",
        existingtenure: "None",
        existingmonthlyinstalment: "None",
        existingpresentoutstand: "None",
        existingbalancetenure: "None",
        loanamount: selectedLoanDetails
          ? selectedLoanDetails.Loanamount
          : values.loanamount
          ? values.loanamount
          : "",
        tenure: selectedLoanDetails
          ? selectedLoanDetails.Tenure
          : values.tenure
          ? values.tenure
          : "",
        loanscheme: selectedLoanDetails
          ? selectedLoanDetails.Loanscheme
          : values.loanscheme
          ? values.loanscheme
          : "",
        roi: selectedLoanDetails
          ? selectedLoanDetails.Rateofinterest
          : values.roi
          ? values.roi
          : "",
        emi: selectedLoanDetails
          ? selectedLoanDetails.EMI
          : values.emi
          ? values.emi
          : "",
      });
    } else {
      // //console.log("enter else");
      setPostdetails({
        ...values,
        existinglendname: values.existinglendname,
        existingloanpurpose: values.existingloanpurpose,
        existingloanamount: values.existingloanamount,
        existingtenure: values.existingtenure,
        existingmonthlyinstalment: values.existingmonthlyinstalment,
        existingpresentoutstand: values.existingpresentoutstand,
        existingbalancetenure: values.existingbalancetenure,
        loanamount: selectedLoanDetails
          ? selectedLoanDetails.Loanamount
          : values.loanamount
          ? values.loanamount
          : "",
        tenure: selectedLoanDetails
          ? selectedLoanDetails.Tenure
          : values.tenure
          ? values.tenure
          : "",
        loanscheme: selectedLoanDetails
          ? selectedLoanDetails.Loanscheme
          : values.loanscheme
          ? values.loanscheme
          : "",
        roi: selectedLoanDetails
          ? selectedLoanDetails.Rateofinterest
          : values.roi
          ? values.roi
          : "",
        emi: selectedLoanDetails
          ? selectedLoanDetails.EMI
          : values.emi
          ? values.emi
          : "",
      });
      // //console.log("elsepostdetails", postdetails);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
  };

  const Token = sessionStorage.getItem("user_token");
  const AccVerified = sessionStorage.getItem("verifyaccButton");
  const VerifedAccNo = sessionStorage.getItem("account_number");
  const VerifedIfsc = sessionStorage.getItem("ifsc_code");

  useEffect(() => {
    if (postdetails) {
      // Navigate("/borrowerdetails", {
      //       state: {indexdata, detailvalues: postdetails},
      //     });
      Navigate("/borrowerdetails", {
        state: {
          indexdata,
          Imageid,
          Image,
          AadharImage,
          PanImage,
          poi,
          detailvalues: postdetails,
          borrowerdetails: borrowerdetails,
          cheque1: cheque1,
          cheque2: cheque2,
          ItrUpload: ItrUpload,
        },
      });
    }
    // else{
    //   Navigate("/borrowerdetails", {
    //     state: {indexdata, detailvalues: postdetails},
    //   });
    // }
  }, [postdetails]);

  const existingdetail = [
    { check: "Yes", valuedetails: "" },
    { check: "No", valuedetails: "None" },
  ];

  const handleVerify = (ifsc_code, account_number) => {
    console.log("account_number", account_number, "account_number", ifsc_code);
    BankAccNumberVerify(
      ifsc_code,
      account_number,
      handleSuccess,
      handleError,
      setIsLoading
    );
  };

  const handleSuccess = () => {
    setAccNoSuccess(true);
    setIsLoading(false);
  };
  const handleError = () => {
    setAccNoErr(true);
    setIsLoading(false);
  };
  const handleBankClose = () => {
    setAccNoErr(false);
    setAccNoSuccess(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (Token) {
    return (
      <>
        <Snackbar
          open={AccNoSuccess}
          autoHideDuration={6000}
          onClose={handleBankClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleBankClose} variant="filled" severity="success">
            Your bank account number is verified
          </Alert>
        </Snackbar>
        <Snackbar
          open={AccNoErr}
          autoHideDuration={6000}
          onClose={handleBankClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleBankClose} variant="filled" severity="error">
            Something went wrong
          </Alert>
        </Snackbar>
        <Formik
          initialValues={{
            // companyaddress: "N/A",
            loanpurpose: detailvalues
              ? detailvalues.loanpurpose
                ? detailvalues.loanpurpose
                : ""
              : "",
            loanamount: detailvalues
              ? detailvalues.loanamount
                ? detailvalues.loanamount
                : ""
              : "",
            tenure: detailvalues
              ? detailvalues.tenure
                ? detailvalues.tenure
                : ""
              : "",
            // loantype: "",
            loanscheme: detailvalues
              ? detailvalues.loanscheme
                ? detailvalues.loanscheme
                : ""
              : "",
            roi: detailvalues ? (detailvalues.roi ? detailvalues.roi : "") : "",
            emi: detailvalues ? (detailvalues.emi ? detailvalues.emi : "") : "",
            // sanctiondate: "N/A",
            // loanid: "N/A",
            // annualpercentage: "N/A",
            // availableperiod: "N/A",
            // commencementdate: "N/A",
            // overdueintrest: "",
            feescharge: "as per Schedule charges",
            // postdatecheque: "",
            // modeofrepayment: "N/A",
            // repaymentfrequency: "N/A",
            // instalmentsrepayments: "N/A",
            accname: detailvalues
              ? detailvalues.accname
                ? detailvalues.accname
                : ""
              : "",
            bankbranch: detailvalues
              ? detailvalues.bankbranch
                ? detailvalues.bankbranch
                : ""
              : "",
            ifsc: detailvalues
              ? detailvalues.ifsc
                ? detailvalues.ifsc
                : VerifedIfsc
              : VerifedIfsc,
            accountno: detailvalues
              ? detailvalues.accountno
                ? detailvalues.accountno
                : VerifedAccNo
              : VerifedAccNo,
            reaccountno: detailvalues
              ? detailvalues.reaccountno
                ? detailvalues.reaccountno
                : ""
              : "",
            accounttype: detailvalues
              ? detailvalues.accounttype
                ? detailvalues.accounttype
                : "Savings Bank Account(SB)"
              : "Savings Bank Account(SB)",
            existingloandetails: "none",
            existinglendname: "none",
            existingloanpurpose: "none",
            existingloanamount: "none",
            existingtenure: "none",
            existingpresentoutstand: "none",
            existingbalancetenure: "none",
            existingmonthlyinstalment: "none",
          }}
          validationSchema={Yup.object().shape({
            // companyaddress: Yup.string()
            //   .max(50)
            //   .required("Name of Lending Institution with Branch is required"),
            loanpurpose: Yup.string()
              .max(50)
              .required("Purpose of Loan is required"),
            loanamount: Yup.string(),
            // .when('loanpurpose', {
            //     is: "Demo",
            //     then: Yup.string().required("Loan amount is required").matches(/^\d+$/, 'Loan amount must be a number'),
            //     otherwise:Yup.string()
            //   }),

            // .min(1000, 'Price must be at least 1000')

            tenure: Yup.string().max(20),
            // loantype: Yup.string().max(20).required("Loan type is required"),
            loanscheme: Yup.string().max(50),
            roi: Yup.string().max(20),
            emi: Yup.string().max(100),
            // sanctiondate: Yup.string()
            //   .max(50)
            //   .required("The Company’s Sanction Letter Date is required"),
            // loanid: Yup.string()
            //   .max(50)
            //   .required("The Company’s Loan ID is required"),
            // annualpercentage: Yup.string()
            //   .max(50)
            //   .required("Annual Percentage Rate is required"),
            // availableperiod: Yup.string()
            //   .max(50)
            //   .required("Availability Period is required"),
            // commencementdate: Yup.string()
            //   .max(50)
            //   .required("Date of Commencement of EMI is required"),
            // overdueintrest: Yup.string()
            //   .max(50),
            // feescharge: Yup.string()
            //   .max(50)
            //   .required("Fees / Charges is required"),
            // postdatecheque: Yup.string().required(
            //   "Post Dated cheques is required"
            // ),
            // modeofrepayment: Yup.string().required(
            //   "Mode of Repayment is required"
            // ),
            // repaymentfrequency: Yup.string().required(
            //   "Frequency of Repayment is required"
            // ),
            // instalmentsrepayments: Yup.string().required(
            //   "No of Instalments for Repayment is required"
            // ),
            accname: Yup.string()
              .max(50)
              .required("Name of Account Holder is required"),
            bankbranch: Yup.string()
              .max(50)
              .required("Name of Bank and branch is required"),
            ifsc: Yup.string()
              // .matches(/^[A-Z]{4}\d{7}$/, "Please enter a valid IFSC Code")
              .max(11)
              .required("IFSC Code is required"),
            accountno: Yup.string()
              .matches(/^[0-9]{8,20}$/, "Invalid Account Number format")
              .required("Account Number is required"),
            reaccountno: Yup.string()
              .matches(/^[0-9]{8,20}$/, "Invalid Account Number format")
              .oneOf(
                [Yup.ref("accountno"), null],
                "Account numbers don't match"
              )
              .required("Reenter Account Number is required"),
            accounttype: Yup.string()
              .max(50)
              .required("Type of Account is required"),
            existingloandetails: Yup.string()
              .max(50)
              .required("This field is required"),
            existinglendname: Yup.string().max(50),
            existingloanpurpose: Yup.string().max(50),
            existingloanamount: Yup.string().max(50),
            existingtenure: Yup.string().max(20),
            existingmonthlyinstalment: Yup.string().max(50),
            existingpresentoutstand: Yup.string().max(50),
            existingbalancetenure: Yup.string().max(50),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values) {
                setStatus({ success: true });
                setSubmitting(false);
                detailsexist(values);
              }
            } catch (err) {
              console.error(err);
              if (values) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                // detailsexist(values)
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setErrors,
            isSubmitting,
            touched,
            values,
            isValid,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Stack direction="row">
                <Grid item xs={12} sm={12}>
                  {/* Existing loan details start */}
                  <Grid
                    container
                    direction={matchDownSM ? "column-reverse" : "row"}
                    alignItems="center"
                    justifyContent="center"
                    mb={0}
                  >
                    {/* <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Typography
                        sx={{ color: "#121926" }}
                        gutterBottom
                        variant="h6"
                      >
                        Enter your Existing Loans Details
                      </Typography>
                    </Stack> */}
                  </Grid>
                  {/* <Typography variant="subtitle1" mb={1}>If you don't have existing loan details, please select No.&#42;</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                  <FormControl
                        fullWidth
                        error={Boolean(touched.existingloandetails && errors.existingloandetails)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                        Existing Loan Details&#42;
                        </InputLabel>
                        <Select
                          id="select"
                          value={values.existingloandetails}
                          name="existingloandetails"
                          onChange={(event) => {
                            handleChange(event)}}
                          label="Existing Loan Details&#42;"
                          input={<OutlinedInput label="Existing Loan Details&#42;" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          {existingdetail.map ((data) => (
                            <MenuItem value={data.check} key={data.check}>{data.check}</MenuItem>
                          ))}
                        </Select>
                        {touched.existingloandetails && errors.existingloandetails && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingloandetails-register"
                          >
                            {errors.existingloandetails}
                          </FormHelperText>
                        )}
                      </FormControl>
                      </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.existinglendname && errors.existinglendname)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-existinglendname-register">
                          Name of Lending Institution with Branch &#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Name of Lending Institution with Branch &#42;"
                          id="outlined-adornment-existinglendname-register"
                          type="text"
                          value={values.existingloandetails==="No"?'None':values.existinglendname}
                          name="existinglendname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existinglendname && errors.existinglendname && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existinglendname-register"
                          >
                            {errors.existinglendname}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.existingloanpurpose && errors.existingloanpurpose)}
                        sx={{ ...theme.typography.customInput }}
                        >
                        <InputLabel htmlFor="outlined-adornment-existingloanpurpose-register">
                        Purpose of Loan&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Purpose of Loan&#42;"
                          // disabled
                          id="outlined-adornment-existingloanpurpose-register"
                          type="text"
                          value={values.existingloandetails==="No"?'None':values.existingloanpurpose}
                          name="existingloanpurpose"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingloanpurpose && errors.existingloanpurpose && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingloanpurpose-register"
                          >
                            {errors.existingloanpurpose}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.existingloanpurpose && errors.existingloanamount)}
                        sx={{ ...theme.typography.customInput }}
                        >
                        <InputLabel htmlFor="outlined-adornment-existingloanamount-register">
                        Loan Sanction Amount&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Loan Sanction Amount&#42;"
                          // disabled
                          id="outlined-adornment-existingloanamount-register"
                          type="text"
                          value={values.existingloandetails==="No"?'None':values.existingloanamount}
                          name="existingloanamount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingloanamount && errors.existingloanamount && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingloanamount-register"
                          >
                            {errors.existingloanamount}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.existingtenure && errors.existingtenure)}
                        sx={{ ...theme.typography.customInput }}
                        >
                        <InputLabel htmlFor="outlined-adornment-existingtenure-register">
                          Tenure&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Tenure&#42;"
                          // disabled
                          id="outlined-adornment-existingtenure-register"
                          type="text"
                          value={values.existingloandetails==="No"?'None':values.existingtenure}
                          name="existingtenure"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingtenure && errors.existingtenure && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingtenure-register"
                          >
                            {errors.existingtenure}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.existingmonthlyinstalment && errors.existingmonthlyinstalment
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-existingmonthlyinstalment-register">
                        EMI&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="EMI&#42;"
                          id="outlined-adornment-existingmonthlyinstalment-register"
                          type="text"
                          // disabled
                          value={values.existingloandetails==='No'?'None': values.existingmonthlyinstalment}
                          name="existingmonthlyinstalment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingmonthlyinstalment &&
                          errors.existingmonthlyinstalment && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-existingmonthlyinstalment-register"
                            >
                              {errors.existingmonthlyinstalment}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.existingpresentoutstand && errors.existingpresentoutstand
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-existingpresentoutstand-register">
                          Present outstanding&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Present outstanding&#42;"
                          id="outlined-adornment-existingpresentoutstand-register"
                          type="text"
                          // disabled
                          value={values.existingloandetails==='No'?'None': values.existingpresentoutstand}
                          name="existingpresentoutstand"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingpresentoutstand && errors.existingpresentoutstand && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingpresentoutstand-register"
                          >
                            {errors.existingpresentoutstand}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.existingbalancetenure && errors.existingbalancetenure
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-existingbalancetenure-register">
                          No of EMI's Left&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="No of EMI's Left&#42;"
                          id="outlined-adornment-existingbalancetenure-register"
                          type="text"
                          // disabled
                          value={values.existingloandetails==='No'?'None': values.existingbalancetenure}
                          name="existingbalancetenure"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.existingbalancetenure && errors.existingbalancetenure && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-existingbalancetenure-register"
                          >
                            {errors.existingbalancetenure}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid> */}
                  {/* <Divider sx={{ mb: "20px" }} /> */}
                  {/* Existing loan details end */}

                  {/* bank details start */}
                  <Grid
                    container
                    direction={matchDownSM ? "column-reverse" : "row"}
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Typography
                        sx={{ color: "#121926" }}
                        gutterBottom
                        variant="h6"
                      >
                        Enter your Banking Details
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.accname && errors.accname)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-accname-register">
                          Name of Account Holder{" "}
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Name of Account Holder"
                          id="outlined-adornment-accname-register"
                          type="text"
                          value={values.accname}
                          name="accname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.accname && errors.accname && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-accname-register"
                          >
                            {errors.accname}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.bankbranch && errors.bankbranch)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-bankbranch-register">
                          Name of Bank and branch
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Name of Bank and branch"
                          id="outlined-adornment-bankbranch-register"
                          type="text"
                          value={values.bankbranch}
                          name="bankbranch"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.bankbranch && errors.bankbranch && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-bankbranch-register"
                          >
                            {errors.bankbranch}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.ifsc && errors.ifsc)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-ifsc-register">
                          IFSC Code
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="IFSC Code"
                          id="outlined-adornment-ifsc-register"
                          type="text"
                          value={values.ifsc ? values.ifsc : VerifedIfsc}
                          name="ifsc"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: AccVerified,
                          }}
                        />
                        {touched.ifsc && errors.ifsc && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-ifsc-register"
                          >
                            {errors.ifsc}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.accountno && errors.accountno)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-accountno-register">
                          Account Number
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Account Number"
                          id="outlined-adornment-accountno-register"
                          type="text"
                          value={
                            values.accountno ? values.accountno : VerifedAccNo
                          }
                          // disabled={AccVerified}
                          name="accountno"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <LoadingButton
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "155px",
                                // width: { md: 130, sm: 130, xs: 130, xl:150 },
                                height: "35px",
                                backgroundColor: AccVerified
                                  ? "#2e7d32"
                                  : "#A556B2",
                                "&:hover": {
                                  backgroundColor: AccVerified
                                    ? "#2e7d32"
                                    : "#A556B2",
                                },
                              }}
                              // disabled
                              loading={IsLoading}
                              loadingPosition="start"
                              onClick={() => {
                                if (!AccVerified) {
                                  handleVerify(values.ifsc, values.accountno);
                                }
                              }}
                              variant="contained"
                            >
                              {AccVerified ? "Verified" : "Verify"}
                            </LoadingButton>
                          }
                          inputProps={{
                            readOnly: AccVerified,
                          }}
                        />
                        {touched.accountno && errors.accountno && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-accountno-register"
                          >
                            {errors.accountno}
                          </FormHelperText>
                        )}
                        <Typography variant="body2" color="primary">
                          *Note: Do not enter your Kiosk Banking Account number
                          linked with Product Code 5011-1561/5011-2561
                        </Typography>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.reaccountno && errors.reaccountno
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-reaccountno-register">
                          Re-enter Account Number
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Re-enter Account Number"
                          id="outlined-adornment-reaccountno-register"
                          type="text"
                          value={values.reaccountno}
                          name="reaccountno"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ onPaste: handlePaste }}
                        />
                        {touched.reaccountno && errors.reaccountno && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-reaccountno-register"
                          >
                            {errors.reaccountno}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12} sm={12} mb={2}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(
                          touched.accounttype && errors.accounttype
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          Type of Account
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="Savings Bank Account(SB)"
                            control={
                              <Radio
                                checked={
                                  values.accounttype ===
                                  "Savings Bank Account(SB)"
                                }
                                disabled={isSubmitting}
                              />
                            }
                            label="Savings Bank Account(SB)"
                            onChange={handleChange}
                            name="accounttype"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.accounttype && errors.accounttype && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-accounttype-accounttype"
                          >
                            {errors.accounttype}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: "20px" }} />
                  {/* bank details end */}

                  {/* Loan Details and Key Fact Statement start */}
                  <Grid
                    container
                    direction={matchDownSM ? "column-reverse" : "row"}
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Typography
                        sx={{ color: "#121926" }}
                        gutterBottom
                        variant="h6"
                      >
                        Enter your Loan Request Details
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.loanpurpose && errors.loanpurpose
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Purpose of Loan
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <Select
                          id="select"
                          value={values.loanpurpose}
                          name="loanpurpose"
                          onChange={(event) => {
                            handleLoanPurposeChange(event, setFieldValue);
                            handleChange(event);
                          }}
                          label="Purpose of Loan"
                          input={<OutlinedInput label="Purpose of Loan" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          {filteredProducts &&
                            filteredProducts.map((data) => (
                              <MenuItem value={data.Loanpurpose} key={data.id}>
                                {data.Loanpurpose}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.loanpurpose && errors.loanpurpose && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-loanpurpose-register"
                          >
                            {errors.loanpurpose}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.loanamount && errors.loanamount)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-loanamount-register">
                          Loan Sanction Amount&nbsp;(INR)
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Loan Sanction Amount&nbsp;(INR)"
                          id="outlined-adornment-loanamount-register"
                          type="text"
                          value={
                            selectedLoanDetails &&
                            selectedLoanDetails.Loanamount !== ""
                              ? selectedLoanDetails.Loanamount
                              : values.loanamount
                          }
                          name="loanamount"
                          disabled={
                            values.loanpurpose === "Hardware" ? false : true
                          }
                          onBlur={handleBlur}
                          onChange={(e) => {
                            // handleChange(),
                            handleAmountChange(
                              e.target.value,
                              setFieldValue,
                              setErrors
                            );
                          }}
                          inputProps={{}}
                        />
                        {Error.status && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-loanamount-register"
                          >
                            {Error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.loanscheme && errors.loanscheme)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-loanscheme-register">
                          Loan Scheme&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Loan Scheme&#42;"
                          id="outlined-adornment-loanscheme-register"
                          type="text"
                          value={selectedLoanDetails ? selectedLoanDetails.Loanscheme : ""}
                          disabled
                          name="loanscheme"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.loanscheme && errors.loanscheme && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-loanscheme-register"
                          >
                            {errors.loanscheme}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.tenure && errors.tenure)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-Tenure-register">
                          Tenure (Months)
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Tenure (Months)"
                          // disabled
                          id="outlined-adornment-Tenure-register"
                          type="text"
                          value={
                            selectedLoanDetails
                              ? selectedLoanDetails.Tenure
                              : values.tenure !== ""
                              ? values.tenure
                              : ""
                          }
                          disabled
                          name="Tenure"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.tenure && errors.tenure && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-Tenure-register"
                          >
                            {errors.tenure}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.roi && errors.roi)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-roi-register">
                          Rate of Intrest (Reducing)
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Rate of Intrest (Reducing)"
                          id="outlined-adornment-roi-register"
                          type="text"
                          value={
                            selectedLoanDetails
                              ? selectedLoanDetails.Rateofinterest
                              : values.roi !== ""
                              ? values.roi
                              : ""
                          }
                          disabled
                          name="roi"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.roi && errors.roi && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-roi-register"
                          >
                            {errors.roi}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={6} md={4} mb={3}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.emi && errors.emi)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-emi-register">
                          EMI (INR)
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="EMI(INR)"
                          id="outlined-adornment-emi-register"
                          type="text"
                          value={
                            selectedLoanDetails
                              ? selectedLoanDetails.EMI
                              : values.emi !== ""
                              ? values.emi
                              : ""
                          }
                          disabled
                          name="emi"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.emi && errors.emi && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-emi-register"
                          >
                            {errors.emi}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} mb={1}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.feescharge && errors.feescharge)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-feescharge-register">
                          Fees / Charges
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Fees / Charges"
                          id="outlined-adornment-feescharge-register"
                          type="text"
                          value={values.feescharge}
                          disabled
                          name="feescharge"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <IoIosInformationCircleOutline
                              style={{ fontSize: "30px", cursor: "pointer" }}
                              onClick={handleClick}
                            />
                          }
                        />
                        {touched.feescharge && errors.feescharge && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-feescharge-register"
                          >
                            {errors.feescharge}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 2 }}>
                          [Applicable taxes shall belevied on all Fees and
                          Charges specified above. <br />
                          All Fees, Charges and Tax are non- refundable]
                        </Typography>
                      </Popover>
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.sanctiondate && errors.sanctiondate
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <TextField
                          label="Company’s Sanction Letter Date &#42;"
                          id="outlined-adornment-sanctiondate-register"
                          type="date"
                          value='NA'
                          defaultValue='NA'
                          disabled
                          focused
                          name="sanctiondate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{ "&:type": { backgroundColor: "red" } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {touched.sanctiondate && errors.sanctiondate && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-sanctiondate-register"
                          >
                            {errors.sanctiondate}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.overdueintrest && errors.overdueintrest
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-overdueintrest-register">
                          Overdue interest (%) p. a.&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Overdue interest (%) p. a.&#42;"
                          id="outlined-adornment-overdueintrest-register"
                          type="text"
                          value={selectedLoanDetails ? selectedLoanDetails.OverdueInterest : ""}
                          disabled
                          name="overdueintrest"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.overdueintrest && errors.overdueintrest && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-overdueintrest-register"
                          >
                            {errors.overdueintrest}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.loantype && errors.loantype)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Type of the Loan&#42;
                        </InputLabel>
                        <Select
                          label="Type of the Loan&#42;"
                          id="select"
                          value={values.loantype}
                          name="loantype"
                          onChange={handleChange}
                          input={<OutlinedInput label="Type of the Loan&#42;" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="none">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="option1">loan option 1</MenuItem>
                          <MenuItem value="option2">loan option 2</MenuItem>
                        </Select>
                        {touched.loantype && errors.loantype && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-loantype-register"
                          >
                            {errors.loantype}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6} md={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.commencementdate && errors.commencementdate
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >

                        <TextField
                          label="Date of Commencement of EMI&#42;"
                          id="outlined-adornment-commencementdate-register"
                          type="date"
                          value='NA'
                          defaultValue='NA'
                          disabled
                          focused
                          name="commencementdate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{ "&:type": { backgroundColor: "red" } }}
                          inputProps={{
                            shrink: true,
                          }}
                        />
                        {touched.commencementdate && errors.commencementdate && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-commencementdate-register"
                          >
                            {errors.commencementdate}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.loanid && errors.loanid)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-loanid-register">
                          The Company’s Loan ID &#42;
                        </InputLabel>
                        <OutlinedInput
                          label="The Company’s Loan ID &#42;"
                          id="outlined-adornment-loanid-register"
                          type="text"
                          value={values.loanid}
                          disabled
                          name="loanid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.loanid && errors.loanid && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-loanid-register"
                          >
                            {errors.loanid}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid> */}
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} xl={4} lg={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.companyaddress && errors.companyaddress
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-companyaddress-register">
                          Name and Address of the Company&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Name and Address of the Company&#42;"
                          id="outlined-adornment-companyaddress-register"
                          type="text"
                          value={values.companyaddress}
                          // defaultValue={""}
                          disabled
                          name="companyaddress"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.companyaddress && errors.companyaddress && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-companyaddress-register"
                          >
                            {errors.companyaddress}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.annualpercentage && errors.annualpercentage
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-annualpercentage-register">
                          Annual Percentage Rate (APR)&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Annual Percentage Rate (APR)&#42;"
                          id="outlined-adornment-annualpercentage-register"
                          type="text"
                          value={values.annualpercentage}
                          disabled
                          name="annualpercentage"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.annualpercentage && errors.annualpercentage && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-annualpercentage-register"
                          >
                            {errors.annualpercentage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.availableperiod && errors.availableperiod
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-availableperiod-register">
                          Availability Period (Months)&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Availability Period (Months)"
                          id="outlined-adornment-availableperiod-register"
                          type="text"
                          value={values.availableperiod}
                          disabled
                          name="availableperiod"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.availableperiod && errors.availableperiod && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-availableperiod-register"
                          >
                            {errors.availableperiod}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.modeofrepayment && errors.modeofrepayment
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Mode of Repayment&#42;
                        </InputLabel>
                        <Select
                          id="select"
                          value={values.modeofrepayment}
                          name="modeofrepayment"
                          onChange={handleChange}
                          disabled
                          label="Mode of Repayment&#42;"
                          input={<OutlinedInput label="Mode of Repayment&#42;" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="N/A">
                            <em>N/A</em>
                          </MenuItem>
                          <MenuItem value="NEFT">loan option 1</MenuItem>
                          <MenuItem value="Debit Card">loan option 2</MenuItem>
                          <MenuItem value="Credit Card">loan option 2</MenuItem>
                        </Select>
                        {touched.modeofrepayment && errors.modeofrepayment && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-modeofrepayment-register"
                          >
                            {errors.modeofrepayment}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.repaymentfrequency && errors.repaymentfrequency
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Frequency of Repayment&#42;
                        </InputLabel>
                        <Select
                          id="select"
                          value={values.repaymentfrequency}
                          disabled
                          name="repaymentfrequency"
                          onChange={handleChange}
                          label="Frequency of Repayment&#42;"
                          input={
                            <OutlinedInput label="Frequency of Repayment&#42;" />
                          }
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="N/A">
                            <em>N/A</em>
                          </MenuItem>
                          <MenuItem value="option1">loan option 1</MenuItem>
                          <MenuItem value="option2">loan option 2</MenuItem>
                        </Select>
                        {touched.repaymentfrequency &&
                          errors.repaymentfrequency && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-repaymentfrequency-register"
                            >
                              {errors.repaymentfrequency}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.instalmentsrepayments &&
                            errors.instalmentsrepayments
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          No of Instalments for Repayment&#42;
                        </InputLabel>
                        <Select
                          labelId="Number of Instalments for Repayment"
                          id="select"
                          value={values.instalmentsrepayments}
                          disabled
                          name="instalmentsrepayments"
                          onChange={handleChange}
                          label="Number of Instalments for Repayment"
                          input={
                            <OutlinedInput label="Number of Instalments for Repayment" />
                          }
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="N/A">
                            <em>N/A</em>
                          </MenuItem>
                          <MenuItem value="option1">loan option 1</MenuItem>
                          <MenuItem value="option2">loan option 2</MenuItem>
                        </Select>
                        {touched.instalmentsrepayments &&
                          errors.instalmentsrepayments && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-instalmentsrepayments-register"
                            >
                              {errors.instalmentsrepayments}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12} sm={8} md={6} mb={2}>
                      {/* <FormControl
                        component="fieldset"
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          Details of Post Dated Security Cheques&#42;
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="ECS"
                            control={<Radio disabled={isSubmitting} />}
                            label="ECS"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="NACH"
                            control={<Radio disabled={isSubmitting} />}
                            label="NACH"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-postdatecheque"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl> */}
                      <Typography variant="subtitle1">
                        Details of Post Dated Security Cheques 1
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-postdatecheque-register">
                        Details of Post Dated Security Cheques&#42;
                        </InputLabel> */}
                        <OutlinedInput
                          // label="Details of Post Dated Security Cheques&#42;"
                          id="outlined-adornment-postdatecheque-register"
                          type="text"
                          // value={cheque1?cheque1.name:''}
                          value={cheque1 ? cheque1.name : ""}
                          name="postdatecheque"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            cheque1?
                            <CancelIcon sx={{ cursor: 'pointer', color:"#000" }} onClick={handleCheque1Close} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "13px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handleImageUpload}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-register"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={8} md={6} mb={2}>
                      {/* <FormControl
                        component="fieldset"
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          Details of Post Dated Security Cheques&#42;
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="ECS"
                            control={<Radio disabled={isSubmitting} />}
                            label="ECS"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="NACH"
                            control={<Radio disabled={isSubmitting} />}
                            label="NACH"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-postdatecheque"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl> */}
                      <Typography variant="subtitle1">
                        Details of Post Dated Security Cheques 2
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-postdatecheque-register">
                        Details of Post Dated Security Cheques&#42;
                        </InputLabel> */}
                        <OutlinedInput
                          // label="Details of Post Dated Security Cheques&#42;"
                          id="outlined-adornment-postdatecheque-register"
                          type="text"
                          // value={cheque2?cheque2.name:''}
                          value={cheque2 ? cheque2.name : ""}
                          name="postdatecheque"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            cheque2?
                            <CancelIcon sx={{ cursor: 'pointer', color:"#000" }} onClick={handlecheque2Close} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "13px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handleImageUpload2}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-register"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      xs={12}
                      sm={8}
                      md={6}
                      mb={2}
                      sx={{
                        display: BankNameData === "SBI" ? "none" : "initial",
                      }}
                    >
                      {/* <FormControl
                        component="fieldset"
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          Details of Post Dated Security Cheques&#42;
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="ECS"
                            control={<Radio disabled={isSubmitting} />}
                            label="ECS"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="NACH"
                            control={<Radio disabled={isSubmitting} />}
                            label="NACH"
                            onChange={handleChange}
                            name="postdatecheque"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-postdatecheque"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl> */}
                      <Typography variant="subtitle1">
                        ITRs for minimum 2 years
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        required
                        fullWidth
                        error={Boolean(
                          touched.postdatecheque && errors.postdatecheque
                        )}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-postdatecheque-register">
                        Details of Post Dated Security Cheques&#42;
                        </InputLabel> */}
                        <OutlinedInput
                          // label="Details of Post Dated Security Cheques&#42;"
                          id="outlined-adornment-postdatecheque-register"
                          type="text"
                          // value={cheque2?cheque2.name:''}
                          value={ItrUpload ? ItrUpload.name : ""}
                          name="postdatecheque"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            ItrUpload?
                            <CancelIcon sx={{ cursor: 'pointer', color:"#000" }} onClick={handleItrUploadClose} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "13px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handlePdfUpload}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload a image in a format such as jpg,
                          jpeg, or png and pdf format only.
                        </Typography>
                        {touched.postdatecheque && errors.postdatecheque && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postdatecheque-register"
                          >
                            {errors.postdatecheque}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Loan Details and Key Fact Statement end */}

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Stack>
              <Grid
                container
                spacing={4}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Grid item sm={6} xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      // disabled={isSubmitting || !isValid}
                      fullWidth
                      size="large"
                      // type="submit"
                      variant="outlined"
                      sx={{
                        borderColor: "#A556B2",
                        color: "#A556B2",
                        "&:hover": { borderColor: "#A556B2" },
                      }}
                      onClick={() =>
                        Navigate("/loan", {
                          state: {
                            indexdata,
                            proofid: Imageid,
                            userimage: Image,
                            aadhar: AadharImage,
                            pan: PanImage,
                            userincomeproof: poi,
                            detailvalues: values,
                            datecheque1: cheque1,
                            datecheque2: cheque2,
                            dataitr: ItrUpload,
                          },
                        })
                      }
                    >
                      back
                    </Button>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !cheque1 ||
                        !cheque2 ||
                        !AccVerified ||
                        BankNameData === "SBI"
                          ? false
                          : !ItrUpload
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#A556B2",
                        color: "#fff",
                        "&:hover": { backgroundColor: "#A556B2" },
                      }}
                      // onClick={() => {
                      //   Navigate("/borrowerdetails", {
                      //     state: {
                      //       indexdata,
                      //       Imageid,
                      //       Image,
                      //       AadharImage,
                      //       PanImage,
                      //       poi,
                      //       detailvalues: values,
                      //       cheque1:cheque1,
                      //       cheque2:cheque2
                      //     },

                      //   });
                      //   //console.log("values", values);
                      // }}
                    >
                      next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    );
  } else window.location.href = "/";
};

export default ExistingDetails;
