import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {Box,Stack,CircularProgress} from "@mui/material";
import PendingForm from "./PendingForm";
import ApproveForm from "./ApproveForm";
import RejecttionForm from "./RejectionForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../FormStructure.js/Home/Header";
import LoanApplicationForm from "./LoanApplication";
import { ExpiredDocumentsApi } from "../../Axios/Approval/api";
import ExpiredDocument from './ExpiredDocumnet'
import Equifax from "./Equifax";


function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  const [Data,setData] = useState('')
console.log("Data",Data);

  useEffect(() => {
    ExpiredDocumentsApi(setData)
  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const orientation = useMediaQuery("(min-width:600px)")
    ? "vertical"
    : "horizontal";
  const direction = useMediaQuery("(min-width:600px)") ? "row" : "column";

  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: direction,
        }}
      >
        <Tabs
          orientation={orientation}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            textAlign: "left",
            borderColor: "divider",
            width: useMediaQuery("(min-width:600px)") ? "13%" : "100%",
            height: useMediaQuery("(min-width:600px)") ? "70vh" : "5vh",
          }}
        >
          <Tab
            label="Pending"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Approve"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(1)}
          />
          <Tab
            label="Rejected"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(2)}
          />
          <Tab
            label="Loan Application"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(3)}
          />
          <Tab
            label="Expired Documents"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(4)}
          />
          <Tab
            label="Equifax Upload Data"
            sx={{ fontSize: "12px", textAlign: "left" }}
            {...a11yProps(4)}
          />
        </Tabs>
        <div
          style={{
            width: useMediaQuery("(min-width:600px)") ? "100%" : "100%",
          }}
        >
          <TabPanel sx={{ height: "100vh" }} value={value} index={0}>
            <PendingForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ApproveForm />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RejecttionForm />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <LoanApplicationForm />
          </TabPanel>
          <TabPanel value={value} index={4}>
            {Data?
            <ExpiredDocument Data={Data}/>
            :
            <Stack sx={{ color: 'grey.500', textAlign: 'center' }} spacing={2} direction="row">
             <CircularProgress color='primary' />
            </Stack>
            }
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Equifax />  
          </TabPanel>
        </div>
      </Box>
    </>
  );
}
