import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  FormControl,
  Grid,
  Typography,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  FormLabel,
} from "@mui/material";
// import { Field } from "formik";

const ExistingLoan = (props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const values = props.detailsdata;
  // //console.log("values", values);

  return (
    <>
      <form noValidate>
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
          mb={4}
        ></Grid>
        <Stack direction="column">
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              {/* preview existing loan details start*/}

              {/* preview existing loan details end*/}

              {/* preview bank details start*/}
              <Grid
                container
                direction={matchDownSM ? "column-reverse" : "row"}
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                  <Typography
                    sx={{ color: "#121926" }}
                    gutterBottom
                    variant="h6"
                  >
                    Banking Details
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.email && errors.email)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-register">
                    Name of Account Holder &#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Name of Account Holder&#42;"
                    id="outlined-adornment-email-register"
                    type="email"
                    value={values.accname}
                    name="email"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-register"
                        >
                          {errors.email}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspcode && errors.cspcode)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspcode-register">
                    Name of Bank and branch&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Name of Bank and branch&#42;"
                    id="outlined-adornment-cspcode-register"
                    type="cspcode"
                    value={values.bankbranch}
                    name="cspcode"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.cspcode && errors.cspcode && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspcode-register"
                        >
                          {errors.cspcode}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.resaddress && errors.resaddress)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-resaddress-register">
                    IFSC Code&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="IFSC Code&#42;"
                    id="outlined-adornment-resaddress-register"
                    fullWidth
                    type="text"
                    value={values.ifsc}
                    name="resaddress"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.resaddress && errors.resaddress && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-resaddress-regresaddressister"
                        >
                          {errors.resaddress}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.branch && errors.branch)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-branch-register">
                    Account Number&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Account Number&#42;"
                    id="outlined-adornment-branch-register"
                    type="text"
                    value={values.accountno}
                    name="branch"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.branch && errors.branch && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-branch-register"
                        >
                          {errors.branch}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={3}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.residingyear && errors.residingyear
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-residingyear-register">
                    Type of Account&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Type of Account&#42;"
                    id="outlined-adornment-residingyear-register"
                    fullWidth
                    type="text"
                    value={values.accounttype}
                    name="residingyear"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.residingyear && errors.residingyear && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-residingyear-residingyear"
                        >
                          {errors.residingyear}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid
                container
                direction={matchDownSM ? "column-reverse" : "row"}
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                  <Typography
                    sx={{ color: "#121926" }}
                    gutterBottom
                    variant="h6"
                  >
                    Loan Request Details
                  </Typography>
                </Stack>
              </Grid>
              {/* preview bank details end*/}

              {/* Loan Request Details start */}
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspyear && errors.cspyear)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspyear-register">
                    Purpose of Loan&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Purpose of Loan&#42;"
                    id="outlined-adornment-cspyear-register"
                    fullWidth
                    type="text"
                    value={values.loanpurpose}
                    name="cspyear"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.cspyear && errors.cspyear && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspyear-cspyear"
                        >
                          {errors.cspyear}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    Loan Amount (INR)&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Loan Amount (INR)&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.loanamount}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    Tenure(Month)&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Tenure(Month)&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.tenure}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    Rate of Interest&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Rate of Interest(Reducing)&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.roi}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    EMI(INR) &#42;
                  </InputLabel>
                  <OutlinedInput
                    label="EMI(INR)&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.emi}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    Fees / Charges &#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Fees / Charges&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.feescharge}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              {/* Loan Request Details end */}
            </Grid>

            {/* <Grid container spacing={2}> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                  <Typography variant="subtitle1">Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;</Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.idproof && errors.idproof)}
                      sx={{ mb: "10px", ...theme.typography.customInput }}
                    >

                      <OutlinedInput
                        id="outlined-adornment-idproof-register"
                        type="text"
                        value={values.idproof}
                        name="idproof"
                        onBlur={handleBlur}
                        disabled={true}
                        endAdornment={<Button
                          component="label"
                          sx={{
                            fontSize: "13px",
                            width: "235px",
                            height: "40px",
                            backgroundColor: "#A556B2",
                            "&:hover": { backgroundColor: "#A556B2" },
                          }}
                          variant="contained"
                        >
                          Upload
                          <VisuallyHiddenInput
                            type="file"
                            name=""
                            onChange={handleImageUpload}
                          />
                        </Button>}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.idproof && errors.idproof && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-idproof-register"
                        >
                          {errors.idproof}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} mb={2} sx={{ textAlign: "center" }}
                  >
                  <Typography variant="subtitle1" sx={{float:"left"}}>Image Upload&#42;</Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.image && errors.image)}
                      sx={{ ...theme.typography.customInput }}
                    >

                      <OutlinedInput
                        id="outlined-adornment-fname-register"
                        type="text"
                        value={ImageUpload}
                        disabled={true}
                        endAdornment={
                          <Button
                            component="label"
                            sx={{
                              fontSize: "13px",
                              width: "235px",
                              height: "40px",
                              backgroundColor: "#A556B2",
                              "&:hover": { backgroundColor: "#A556B2" },
                            }}
                            variant="contained"
                          >
                            Image Upload
                            <VisuallyHiddenInput
                              type="file"
                              name=""
                              onChange={handleFileChange}
                            />
                          </Button>
                        }
                        name="image"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.image && errors.image && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-image-register"
                        >
                          {errors.image}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
            {/* </Grid> */}
            {/* {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )} */}
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default ExistingLoan;
