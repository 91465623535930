import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Drawer,
  Grid,
  Snackbar,
  TextField,
  Alert,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useState } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "../styles/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../images/FILending-logo.svg";
import { useNavigate } from "react-router-dom";
import { AiOutlinePoweroff } from "react-icons/ai";
import { LogoutApi, NewRegisterAdd } from "../../../Axios/ServiceApi";
import LoadingButton from "@mui/lab/LoadingButton";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const classes = useStyles();
  const Navigate = useNavigate();
  const user_token = sessionStorage.getItem("user_token");
  const admin_token = sessionStorage.getItem("admin_token");
  const links = [
    { id: 2, route: "Home", url: "/home" },
    { id: 1, route: "About" },
    { id: 2, route: "Contact" },
    { id: 2, route: "Repay Loan" },
    { id: 2, route: "Excel Form", url:'/excelform' },
    
    
  ];
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRegisterClose = () => {
    setOpen(false);
    setIsLoadingNewreg(false)
  };
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((link) => (
          <ListItem button key={link.id}>
            <ListItemText primary={link.route} />
          </ListItem>
        ))}
        <ListItem button onClick={() => Navigate("/loanstatus")}>
          <ListItemText primary={"Check Application"} />
        </ListItem>
        <ListItem button onClick={handleClickOpen}>
          <ListItemText primary={"Register User"} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </Box>
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [LogErr, setLogErr] = useState(false);
  const [LogRes, setLogRes] = useState(false);
  const [LogErrRes, setLogErrRes] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoadingNewreg, setIsLoadingNewreg] = useState(false);
  const [Success, setSuccess] = useState(false);
  const [Error, setError] = useState(false);
  const [Data, setData] = useState({});

  const handleChange = (name, event) => {
    if (name === "mobile_number") {
      setData({ ...Data, [name]: event });
    }
  };
  const handleSubmit = () => {
    if (Data.mobile_number && Data.mobile_number.length === 10) {
      NewRegisterAdd(setIsLoadingNewreg, setSuccess, setError, setOpen);
    } else {
      alert("please enter 10 digit mobile number");
    }
  };

  const handleLogoutFn = () => {
    let token = user_token ? user_token : admin_token ? admin_token : null;

    LogoutApi(handleLogout, handleErrLogout, setLogErr, token, setIsLoading);
  };

  const handleLogout = () => {
    setLogRes(true);
    setIsLoading(false);
  };

  const handleErrLogout = () => {
    setLogErrRes(true);
    setIsLoading(false);
  };

  const handleClose = () => {
    setLogRes(false);
    setLogErrRes(false);
    setSuccess(false);
    setError(false);
  };

  const handleInput = (e) => {
    let value = e.target.value;

    // Remove all non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Check if the first digit is valid (not 2, 3, 4, 1, or 0)
    if (
      value[0] === "2" ||
      value[0] === "3" ||
      value[0] === "4" ||
      value[0] === "1" ||
      value[0] === "0"
    ) {
      // Clear the input or set an error message here if necessary
      value = "";
    }

    // Limit the value to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    e.target.value = value;
  };

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={IsLoadingNewreg}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleRegisterClose}>
        <DialogTitle>Register mobile number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter mobile number here. We
            will provide loan updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="mobile_number"
            label="Enter mobile number"
            onChange={(e) => handleChange("mobile_number", e.target.value)}
            fullWidth
            variant="standard"
            onInput={handleInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRegisterClose}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit}>
            submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* register field end */}
      <Snackbar
        open={LogErrRes}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" severity="error">
          {LogErr !== "" ? LogErr : "Something went wrong!!!"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={LogRes}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" severity="success">
          Successfully Logout
        </Alert>
      </Snackbar>
      <Snackbar
        open={Success}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" severity="success">
          {Success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Error}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" severity="error">
          {Error}
        </Alert>
      </Snackbar>
      //
      <Box sx={{ marginBottom: "70px" }}>
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar className={classes.toolBar}>
              <Link underline="none">
                <Typography variant="h5" className={classes.logo}>
                  {/* LOGO */}
                  <Box
                    component="img"
                    sx={{ height: 50 }}
                    alt="Logo"
                    src={Logo}
                  />
                </Typography>
              </Link>

              {matches ? (
                <Box>
                  <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer("right", true)}
                  >
                    <MenuIcon className={classes.menuIcon} fontSize="" />
                  </IconButton>

                  <Drawer
                    anchor="right"
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    {list("right")}
                  </Drawer>
                </Box>
              ) : (
                <Grid
                  container
                  spacing={5}
                  justifyContent={"flex-end"}
                  // sx={{
                  //   display: "flex",
                  //   justifyContent: "space-evenly",
                  //   flexGrow: "0.1",
                  // }}
                >
                  {links.map((link) => (
                    <Grid item>
                      <Link href={link.url} underline="none" key={link.id}>
                        <Typography
                          className={classes.link}
                          sx={{
                            cursor: "pointer",
                            fontSize: "16px",
                            display: user_token ? "block" : "none",
                          }}
                        >
                          {link.route}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid
                container
                display={{
                  xs: "none",
                  sm: "none",
                  xl: "flex",
                  md: "flex",
                  lg: "flex",
                }}
                spacing={1}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "12px",
                      display: user_token ? "block" : "none",
                    }}
                    onClick={() => Navigate("/loanstatus")}
                  >
                    Check Loan Status
                  </Button>
                </Grid>
                {/* <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: "12px" }}
                  // onClick={() => Navigate('/loan')}
                >
                  Language
                </Button>
                </Grid> */}
                <Grid item>
                  {!user_token && !admin_token ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ fontSize: "12px" }}
                      onClick={() => Navigate("/")}
                    >
                      sign in &nbsp;
                      <AiOutlinePoweroff style={{ fontSize: "16px" }} />
                    </Button>
                  ) : (
                    <>
                      {/* // <Stack justifyContent={"center"} alignItems="center" mt={2}> */}
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleClickOpen}
                        sx={{ marginRight: "5px", fontSize: "12px" }}
                      >
                        Register User
                      </Button>
                      {/* // </Stack> */}
                      <LoadingButton
                        variant="outlined"
                        loading={IsLoading}
                        color="error"
                        sx={{ fontSize: "12px" }}
                        onClick={() => handleLogoutFn()}
                      >
                        sign out &nbsp;
                        <AiOutlinePoweroff style={{ fontSize: "16px" }} />
                      </LoadingButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </Box>
    </>
  );
};

export default Header;
