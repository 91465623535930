import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  styled,
  Snackbar,
  Alert,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  // IconButton,
  // InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  // useMediaQuery,
  RadioGroup,
  debounce,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";

// third party
import * as Yup from "yup";
import { Formik, Field } from "formik";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import useScriptRef from "../../hooks/useScriptRef";
import {
  AadharData,
  Aadharapi,
  Panapi,
  VerifyEmailotpapi,
  VerifyEmailpapi,
} from "../../Axios/ServiceApi";
// import { debounce } from '@mui/material/utils';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid",
  boxShadow: 24,
  p: 4,
};

const Index = (props, { ...others }) => {
  const theme = useTheme();
  // const CityData = props.city
  // const StateData = props.state
  // const PincodeData = props.pincode
  // console.log("CityData",CityData,StateData,PincodeData);
  const scriptedRef = useScriptRef();
  const Navigate = useNavigate();
  const location = useLocation();
  const aadharName = location.state
    ? location.state.aadhar
      ? location.state.aadhar
      : null
    : null;
  const panName = location.state
    ? location.state.pan
      ? location.state.pan
      : null
    : null;
  const userid = location.state
    ? location.state.proofid
      ? location.state.proofid
      : null
    : null;
  const userphoto = location.state
    ? location.state.userimage
      ? location.state.userimage
      : null
    : null;
  const userproof = location.state
    ? location.state.userincomeproof
      ? location.state.userincomeproof
      : null
    : null;
  // const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const eighteen_years_ago = dayjs().subtract(15, "year").toDate();
  const [open, setOpen] = useState(false);
  const [ImageUpload, setImageUpload] = useState(userphoto);
  const [IdUpload, setIdUpload] = useState(userid);
  const [AadharUpload, setAadharUpload] = useState(aadharName);
  const [PanUpload, setPanUpload] = useState(panName);
  const [PoiUpload, setPoiUpload] = useState(userproof);
  const [PanOpen, setPanOpen] = useState(false);
  const [PanErr, setPanErr] = useState(false);
  const [AadhaarOpen, setAadhaarOpen] = useState(false);
  const [AadhaarErr, setAadhaarErr] = useState(false);
  // const [DisableButton,setDisableButton] = useState(false)
  const [PanSubmit, setPanSubmit] = useState(false);
  const [PanWait, setPanWait] = useState(false);
  const indexdata = location.state
    ? location.state.indexdata
      ? location.state.indexdata
      : null
    : null;
  const detailvalues = location.state
    ? location.state.detailvalues
      ? location.state.detailvalues
      : null
    : null;
  const borrowerdetails = location.state
    ? location.state.borrowerdetails
      ? location.state.borrowerdetails
      : null
    : null;
  const cheque1 = location.state
    ? location.state.datecheque1
      ? location.state.datecheque1
      : null
    : null;
  const cheque2 = location.state
    ? location.state.datecheque2
      ? location.state.datecheque2
      : null
    : null;

  // email verify
  const [EmailVerifyopen, setEmailVerifyopen] = useState(false);
  const [EmailOtpCheck, setEmailOtpCheck] = useState(false);
  const [EmailSuccessOtp, setEmailSuccessOtp] = useState(false);
  const [EmailOtpErr, setEmailOtpErr] = useState(false);
  const [Emailresdata, setEmailresdata] = useState(false);
  const [IsLoadingEmail, setIsLoadingEmail] = useState(false);
  const [DisableEmailField, setDisableEmailField] = useState(false);

  const handleEmailVerifyOpen = debounce((EmailId) => {
    // setEmailVerifyopen(true);
    VerifyEmailpapi(
      EmailId,
      handleMailOtpSent,
      setEmailresdata,
      handleMailErrOtp,
      setEmailOtpCheck,
      setIsLoadingEmail
    );
    setRemainingTime(120);
  }, 2000);

  const EmailverifyClose = () => {
    setEmailSuccessOtp(false);
  };

  const EmailErrClose = () => {
    setEmailOtpErr(false);
  };

  const handleMailOtpSent = () => {
    setEmailresdata(true);
    setEmailSuccessOtp(true);
    setTimeout(() => {
      setIsLoadingEmail(false);
      setEmailVerifyopen(true);
    }, 2000);
  };

  const handleMailErrOtp = () => {
    setEmailOtpErr(true);
    setEmailOtpCheck(true);
    setIsLoadingEmail(false);
  };
  // Verify Email Otp
  const [OtpEmail, setOtpEmail] = useState(false);
  const [ErrOtpEmail, SetErrOtpEmail] = useState(false);
  const [EmailresErr, setEmailresErr] = useState(false);
  const [OtpEmailres, SetOtpEmailres] = useState(false);
  const [DobErr, setDobErr] = useState(false);
  const [PanDobErr, setPanDobErr] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const handleEmailVerifyClose = (email, otp) => {
    //console.log("otpmail", email,otp);
    VerifyEmailotpapi(
      email,
      otp,
      handleEmailOtp,
      SetOtpEmailres,
      handleEmailErr,
      setEmailresErr
    );
  };

  const handleEmailOtp = () => {
    setDisableEmailField(true);
    setOtpEmail(true);
    SetOtpEmailres(true);
    setTimeout(() => {
      setEmailVerifyopen(false);
    }, 3000);
  };

  const handleEmailErr = () => {
    SetErrOtpEmail(true);
    setEmailresErr(true);
  };

  const handleEmailERRClose = () => {
    SetErrOtpEmail(false);
  };

  const handleEmailRESClose = () => {
    setOtpEmail(false);
  };

  const handleFileChange = (e) => {
    // Handle the selected file here, you can upload it to a server or process it in some way
    const selectedFile = e.target.files[0];
    setImageUpload(selectedFile);
  };
  const handleImageUploadClose = () => {
    setImageUpload(false)
  }
  // //console.log("ImageUpload", ImageUpload);

  const handleImageUpload = (e) => {
    // //console.log("file",e);
    setIdUpload(e.target.files[0]);
  };
  const handleIDClose = () => {
    setIdUpload(false)
  }

  const handleAadharUpload = (e) => {
    // //console.log("file",e);
    setAadharUpload(e.target.files[0]);
  };
  const handleImageClose = () => {
    setAadharUpload(false);
  };

  const handlePanUpload = (e) => {
    // //console.log("file",e);
    setPanUpload(e.target.files[0]);
  };
  const handlePanClose = ()=>{
    setPanUpload(false)
  }

  const handlePoiUpload = (e) => {
    // //console.log("file",e);
    setPoiUpload(e.target.files[0]);
  };

  // aadhaar verify
  const handleVerifyAadhaar = debounce((aadhaar) => {
    Aadharapi(aadhaar, handleaadhaarClick, handleaadhaarErr, setIsLoading);
  }, 2000);

  // const valueAadhaar = sessionStorage.getItem('input_Aadhaar')
  // console.log(valueAadhaar);

  const handleaadhaarErr = () => {
    setAadhaarErr(true);
    setIsLoading(false);
    // setDisableAadhaarButton(false)
  };
  const queryParams = new URLSearchParams(location.search);

  const handleaadhaarClick = () => {
    setAadhaarOpen(true);
    setIsLoading(false);
    // setDisableAadhaarButton(true)
    // queryParams.delete()
  };

  const [IsLoadingPan, setIsLoadingPan] = useState(false);
  // panverify
  const handlePanVerify = debounce((panNo, dob, err, name, setFieldError) => {
    console.log(err);
    if (dob === "" || err) {
      setDobErr(true);
      setFieldError("dob", "Valid Dob is required for verification");
      setFieldError("dob", "Dob is required");
      return;
    } else if (panNo !== "") {
      setPanWait(true);
      Panapi(
        panNo,
        dob,
        name,
        handlePanClick,
        handlePanErr,
        handlePanDobErr,
        setIsLoadingPan
      );
    }
  }, 2000);

  const handlePanClick = () => {
    setPanOpen(true);
    // setDisableButton(true)
    sessionStorage.setItem("setDisableButton", true);
    setPanSubmit(true);
    setIsLoadingPan(false);
    setPanWait(false);
  };

  const handlePanDobErr = () => {
    setPanDobErr(true);
    setIsLoadingPan(false);
    setPanWait(false);
  };

  const handlepanerrClose = () => {
    setPanErr(false);
    setIsLoadingPan(false);
    setDobErr(false);
    setPanDobErr(false);
  };

  const handleAadharClose = () => {
    setAadhaarOpen(false);
  };

  const handleAadhaarerr = () => {
    setAadhaarErr(false);
  };

  const handleresclose = () => {
    setPanOpen(false);
  };

  const handlePanErr = () => {
    setPanErr(true);
    // setDisableButton(false)
    setPanWait(false);
  };

  const MAX_FILE_SIZE = 102400; //100KB

  const validFileExtensions = { image: ["jpg", "png", "jpeg"] };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const Token = sessionStorage.getItem("user_token");

  // const indexdetails = (e,values) => {
  //   //console.log("idescvlausfv",values);
  //   Navigate("/existingdetails", {
  //     state: { Indexvalues: values },
  //   });
  // }

  // auto populate
  const cspcode = sessionStorage.getItem("csp_code");
  const mobileno = sessionStorage.getItem("mobile_no");
  const address1 = sessionStorage.getItem("address1");
  const address2 = sessionStorage.getItem("address2");
  const pincode = sessionStorage.getItem("pincode");
  const email = sessionStorage.getItem("email");
  const fathername = sessionStorage.getItem("fathername");
  const aadhar = sessionStorage.getItem("aadhar");
  const username = sessionStorage.getItem("user_name");
  // const dateofbirth=sessionStorage.getItem('dateofbirth')
  const gender = sessionStorage.getItem("user_gender");
  const panNumber = sessionStorage.getItem("user_pancard");
  const DisableAadhaarButton = props.DisableAadhaarButton;
  const DisableButton = sessionStorage.getItem("setDisableButton");
  const DisableEmail = sessionStorage.getItem("setDisableEmail");
  const dateofbirth = sessionStorage.getItem("dateofbirth");
  // const CityData = sessionStorage.getItem("City");

  console.log("dateofbirth", dateofbirth);
  var originalDate = new Date(dateofbirth);

  // Extract day, month, and year from the Date object
  var day = originalDate.getDate();
  var month = originalDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
  var year = originalDate.getFullYear();

  // Format day, month, and year with leading zeros if necessary
  var formattedDay = (day < 10 ? "0" : "") + day;
  var formattedMonth = (month < 10 ? "0" : "") + month;

  // Form the final formatted date string
  var formattedDateString = year + "-" + formattedMonth + "-" + formattedDay;

  console.log(formattedDateString);
  console.log("DisableAadhaarButton", DisableAadhaarButton);

  // useEffect(()=>{
  //   if(DisableAadhaarButton==='true'&&!sessionStorage.getItem("pagereloaded")){
  //       sessionStorage.setItem("pagereloaded",true)
  //       window.location.reload();
  //   }
  // },[DisableAadhaarButton])

  const bankdetails = [
    "SBI",
    // "AGB",
    // "BGGB",
    "AGVB",
    "BOB",
    "BOI",
    // "BRKGB",
    // "BUPGB",
    "CBI",
    // "CRGB",
    // "EDB",
    "IB",
    "IOB",
    // "MGB",
    // "PBGB",
    "PNB",
    // "RMGB",
    // "SBBJ",
    // "SBOP",
    // "UBGB",
    "UBI",
    // "UBKGB",
    "UCO",
    // "UGB",
    // "VGB",
    // "Other Bank",
  ];
  const [remainingTime, setRemainingTime] = useState(120);

  useEffect(() => {
    if (EmailVerifyopen) {
      //console.log("timer open");
      const interval = setInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime(remainingTime - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  });

  if (Token) {
    return (
      <>
        {/*  */}
        <Snackbar
          open={AadhaarErr}
          autoHideDuration={6000}
          onClose={handleAadhaarerr}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleAadhaarerr}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Unable to retrieve Aadhaar details. Please try again.
          </Alert>
        </Snackbar>
        <Snackbar
          open={PanErr}
          autoHideDuration={6000}
          onClose={handlepanerrClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handlepanerrClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Unable to retrieve PAN details. Please try again.
          </Alert>
        </Snackbar>
        <Snackbar
          open={PanDobErr}
          autoHideDuration={6000}
          onClose={handlepanerrClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handlepanerrClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Your Dob does not match your pan database
          </Alert>
        </Snackbar>
        <Snackbar
          open={DobErr}
          autoHideDuration={6000}
          onClose={handlepanerrClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handlepanerrClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Enter Dob or Dob is not valid to verify pan
          </Alert>
        </Snackbar>
        <Snackbar
          open={PanOpen}
          autoHideDuration={6000}
          onClose={handleresclose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleresclose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Successfully verified your PAN Number
          </Alert>
        </Snackbar>
        <Snackbar
          open={AadhaarOpen}
          autoHideDuration={6000}
          onClose={handleAadharClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleAadharClose}
            severity="warning"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Please verify your Aadhar Number with digilocker
          </Alert>
        </Snackbar>
        {/* email error handle */}
        <Snackbar
          open={EmailSuccessOtp}
          autoHideDuration={6000}
          onClose={EmailverifyClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={EmailverifyClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {Emailresdata != "" ? Emailresdata : "OTP Sent to your mail"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={EmailOtpErr}
          autoHideDuration={6000}
          onClose={EmailErrClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={EmailErrClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {EmailOtpCheck != "" ? EmailOtpCheck : "Something went Wrong!!!"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={OtpEmail}
          autoHideDuration={6000}
          onClose={handleEmailRESClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleEmailRESClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {OtpEmailres != "" ? OtpEmailres : "Email Verified"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={ErrOtpEmail}
          autoHideDuration={6000}
          onClose={handleEmailERRClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleEmailERRClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {EmailresErr != "" ? EmailresErr : "Something went Wrong!!!"}
          </Alert>
        </Snackbar>
        {/*  */}
        <Formik
          initialValues={{
            name: indexdata
              ? indexdata.name
              : !username || username === "null"
              ? ""
              : username,
            dob: indexdata ? indexdata.dob : formattedDateString,
            gender:
              gender === "M"
                ? "Male"
                : gender === "F"
                ? "Female"
                : gender === "O"
                ? "Others"
                : null,
            fatherspouse: fathername,
            // fatherspouse: indexdata?indexdata.fatherspouse:!fathername || fathername === 'null' ? "": fathername,
            martailstatus: indexdata ? indexdata.martailstatus : "",
            // statecode:indexdata ? indexdata.statuscode : "",
            // city:indexdata ? indexdata.city : CityData,
            // postalcode: indexdata ? indexdata.postalcode : PincodeData,
            nationality: "Indian",
            aadhar: indexdata
              ? indexdata.aadhar
              : !aadhar || aadhar === "null"
              ? ""
              : aadhar,
            pan: indexdata
              ? indexdata.pan
              : !panNumber || panNumber === "null"
              ? ""
              : panNumber,
            // idproof: "",
            phone: mobileno,
            email: email,
            cspcode: cspcode,
            // referred_by:indexdata?indexdata.referred_by:"",
            bankname: indexdata ? indexdata.bankname : "",
            branch: indexdata ? indexdata.branch : "",
            resaddress: address1 + " " + address2 + " " + -pincode,
            residingyear: indexdata ? indexdata.residingyear : "",
            cspaddress: indexdata ? indexdata.cspaddress : "",
            cspyear: indexdata ? indexdata.cspyear : "",
            cspcenter: indexdata ? indexdata.cspcenter : "",
            annualincome: indexdata ? indexdata.annualincome : "",
            // proofofincome: "",
            // image: "",
            resaddresstype: indexdata ? indexdata.resaddresstype : "",
            otp: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name of Applicant is required"),
            fatherspouse: Yup.string().required(
              "Father and Spouse name is required"
            ),
            dob: Yup.date()
              .nullable()
              .required("Dob is required")
              .max(
                eighteen_years_ago,
                "You must be at least 15 years old to register"
              ),
            gender: Yup.string().required("gender is required"),
            martailstatus: Yup.string().required("Martial status is required"),
            // statecode: Yup.string().required("state code is required"),
            // postalcode: Yup.string().required("postal code is required"),
            // city: Yup.string().required("city is required"),
            nationality: Yup.string().required(
              "Nationality status is required"
            ),
            // idproof: Yup.string().required("ID Proof is required"),
            aadhar: Yup.string()
              .matches(/^[0-9]{12}$/, "Invalid Aadhar Card format")
              .required("Aadhar is required"),
            pan: Yup.string()
              .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Card format")
              .required("PAN is required"),
            phone: Yup.string("Must be a number")
              .min(10, "Phone number must have min 10 digit ")
              .max(10, "Phone number must have max 10 digit")
              .required("Phone number is required"),
            email: Yup.string()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                "Please enter a valid email address"
              )
              .email("Please enter a valid email address")
              .required("Email is required"),
            otp: Yup.string()
              .matches(/^[0-9]{1,6}$/, "Invalid Otp format")
              .min(6, "Otp must have 6 digit"),
            cspcode: Yup.string().required("CSP Code is required"),
            // reffe
            bankname: Yup.string().required("Bank Name is required"),
            branch: Yup.string().required("Branch Name is required"),
            resaddress: Yup.string().required("Residental Address is required"),
            resaddresstype: Yup.string().required(
              "Residental Type is required"
            ),
            residingyear: Yup.string().required(
              "No of years at residing is reqiured"
            ),
            cspaddress: Yup.string().required("CSP Centre Address is required"),
            cspyear: Yup.string().required(
              "No. of years of CSP Centre is reqiured"
            ),
            cspcenter: Yup.string().required("CSP Center is required"),
            annualincome: Yup.string().required("Annual Income is reqiured"),
            // image: Yup
            // .mixed()
            // .required("Image is required")
            // .test("is-valid-type", "Not a valid image type",
            //   value => isValidFileType(value && value.name.toLowerCase(), "image"))
            // .test("is-valid-size", "Max allowed size is 100KB",
            //   value => value && value.size <= MAX_FILE_SIZE)
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values) {
                setStatus({ success: true });
                setSubmitting(false);
                // indexdetails(values)
                Navigate("/existingdetails", {
                  state: {
                    Indexvalues: values,
                    id: IdUpload,
                    image: ImageUpload,
                    aadhar: AadharUpload,
                    pan: PanUpload,
                    poi: PoiUpload,
                    detailvalues: detailvalues,
                    borrowerdetails: borrowerdetails,
                    datecheque1: cheque1,
                    datecheque2: cheque2,
                  },
                });
                // //console.log("values", values)
              }
            } catch (err) {
              console.error(err);
              if (values) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                // indexdetails(values)
                // Navigate("/existingdetails", {
                //   state: { Indexvalues: values },
                // });
                // //console.log("values", values);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            isValid,
            setFieldError,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Stack direction="row">
                {/* email verify modal start */}
                <Dialog open={EmailVerifyopen} onClose={handleEmailVerifyClose}>
                  <DialogTitle>
                    Please Enter OTP to sended your Mail
                  </DialogTitle>
                  <DialogContent>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.otp && errors.otp)}
                      sx={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <TextField
                        error={errors.otp && touched.otp}
                        id="outlined-error"
                        type="text"
                        size="large"
                        value={values.otp}
                        name="otp"
                        label="Otp&#42;"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        // inputProps={{}}
                      />
                      {Boolean(touched.otp && errors.otp) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-otp-register"
                        >
                          {errors.otp}
                        </FormHelperText>
                      )}
                      <>
                        {remainingTime > 0 || EmailSuccessOtp ? (
                          <Typography textAlign="center" mt={2}>
                            Time Remaining: {remainingTime} seconds
                          </Typography>
                        ) : (
                          <Typography textAlign="center" mt={2}>
                            Didn't receive the OTP? Resend.
                          </Typography>
                        )}
                        <Button
                          disabled={remainingTime > 0}
                          width="50%"
                          onClick={() => handleEmailVerifyOpen(values.email)}
                        >
                          Resend OTP
                        </Button>
                      </>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      disabled={values.otp.length < 6 || values.otp.length > 6}
                      onClick={() =>
                        handleEmailVerifyClose(values.email, values.otp)
                      }
                    >
                      submit
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* email verify modal end */}

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={5} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.aadhar && errors.aadhar)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-aadhar-register">
                          Aadhaar Card Number
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Aadhaar Card Number"
                          id="outlined-adornment-aadhar-register"
                          type="text"
                          value={values.aadhar}
                          disabled={DisableAadhaarButton}
                          name="aadhar"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.aadhar && errors.aadhar && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-aadhar-register"
                          >
                            {errors.aadhar}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <LoadingButton
                        loading={IsLoading}
                        variant="contained"
                        target="_blank"
                        onClick={() => {
                          if (!DisableAadhaarButton) {
                            handleVerifyAadhaar(values.aadhar);
                          }
                        }}
                        color={!DisableAadhaarButton ? "secondary" : "success"}
                        size="small"
                        disabled={
                          values.aadhar.length < 12 ||
                          values.aadhar.length > 12 ||
                          errors.aadhar ||
                          isSubmitting
                        }
                        // disabled={true}
                      >
                        {!DisableAadhaarButton ? "Verify" : "Verified"}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={5} mb={0}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.pan && errors.pan)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-pan-register">
                          Pan Card Number
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Pan Card Number"
                          id="outlined-adornment-full_name-register"
                          type="text"
                          value={values.pan}
                          disabled={DisableButton}
                          name="pan"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.pan && errors.pan && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-pan-register"
                          >
                            {errors.pan}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Grid container>
                        <Grid item>
                          <LoadingButton
                            variant="contained"
                            loading={IsLoadingPan}
                            // loadingPosition="start"
                            start
                            disabled={
                              values.pan.length < 10 ||
                              values.pan.length > 10 ||
                              errors.pan ||
                              isSubmitting
                            }
                            // disabled={true}
                            color={!DisableButton ? "secondary" : "success"}
                            onClick={() => {
                              if (!DisableButton) {
                                handlePanVerify(
                                  values.pan,
                                  values.dob,
                                  errors.dob,
                                  values.name,
                                  setFieldError
                                );
                              }
                            }}
                            size="small"
                          >
                            {!DisableButton ? "Verify" : "Verified"}
                          </LoadingButton>
                        </Grid>
                        <Grid item ml={2}>
                          <Box
                            width="100%"
                            sx={{ visibility: PanWait ? "visible" : "hidden" }}
                          >
                            Wait few minutes for Verify your PAN
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-full_name-register">
                          Name of Applicant
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Name of Applicant"
                          id="outlined-adornment-full_name-register"
                          type="text"
                          value={values.name}
                          disabled={username === !null ? true : false}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.name && errors.name && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-name-register"
                          >
                            {errors.name}
                          </FormHelperText>
                        )}
                        <Typography variant="body2" color="primary">
                          Note: Name should be as per aadhar card name
                        </Typography>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.fatherspouse && errors.fatherspouse
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-full_name-register">
                          Father&apos;s/Spouse&apos;s Name
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Father's/Spouse's Name"
                          id="outlined-adornment-full_name-register"
                          type="text"
                          value={values.fatherspouse}
                          name="fatherspouse"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.fatherspouse && errors.fatherspouse && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-fatherspouse-register"
                          >
                            {errors.fatherspouse}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.dob && errors.dob)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-dob-register">
                          Date of Birth&#42;
                        </InputLabel>
                        <OutlinedInput
                          label="Date of Birth&#42;"
                          id="outlined-adornment-dob-register"
                          type="date"
                          value={values.dob}
                          name="dob"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{}}
                          inputProps={{}}
                        />
                        {touched.dob && errors.dob && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-dob-register"
                          >
                            {errors.dob}
                          </FormHelperText>
                        )}
                      </FormControl>

                      {/* <FormControl
                        fullWidth
                        error={Boolean(touched.dob && errors.dob)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-full_dob-register">
                          Date of Birth
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Date of Birth&#42;"
                          id="outlined-adornment-full_name-register"
                          type="text"
                          value={values.dob}
                          disabled={
                            formattedDateString === !null ? true : false
                          }
                          name="dob"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.dob && errors.dob && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-dob-register"
                          >
                            {errors.dob}
                          </FormHelperText>
                        )}
                      </FormControl> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.gender && errors.gender)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-gender-register">
                          Gender<span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.gender}
                          label="Gender"
                          name="gender"
                          onChange={handleChange}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                        {touched.gender && errors.gender && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-gender-register"
                          >
                            {errors.gender}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.martailstatus && errors.martailstatus
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          Marital Status
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          value={values.martailstatus}
                          name="martailstatus"
                          onChange={handleChange}
                          label="Martail Status"
                          input={<OutlinedInput label="Martail Status" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="Married">Married</MenuItem>
                          <MenuItem value="UnMarried">UnMarried</MenuItem>
                        </Select>
                        {touched.martailstatus && errors.martailstatus && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-martailstatus-register"
                          >
                            {errors.martailstatus}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.nationality && errors.nationality
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-nationality-register">
                          Nationality
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-nationality-register"
                          label="Nationality"
                          type="text"
                          value={values.nationality}
                          name="nationality"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{}}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                        {touched.nationality && errors.nationality && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-nationality-register"
                          >
                            {errors.nationality}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.statecode && errors.statecode
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          State Code<span style={{color:"#DB2F2F"}}>&#42;</span>
                        </InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          value={values.statecode}
                          name="statecode"
                          onChange={handleChange}
                          label="state code"
                          input={<OutlinedInput label="Martail Status" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          <MenuItem value="AN">Andaman & Nicobar Islands</MenuItem>
                          <MenuItem value="AP">Andhra Pradesh</MenuItem>
                          <MenuItem value="AR">Arunachal Pradesh</MenuItem>
                          <MenuItem value="AS">Assam</MenuItem>
                          <MenuItem value="BR">Bihar</MenuItem>
                          <MenuItem value="CG">Chattisgarh</MenuItem>
                          <MenuItem value="CH">Chandigarh</MenuItem>
                          <MenuItem value="DD">Daman & Diu</MenuItem>
                          <MenuItem value="DL">Delhi</MenuItem>
                          <MenuItem value="DN">Dadra & Nagar Haveli</MenuItem>
                          <MenuItem value="GA">Goa</MenuItem>
                          <MenuItem value="GJ">Gujarat</MenuItem>
                          <MenuItem value="HP"> Himachal Pradesh</MenuItem>
                          <MenuItem value="HR">Haryana</MenuItem>
                          <MenuItem value="JH">Jharkhand</MenuItem>
                          <MenuItem value="JK">Jammu & Kashmir</MenuItem>
                          <MenuItem value="KA">Karnataka</MenuItem>
                          <MenuItem value="KL">Kerala</MenuItem>
                          <MenuItem value="LD">Lakshadweep</MenuItem>
                          <MenuItem value="MH">Maharashtra</MenuItem>
                          <MenuItem value="ML">Meghalaya</MenuItem>
                          <MenuItem value="MN">Manipur</MenuItem>
                          <MenuItem value="MP">Madhya Pradesh</MenuItem>
                          <MenuItem value="MZ">Mizoram</MenuItem>
                          <MenuItem value="NL">Nagaland</MenuItem>
                          <MenuItem value="OR">Orissa</MenuItem>
                          <MenuItem value="PB">Punjab</MenuItem>
                          <MenuItem value="PY">Pondicherry/Puducherry</MenuItem>
                          <MenuItem value="RJ">Rajasthan</MenuItem>
                          <MenuItem value="SK">Sikkim</MenuItem>
                          <MenuItem value="TG">Telangana</MenuItem>
                          <MenuItem value="TN">Tamil Nadu</MenuItem>
                          <MenuItem value="TR">Tripura</MenuItem>
                          <MenuItem value="UL">Uttaranchal/Uttarakhand</MenuItem>
                          <MenuItem value="UP">Uttar Pradesh</MenuItem>
                          <MenuItem value="WB">West Bengal</MenuItem>
                        </Select>
                        {touched.statecode && errors.statecode && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-statecode-register"
                          >
                            {errors.statecode}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.postalcode && errors.postalcode)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-postalcode-register">
                          Postal Code<span style={{color:"#DB2F2F"}}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Postal Code"
                          id="outlined-adornment-postalcode-register"
                          type="postalcode"
                          value={PincodeData}
                          name="postalcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                        {touched.postalcode && errors.postalcode && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-postalcode-register"
                          >
                            {errors.postalcode}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.city && errors.city)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-city-register">
                          City<span style={{color:"#DB2F2F"}}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label=" City"
                          id="outlined-adornment-city-register"
                          type="city"
                          value={CityData}
                          name="city"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                        {touched.city && errors.city && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-city-register"
                          >
                            {errors.city}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                      <Typography variant="subtitle1">
                        Aadhaar Card
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.idproof && errors.idproof)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-idproof-register">
                        Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;
                      </InputLabel> */}
                        <OutlinedInput
                          // label="Passport &#47;Voter ID &#47;Driving Licence &#47;Utility Bill No.&#42;"
                          id="outlined-adornment-idproof-register"
                          type="text"
                          required
                          value={AadharUpload ? AadharUpload.name : ""}
                          name="AadharImage"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            AadharUpload?
                            <CancelIcon sx={{ cursor: 'pointer', color:"#000" }} onClick={handleImageClose} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handleAadharUpload}
                              />
                            </Button>

                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.idproof && errors.idproof && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-idproof-register"
                          >
                            {errors.idproof}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                      <Typography variant="subtitle1">
                        Pan Card<span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.idproof && errors.idproof)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-idproof-register">
                        Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;
                      </InputLabel> */}
                        <OutlinedInput
                          // label="Passport &#47;Voter ID &#47;Driving Licence &#47;Utility Bill No.&#42;"
                          id="outlined-adornment-idproof-register"
                          type="text"
                          // value={PanUpload?PanUpload.name:''}
                          value={PanUpload ? PanUpload.name : ""}
                          name="PanImage"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            PanUpload?
                            <CancelIcon sx={{ cursor: 'pointer', color:"#000" }} onClick={handlePanClose} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handlePanUpload}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.idproof && errors.idproof && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-idproof-register"
                          >
                            {errors.idproof}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                      <Typography variant="subtitle1">
                        Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No<span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.idproof && errors.idproof)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-idproof-register">
                        Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;
                      </InputLabel> */}
                        <OutlinedInput
                          // label="Passport &#47;Voter ID &#47;Driving Licence &#47;Utility Bill No.&#42;"
                          id="outlined-adornment-idproof-register"
                          type="text"
                          // value={IdUpload?IdUpload.name:''}
                          value={IdUpload ? IdUpload.name : ""}
                          name="idproof"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            IdUpload?
                            <CancelIcon sx={{ cursor: 'pointer',color:"#000" }} onClick={handleIDClose} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handleImageUpload}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.idproof && errors.idproof && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-idproof-register"
                          >
                            {errors.idproof}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      mb={2}
                      sx={{ textAlign: "center" }}
                    >
                      <Typography variant="subtitle1" sx={{ float: "left" }}>
                        Photo Upload
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      {/* <Box>
                          <Button
                          fullWidth
                            disableElevation
                            size="small"
                            variant="outlined"
                            onClick={handleClickOpen}
                            sx={{ color: '#A556B2', height:"50px", borderColor: '#A556B2', '&:hover': { borderColor: '#A556B2' } }}
                          >
                            Image upload
                            <input
                            type="file"
                            hidden
                            />
                          </Button>
                      </Box> */}
                      {/* <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="outlined"
                        fullWidth
                        component="span"
                        sx={{
                          color: "#A556B2",
                          height: "50px",
                          borderColor: "#A556B2",
                          "&:hover": { borderColor: "#A556B2" },
                        }}
                      >
                        Image Upload
                      </Button>
                    </label> */}
                      <FormControl
                        fullWidth
                        error={Boolean(touched.image && errors.image)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-image-register">
                        Image&#42;
                      </InputLabel> */}
                        <OutlinedInput
                          // label="Image&#42;"
                          id="outlined-adornment-fname-register"
                          type="text"
                          // value={ImageUpload?ImageUpload.name:''}
                          value={ImageUpload ? ImageUpload.name : ""}
                          disabled={true}
                          endAdornment={
                            ImageUpload?
                            <CancelIcon sx={{ cursor: 'pointer' }} onClick={handleImageUploadClose} />:
                            <Button
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "335px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Photo Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handleFileChange}
                              />
                            </Button>
                          }
                          name="image"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.image && errors.image && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-image-register"
                          >
                            {errors.image}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.phone && errors.phone)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-phone-register">
                          Contact No
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Contact No"
                          id="outlined-adornment-phone-register"
                          type="text"
                          value={values.phone}
                          name="phone"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: false,
                          }}
                        />
                        {touched.phone && errors.phone && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-phone-register"
                          >
                            {errors.phone}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-email-register">
                          Email<span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Email"
                          id="outlined-adornment-email-register"
                          type="email"
                          value={values.email}
                          // disabled={DisableEmail}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: false,
                          }}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-register"
                          >
                            {errors.email}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <LoadingButton
                        variant="contained"
                        color={!DisableEmail ? "secondary" : "success"}
                        loading={IsLoadingEmail}
                        onClick={() => {
                          if (!DisableEmail) {
                            handleEmailVerifyOpen(values.email);
                          }
                        }}
                        // onClick={()=>handleEmailVerifyOpen(values.email)}
                        size="small"
                      >
                        {!DisableEmail ? "Verify your Email" : "Verified Email"}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.cspcode && errors.cspcode)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-cspcode-register">
                          CSP Code
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="CSP Code"
                          id="outlined-adornment-cspcode-register"
                          type="cspcode"
                          value={values.cspcode}
                          name="cspcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: false,
                          }}
                        />
                        {touched.cspcode && errors.cspcode && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-cspcode-register"
                          >
                            {errors.cspcode}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.bankname && errors.bankname)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-lname-register">
                          CSP with Bank Name
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <Select
                          id="select"
                          value={values.bankname}
                          defaultValue="SBI"
                          name="bankname"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          label="CSP with Bank Name"
                          input={<OutlinedInput label="CSP with Bank Name" />}
                          sx={{
                            height: "62px",
                            padding: "15.5px 14px 0px 0px !important",
                          }}
                        >
                          {bankdetails.map((data) => (
                            <MenuItem value={data} key={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.bankname && errors.bankname && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-bankname-register"
                          >
                            {errors.bankname}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.branch && errors.branch)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-branch-register">
                          CSP with Branch Name
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="CSP with Branch Name"
                          id="outlined-adornment-branch-register"
                          type="text"
                          value={values.branch}
                          name="branch"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.branch && errors.branch && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-branch-register"
                          >
                            {errors.branch}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} xl={8} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.resaddress && errors.resaddress)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-resaddress-register">
                          Residential Address
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Residential Address"
                          id="outlined-adornment-resaddress-register"
                          fullWidth
                          type="text"
                          value={values.resaddress}
                          name="resaddress"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.resaddress && errors.resaddress && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-resaddress-regresaddressister"
                          >
                            {errors.resaddress}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={2}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(
                          touched.resaddresstype && errors.resaddresstype
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          Residential Address
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                          value={values.resaddresstype}
                        >
                          <FormControlLabel
                            value="Owned"
                            control={
                              <Radio
                                checked={values.resaddresstype === "Owned"}
                                disabled={isSubmitting}
                              />
                            }
                            label="Owned"
                            onChange={handleChange}
                            name="resaddresstype"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="Rented"
                            control={
                              <Radio
                                checked={values.resaddresstype === "Rented"}
                                disabled={isSubmitting}
                              />
                            }
                            label="Rented"
                            onChange={handleChange}
                            name="resaddresstype"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.resaddresstype && errors.resaddresstype && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-resaddresstype-resaddresstype"
                          >
                            {errors.resaddresstype}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} xl={4} mb={3}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.residingyear && errors.residingyear
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-residingyear-register">
                          No of years at residing at above residence
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="No of years at residing at above residence"
                          id="outlined-adornment-residingyear-register"
                          fullWidth
                          type="text"
                          value={values.residingyear}
                          name="residingyear"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.residingyear && errors.residingyear && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-residingyear-residingyear"
                          >
                            {errors.residingyear}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={8} mb={3}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.cspaddress && errors.cspaddress)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-cspaddress-register">
                          CSP Centre Address
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="CSP Centre Address"
                          id="outlined-adornment-cspaddress-register"
                          fullWidth
                          type="text"
                          value={values.cspaddress}
                          name="cspaddress"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.cspaddress && errors.cspaddress && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-cspaddress-regcspaddressister"
                          >
                            {errors.cspaddress}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} xl={4} mb={2}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.cspyear && errors.cspyear)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-cspyear-register">
                          No. of years of CSP Centre
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="No. of years of CSP Centre"
                          id="outlined-adornment-cspyear-register"
                          fullWidth
                          type="text"
                          value={values.cspyear}
                          name="cspyear"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        {touched.cspyear && errors.cspyear && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-cspyear-cspyear"
                          >
                            {errors.cspyear}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={3} mb={2}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(touched.cspcenter && errors.cspcenter)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <FormLabel component="legend">
                          CSP Centre
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </FormLabel>
                        {/* <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value={values.cspcenter.owned}
                          control={<Checkbox />}
                          onChange={handleChange}
                          label="Owned"
                          name="cspcenter.owned"
                          labelPlacement="Owned"
                        />
                        <FormControlLabel
                          value={values.cspcenter.rented}
                          control={<Checkbox />}
                          onChange={handleChange}
                          label="Rented"
                          labelPlacement="Rented"
                        />
                      </FormGroup> */}
                        <Field
                          component={RadioGroup}
                          name="activity"
                          style={{ display: "flex", flexDirection: "row" }}
                          value={values.cspcenter}
                        >
                          <FormControlLabel
                            value="Owned"
                            control={
                              <Radio
                                checked={values.cspcenter === "Owned"}
                                disabled={isSubmitting}
                              />
                            }
                            label="Owned"
                            onChange={handleChange}
                            name="cspcenter"
                            disabled={isSubmitting}
                          />
                          <FormControlLabel
                            value="Rented"
                            control={
                              <Radio
                                checked={values.cspcenter === "Rented"}
                                disabled={isSubmitting}
                              />
                            }
                            label="Rented"
                            onChange={handleChange}
                            name="cspcenter"
                            disabled={isSubmitting}
                          />
                        </Field>
                        {touched.cspcenter && errors.cspcenter && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-cspcenter-cspcenter"
                          >
                            {errors.cspcenter}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={5}
                      mb={2}
                      mt={3.5}
                    >
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.annualincome && errors.annualincome
                        )}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-annualincome-register">
                          Annual Income
                          <span style={{ color: "#DB2F2F" }}>&#42;</span>
                        </InputLabel>
                        <OutlinedInput
                          label="Annual Income"
                          id="outlined-start-adornment"
                          fullWidth
                          type="text"
                          value={values.annualincome}
                          name="annualincome"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.annualincome && errors.annualincome && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-annualincome-annualincome"
                          >
                            {errors.annualincome}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                      <Typography variant="subtitle1">
                        Proof of Income
                        <span style={{ color: "#DB2F2F" }}>&#42;</span>
                      </Typography>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.idproof && errors.idproof)}
                        sx={{ mb: "10px", ...theme.typography.customInput }}
                      >
                        {/* <InputLabel htmlFor="outlined-adornment-idproof-register">
                        Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;
                      </InputLabel> */}
                        <OutlinedInput
                          // label="Passport &#47;Voter ID &#47;Driving Licence &#47;Utility Bill No.&#42;"
                          id="outlined-adornment-idproof-register"
                          type="text"
                          // value={PoiUpload?PoiUpload.name:''}
                          value={
                            PoiUpload
                              ? PoiUpload.name
                              : userproof
                              ? userproof.name
                              : ""
                          }
                          name="proofofincome"
                          onBlur={handleBlur}
                          disabled={true}
                          endAdornment={
                            <Button
                              component="label"
                              sx={{
                                fontSize: "14px",
                                width: "235px",
                                height: "40px",
                                backgroundColor: "#A556B2",
                                "&:hover": { backgroundColor: "#A556B2" },
                              }}
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                              <VisuallyHiddenInput
                                type="file"
                                name=""
                                onChange={handlePoiUpload}
                              />
                            </Button>
                          }
                          onChange={handleChange}
                          inputProps={{}}
                        />
                        <Typography variant="body2" color="primary">
                          Note: Please upload an image in a format such as jpg,
                          jpeg, or png.
                        </Typography>
                        {touched.idproof && errors.idproof && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-idproof-register"
                          >
                            {errors.idproof}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* <Divider sx={{mb:"10px"}}/> */}

                  {/*  */}
                  {/* <LoanDetails /> */}
                  {/*  */}
                  {/* <Divider sx={{mb:"10px"}}/> */}
                  {/*  */}
                  {/* <BankDetails/> */}
                  {/*  */}
                  {/* <Divider sx={{mb:"10px"}}/> */}
                  {/*  */}
                  {/* <AloanDetails /> */}
                  {/*  */}
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Stack>
              <Grid
                container
                spacing={4}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Grid item sm={6} xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !PanUpload ||
                        !AadharUpload ||
                        !ImageUpload ||
                        !PoiUpload ||
                        !DisableButton ||
                        !DisableEmail ||
                        !DisableAadhaarButton ||
                        !IdUpload
                      }
                      // || !DisableAadhaarButton || !IdUpload
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#A556B2",
                        color: "#fff",
                        "&:hover": { backgroundColor: "#A556B2" },
                      }}
                      // onClick={() => {
                      //   //console.log("values", values);
                      //   Navigate("/existingdetails", {
                      //     state: {
                      //       Indexvalues: values,
                      //       id:IdUpload,
                      //       image:ImageUpload,
                      //       aadhar:AadharUpload,
                      //       pan:PanUpload,
                      //       poi:PoiUpload
                      //     },
                      //   });
                      // }}
                    >
                      next
                    </Button>
                    {/* <Typography></Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    );
  } else window.location.href = "/";
};

export default Index;
