import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  FormControl,
  Grid,
  Typography,
  OutlinedInput,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  FormLabel,
} from "@mui/material";
// import { Field } from "formik";

const Index = (props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const values = props.indexdata;
  // //console.log("indexname", values);

  return (
    <>
      <form noValidate>
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography sx={{ color: "#121926" }} gutterBottom variant="h6">
              CSP Details
            </Typography>
          </Stack>
        </Grid>
        <Stack direction="column">
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.name && errors.name)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-full_name-register">
                    Name of Applicant&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Name of Applicant&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.name}
                    name="name"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.name && errors.name && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-name-register"
                        >
                          {errors.name}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.fatherspouse && errors.fatherspouse
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-full_name-register">
                    Father&apos;s/Spouse&apos;s Name&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Father's/Spouse's Name&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.fatherspouse}
                    name="fatherspouse"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.fatherspouse && errors.fatherspouse && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-fatherspouse-register"
                        >
                          {errors.fatherspouse}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.dob && errors.dob)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-dob-register">
                    Date of Birth&#42;
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-dob-register"
                    type="date"
                    label="Date of Birth"
                    value={values.dob}
                    name="dob"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.dob && errors.dob && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-dob-register"
                        >
                          {errors.dob}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.gender && errors.gender)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-lname-register">
                    Gender&#42;
                  </InputLabel>

                  <OutlinedInput
                    label="Gender&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.gender}
                    name="fatherspouse"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />

                  {/* {touched.gender && errors.gender && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-gender-register"
                        >
                          {errors.gender}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.martailstatus && errors.martailstatus
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-lname-register">
                    Martail Status&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Martail Status"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.martailstatus}
                    name="fatherspouse"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.martailstatus && errors.martailstatus && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-martailstatus-register"
                        >
                          {errors.martailstatus}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.nationality && errors.nationality)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-lname-register">
                    Nationality&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Nationality&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.nationality}
                    name="fatherspouse"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.nationality && errors.nationality && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-nationality-register"
                        >
                          {errors.nationality}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={0}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.aadhar && errors.aadhar)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-aadhar-register">
                    Aadhaar Card Number&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Aadhaar Card Number&#42;"
                    id="outlined-adornment-aadhar-register"
                    type="text"
                    value={values.aadhar}
                    name="aadhar"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.aadhar && errors.aadhar && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-aadhar-register"
                        >
                          {errors.aadhar}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={0}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.pan && errors.pan)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-pan-register">
                    Pan Card Number&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Pan Card Number&#42;"
                    id="outlined-adornment-full_name-register"
                    type="text"
                    value={values.pan}
                    name="pan"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.pan && errors.pan && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-pan-register"
                        >
                          {errors.pan}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.phone && errors.phone)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-phone-register">
                    Contact No&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Contact No&#42;"
                    id="outlined-adornment-phone-register"
                    type="text"
                    value={values.phone}
                    name="phone"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.phone && errors.phone && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-phone-register"
                        >
                          {errors.phone}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.email && errors.email)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-register">
                    Email&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Email&#42;"
                    id="outlined-adornment-email-register"
                    type="email"
                    value={values.email}
                    name="email"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-register"
                        >
                          {errors.email}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspcode && errors.cspcode)}
                  //   sx={{ mb: "10px", ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspcode-register">
                    CSP Code&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="CSP Code&#42;"
                    id="outlined-adornment-cspcode-register"
                    type="cspcode"
                    value={values.cspcode}
                    name="cspcode"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.cspcode && errors.cspcode && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspcode-register"
                        >
                          {errors.cspcode}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.bankname && errors.bankname)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-lname-register">
                    Bank Name&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Bank Name&#42;"
                    id="outlined-adornment-cspcode-register"
                    type="cspcode"
                    value={values.bankname}
                    name="cspcode"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.bankname && errors.bankname && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-bankname-register"
                        >
                          {errors.bankname}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.branch && errors.branch)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-branch-register">
                    Branch Name&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Branch Name&#42;"
                    id="outlined-adornment-branch-register"
                    type="text"
                    value={values.branch}
                    name="branch"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.branch && errors.branch && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-branch-register"
                        >
                          {errors.branch}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.resaddress && errors.resaddress)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-resaddress-register">
                    Residential Address&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Residential Address&#42;"
                    id="outlined-adornment-resaddress-register"
                    fullWidth
                    type="text"
                    value={values.resaddress}
                    name="resaddress"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.resaddress && errors.resaddress && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-resaddress-regresaddressister"
                        >
                          {errors.resaddress}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.resaddress && errors.resaddress)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-resaddress-register">
                    Residential Address Type&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Residential Address Type&#42;"
                    id="outlined-adornment-resaddress-register"
                    fullWidth
                    type="text"
                    value={values.resaddresstype}
                    name="resaddress"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.resaddress && errors.resaddress && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-resaddress-regresaddressister"
                        >
                          {errors.resaddress}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={5} mb={3}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.residingyear && errors.residingyear
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-residingyear-register">
                    No of years at residing at above residence&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="No of years at residing at above residence&#42;"
                    id="outlined-adornment-residingyear-register"
                    fullWidth
                    type="text"
                    value={values.residingyear}
                    name="residingyear"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.residingyear && errors.residingyear && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-residingyear-residingyear"
                        >
                          {errors.residingyear}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={3}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspaddress && errors.cspaddress)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspaddress-register">
                    CSP Centre Address&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="CSP Centre Address&#42;"
                    id="outlined-adornment-cspaddress-register"
                    fullWidth
                    type="text"
                    value={values.cspaddress}
                    name="cspaddress"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    inputProps={{}}
                  />
                  {/* {touched.cspaddress && errors.cspaddress && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspaddress-regcspaddressister"
                        >
                          {errors.cspaddress}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspyear && errors.cspyear)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspyear-register">
                    No. of years of CSP Centre&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="No. of years of CSP Centre&#42;"
                    id="outlined-adornment-cspyear-register"
                    fullWidth
                    type="text"
                    value={values.cspyear}
                    name="cspyear"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.cspyear && errors.cspyear && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspyear-cspyear"
                        >
                          {errors.cspyear}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(touched.cspyear && errors.cspyear)}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-cspyear-register">
                    CSP Centre&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="CSP Centre&#42;"
                    id="outlined-adornment-cspyear-register"
                    fullWidth
                    type="text"
                    value={values.cspyear}
                    name="cspyear"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  />
                  {/* {touched.cspyear && errors.cspyear && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-cspyear-cspyear"
                        >
                          {errors.cspyear}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={2} mb={2}>
                <FormControl
                  fullWidth
                  //   error={Boolean(
                  //     touched.annualincome && errors.annualincome
                  //   )}
                  //   sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-annualincome-register">
                    Annual Income&#42;
                  </InputLabel>
                  <OutlinedInput
                    label="Annual Income&#42;"
                    id="outlined-start-adornment"
                    fullWidth
                    type="text"
                    value={values.annualincome}
                    name="annualincome"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    // inputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">kg</InputAdornment>
                    //   ),
                    // }}
                  />
                  {/* {touched.annualincome && errors.annualincome && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-annualincome-annualincome"
                        >
                          {errors.annualincome}
                        </FormHelperText>
                      )} */}
                </FormControl>
              </Grid>
            </Grid>

            {/* <Grid container spacing={2}> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} mb={2}>
                  <Typography variant="subtitle1">Passport &#47;Voter ID &#47;Driving Licence &#47;Utility
                        Bill No.&#42;</Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.idproof && errors.idproof)}
                      sx={{ mb: "10px", ...theme.typography.customInput }}
                    >

                      <OutlinedInput
                        id="outlined-adornment-idproof-register"
                        type="text"
                        value={values.idproof}
                        name="idproof"
                        onBlur={handleBlur}
                        disabled={true}
                        endAdornment={<Button
                          component="label"
                          sx={{
                            fontSize: "13px",
                            width: "235px",
                            height: "40px",
                            backgroundColor: "#A556B2",
                            "&:hover": { backgroundColor: "#A556B2" },
                          }}
                          variant="contained"
                        >
                          Upload
                          <VisuallyHiddenInput
                            type="file"
                            name=""
                            onChange={handleImageUpload}
                          />
                        </Button>}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.idproof && errors.idproof && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-idproof-register"
                        >
                          {errors.idproof}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} mb={2} sx={{ textAlign: "center" }}
                  >
                  <Typography variant="subtitle1" sx={{float:"left"}}>Image Upload&#42;</Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.image && errors.image)}
                      sx={{ ...theme.typography.customInput }}
                    >

                      <OutlinedInput
                        id="outlined-adornment-fname-register"
                        type="text"
                        value={ImageUpload}
                        disabled={true}
                        endAdornment={
                          <Button
                            component="label"
                            sx={{
                              fontSize: "13px",
                              width: "235px",
                              height: "40px",
                              backgroundColor: "#A556B2",
                              "&:hover": { backgroundColor: "#A556B2" },
                            }}
                            variant="contained"
                          >
                            Image Upload
                            <VisuallyHiddenInput
                              type="file"
                              name=""
                              onChange={handleFileChange}
                            />
                          </Button>
                        }
                        name="image"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.image && errors.image && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-image-register"
                        >
                          {errors.image}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
            {/* </Grid> */}
            {/* {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )} */}
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default Index;
