import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import {
  Box,
  ButtonGroup,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import LoanApplicationFormUser from "./LoanApplication";
import { GetRequestByToken } from "../../../Axios/Approval/api";
import ApplicationStatus from "./ApplicationStatus";

const buttons = [
  // <Button key="two">Two</Button>,
  // <Button key="three">Three</Button>,
];

const Index = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);
  const [Selected, setSelected] = useState(null);
  const Token = sessionStorage.getItem("user_token");

  useEffect(() => {
    GetRequestByToken(Token, setData, setIsLoading);
  }, []);

  const handleSelect = (index, item) => {
    if (data) {
      setSelected(data[index]);
      setActive(item);
    }
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <>
      <Header />

      {Selected ? (
        <ApplicationStatus
          data={data}
          setSelected={setSelected}
          active={active}
        />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              "& > *": {
                mt: 5,
                mr: 5,
              },
            }}
          >
            <ButtonGroup
              size="large"
              color="secondary"
              aria-label="Small button group"
              orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
            >
              <Button key="one" onClick={() => handleSelect(0, 1)}>
                Application Status
              </Button>
              ,
              <Button key="one" onClick={() => handleSelect(0, 2)}>
                Loan Sanction & Agreement Status
              </Button>
              ,
            </ButtonGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              "& > *": {
                mt: 3,
                mb: 3,
              },
            }}
          >
            <ButtonGroup
              size="small"
              variant="contained"
              aria-label="Small button group"
            >
              <Button key="one" color="success">
                Application
              </Button>
              <Button key="one" color="error">
                Loan Sanction & Agreement
              </Button>
            </ButtonGroup>
          </Box>
          <Typography textAlign={"center"} m={2}>
            Sign your loan sanction document and afterward sign the loan
            agreement to finalize your financial journey with us!
          </Typography>
          <LoanApplicationFormUser data={data} />
        </>
      )}
    </>
  );
};

export default Index;
