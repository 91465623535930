import React, { useState } from "react";
import {
  Button,
  Container,
  CircularProgress,
  Typography,
  Backdrop,
  Box,
  Alert,
  Snackbar,
  styled,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import { UploadExcelDocumentsApi } from "../../Axios/Approval/api";

const MAX_FILE_SIZE_MB = 50;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const NoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      color: "#555",
    }}
  >
    <Typography variant="h6">No data found.</Typography>
  </Box>
);

const Equifax = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false); // Single loading state
  const [fileName, setFileName] = useState("");
  const [resMessage, setResMessage] = useState("");
  const [errorExcel, setErrorExcel] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [isExportEnabled, setIsExportEnabled] = useState(false);

  const validateExcelFile = (file) => {
    const isValidType = /\.(xls|xlsx)$/i.test(file.name);
    const isValidSize = file.size / 1024 / 1024 < MAX_FILE_SIZE_MB;

    if (!isValidType || !isValidSize) {
      setResMessage(
        !isValidType
          ? "Please upload a valid Excel file (.xls or .xlsx)."
          : `File size should be less than ${MAX_FILE_SIZE_MB}MB.`
      );
      setErrorExcel(true);
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  const uploadExcel = async (file) => {
    try {
      const response = await UploadExcelDocumentsApi(file);
      setApiResponse(response);
      setResMessage("Uploaded successfully!");
      setErrorExcel(false);
      setSnackbarOpen(true);
      setIsExportEnabled(true);
    } catch (error) {
      setResMessage("Failed to upload the file. Please try again.");
      setErrorExcel(true);
      setSnackbarOpen(true);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file || !validateExcelFile(file)) {
      setFileName("");
      setRows([]);
      setColumns([]);
      return;
    }

    setLoading(true); // Start loading
    setFileName(file.name);
    setApiResponse(null);

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });

        const formattedData = jsonData.map((row, index) => ({
          ...row,
          id: index + 1,
        }));

        const newColumns = Object.keys(formattedData[0] || {})
          .filter((key) => key !== "id")
          .map((key) => ({
            field: key,
            headerName: key,
            width: 150,
          }));

        setColumns(newColumns);
        setRows(formattedData);
        await uploadExcel(file); // Upload the file here
      } catch (error) {
        console.error("File upload error:", error.message);
        setResMessage("The uploaded file is invalid or empty.");
        setErrorExcel(true);
        setSnackbarOpen(true);
        setFileName("");
        setRows([]);
        setColumns([]);
      } finally {
        setLoading(false); // Ensure loading is false after processing
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExportToText = () => {
    if (!apiResponse) return;
    const textData = JSON.stringify(apiResponse, null, 2);
    const blob = new Blob([textData], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exportedData.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleClose = () => setSnackbarOpen(false);

  return (
    <>
      <Backdrop open={loading} sx={{ color: "#fff", zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={errorExcel ? "error" : "success"} variant="filled">
          {resMessage}
        </Alert>
      </Snackbar>

      <Container sx={{ padding: "20px", backgroundColor: "#f0f4f8" }}>
        <Box sx={{ maxWidth: 1000, backgroundColor: "#fff", padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <label htmlFor="upload-excel">
              <VisuallyHiddenInput id="upload-excel" type="file" accept=".xls,.xlsx" onChange={handleFileUpload} />
              <Button variant="outlined" component="span" startIcon={<UploadFileIcon />} disabled={loading}>
                {loading ? "Uploading..." : "Upload Excel"}
              </Button>
            </label>

            {fileName && <Typography variant="subtitle1">Uploaded File: {fileName}</Typography>}
            {loading && <CircularProgress />}

            <Button
              variant="outlined"
              startIcon={<TextSnippetIcon />}
              onClick={handleExportToText}
              disabled={!isExportEnabled}
            >
              Export to Text
            </Button>
          </Box>

          <Box sx={{ height: 370, marginTop: "20px" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ NoRowsOverlay }}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Equifax;
