import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  InputAdornment
} from "@mui/material";
import { Stack, height } from "@mui/system";
import React, { useState, useEffect } from "react";
import MainCard from "../MainCard";
import SubCard from "../SubCard";
import { IoMdArrowBack } from "react-icons/io";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import FieldsTable from "./FieldsTable";
import {
  GetAllRequest,
  ProvideApproval,
  UpdateApplicationStatus,
} from "../../Axios/Approval/api";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import html2pdf from "html2pdf.js";
import FILending from "../../assets/images/FILending-logo.png";
import axios from "axios";
import JSZip from "jszip";
import Paginate from "./Paginate";
import RejectDialog from "./Components/RejectDialog";
import SearchIcon from '@mui/icons-material/Search';

function PendingForm() {
  // const style={width:'100%',display:'flex',gap:'30px',flexDirection:'row'}

  const [data, setData] = useState(null);
  const [value, setValue] = React.useState(null);
  const [Selected, setSelected] = React.useState(null);
  console.log("Selected", Selected);
  const [selectedProducts, setSelectedProducts] = React.useState(null);
  const [PostData, setPostData] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [ActiveIndex, setActiveIndex] = useState(0);
  const[SliceData, setSliceData] = React.useState(null);
  const [OpenRejectDialog, setOpenRejectDialog] = React.useState(false);
  const [RejectReasons, setRejectReasons] = React.useState([]);
  const [customReject, setCustomReject] = React.useState(false);
  const [thisstatus, setStatus] = React.useState(null);
  const userType = sessionStorage.getItem("user_type");
  const user_id = sessionStorage.getItem("user_id");
  let itemsPerPage = 20;
  const [isLoading, setisLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const smStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
    flexDirection: "column",
  };

  const mdLgXlStyles = {
    width: "100%",
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",

    flexDirection: "row",
  };

  const handleRejectDialogOpen = () => {
    setOpenRejectDialog(true);
  };

  const handleRejectDialogClose = () => {
    setOpenRejectDialog(false);
  };
  //paginate

  // let itemsPerPage=10

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const startIndex = ActiveIndex  * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = items.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % items.length;
    console
      .log
      // `User requested page number ${event.selected}, which is offset ${newOffset}`
      ();
    // setItemOffset(newOffset);
  };

  // console.log("data",data.data);

  const handleSelect = (index) => {
    // console.log("active",ActiveIndex,index);
    let multiply=(ActiveIndex*20)+index
    // console.log("mul",multiply);
    setSelected(data.data[multiply]);
  };

  const handleItemClick = (index, subIndex) => {
    setValue(data[index]);
    setSelectedProducts(data[index]);
  };

  // //console.log('Selected ', selectedProducts);

  // //console.log('value ', value);

  function createData(name, value) {
    return { name, value };
  }

  const handleRejectReasonAdd = (value) => {
    setCustomReject(false);
    if (value === "others") {
      setCustomReject(true);
    } else {
      setRejectReasons([...RejectReasons, value]);
    }
  };

  const handleRejectReasonDelete = (index) => {
    setRejectReasons(RejectReasons.filter((_, i) => i !== index));
  };

  React.useEffect(() => {
    if (Selected) {
      Object.entries(Selected).forEach(([key, value]) => {
        setRows((prevRows) => [...prevRows, createData(key, value)]);
      });
      // Selected.forEach(element => {
      //     //console.log(element);
      //     setRows(prevRows => [...prevRows, createData(element)]);
      // });
    }
  }, [Selected]);

  console.log("Active index",ActiveIndex);

  // useEffect(() => {
  //     GetAllRequest(setData, setisLoading,ActiveIndex)
  // }, [])

  const handleClose = () => {
    setSelected(null);
    setRows([]);
    setSelectedProducts(null);
    handleRejectDialogClose();
    setValue(null);
    setPostData(null);
  };

  // //console.log("type ", userType);
  // //console.log("id ", user_id);

  useEffect(() => {
    if (PostData !== null)
      ProvideApproval(
        value._id,
        selectedProducts.product_id,
        user_id,
        PostData,
        setData,
        handleClose
      );
  }, [PostData]);

  useEffect(()=>{
    if(data){
      console.log("start",startIndex);
      console.log("end",endIndex);
      console.log("data",data.data);
      console.log("splice",data.data.slice(startIndex,endIndex));
      setSliceData(data.data.slice(startIndex, endIndex))
    }
  },[ActiveIndex,data])

  // const slicedData = data.slice();


  console.log("Sliced data",SliceData);


  const [UpdateStatus, setUpdateStatus] = useState(false);

  const handleApprove = () => {
    // handleClickOpen()
    // setTimeout(() => {
    //     setSelected(null)
    // }, 3500)
    //console.log("selected", Selected);

    let phone = Selected.phone;
    let id = Selected.application_id;
    let status = "approved";
    setStatus(status);

    UpdateApplicationStatus(
      phone,
      id,
      status,
      RejectReasons,
      handleClickOpen,
      handleUpdateErr,
      setSelected,
      setData,
      setUpdateLoading,
      setUpdateStatus,
      handleRejectDialogClose,
      setRejectReasons,
      Selected.loanamount,
      Selected.emi
    );
  };
  // console.log("ampunt ami",Selected.loanamount,Selected.emi);

  const handleRejectPost = () => {
    let phone = Selected.phone;
    let id = Selected.application_id;
    let status = "rejected";
    setStatus(status);
    UpdateApplicationStatus(
      phone,
      id,
      status,
      RejectReasons,
      handleClickOpen,
      handleUpdateErr,
      setSelected,
      setData,
      setUpdateLoading,
      setUpdateStatus,
      handleRejectDialogClose,
      setRejectReasons
    );
  };

  const admin_token = sessionStorage.getItem("admin_token");

  const handleReject = () => {
    // let phone = Selected.phone
    // let id = Selected.application_id
    // let status = "rejected"
    // UpdateApplicationStatus(phone, id, status, handleClickOpen,handleUpdateErr, setSelected, setData, setisLoading, setUpdateStatus)
    handleRejectDialogOpen();
  };

  // useEffect(()=>{
  //     UpdateApplicationStatus('9500494994', 14, 'pending', handleClickOpen,handleUpdateErr, setSelected, setData, setisLoading, setUpdateStatus)
  // },[])

  useEffect(() => {
    GetAllRequest(setData, setisLoading, ActiveIndex, "pending");
  }, [ActiveIndex]);

  const handlePaginate = (event, value) => {
    console.log(event);
    setActiveIndex(value-1);
  };

  // useEffect(()=>{
  //     GetAllRequest(setData,isLoading,ActiveIndex,'pending')
  // },[ActiveIndex])

  const [open, setOpen] = useState(false);
  const [StatusErr, setStatusErr] = useState(false);
  const [FilterData, setFIlterData] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setUpdateStatus(true);
    setUpdateLoading(true);
  };
  const handleUpdateErr = () => {
    setStatusErr(true);
  };
  const handlecloseErr = () => {
    setStatusErr(false);
  };
  const handleAlertClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (value) => {
    setFIlterData(value);
  };

  // const [ItemLength,setItemLength]=useState(0)

  //console.log("data ", data);
  //for downloading form

  const displaydata = [
    { name: "aadhar", displayname: "Aadhar", fieldtype: "small" },
    { name: "accname", displayname: "Account Holder Name", fieldtype: "small" },
    { name: "accountno", displayname: "Account Number", fieldtype: "small" },
    { name: "accounttype", displayname: "Account Type", fieldtype: "small" },
    {
      name: "addresscommunication",
      displayname: "Communication Address ",
      fieldtype: "large",
    },

    {
      name: "addresscommunicationpartner",
      displayname: "Communication Address (Partner)",
      fieldtype: "small",
    },
    { name: "age", displayname: "Age", fieldtype: "small" },
    { name: "allpartner", displayname: "All Partner", fieldtype: "small" },
    { name: "annualincome", displayname: "Annual Income", fieldtype: "small" },
    {
      name: "annualpercentage",
      displayname: "Annual Percentage Rate",
      fieldtype: "small",
    },
    {
      name: "availableperiod",
      displayname: "Available Period",
      fieldtype: "small",
    },
    {
      name: "balancetenture",
      displayname: "Balance Tenure",
      fieldtype: "small",
    },
    { name: "bankbranch", displayname: "Bank Branch", fieldtype: "small" },
    { name: "bankname", displayname: "", fieldtype: "small" },
    { name: "borrowertype", displayname: "", fieldtype: "small" },
    { name: "branch", displayname: "", fieldtype: "small" },
    { name: "commencementdate", displayname: "", fieldtype: "small" },
    { name: "companyaddress", displayname: "", fieldtype: "small" },
    { name: "companyname", displayname: "", fieldtype: "small" },
    { name: "cspaddress", displayname: "", fieldtype: "small" },
    { name: "cspcenter", displayname: "", fieldtype: "small" },
    { name: "cspcode", displayname: "", fieldtype: "small" },
    { name: "cspyear", displayname: "", fieldtype: "small" },
    { name: "dob", displayname: "", fieldtype: "small" },
    { name: "email", displayname: "", fieldtype: "small" },
    { name: "emailpartner", displayname: "", fieldtype: "small" },
    { name: "emailsole", displayname: "", fieldtype: "small" },
    { name: "emi", displayname: "", fieldtype: "small" },
    { name: "fatherspouse", displayname: "", fieldtype: "small" },
    { name: "feescharge", displayname: "", fieldtype: "small" },
    { name: "firm", displayname: "", fieldtype: "small" },
    { name: "ifsc", displayname: "", fieldtype: "small" },
    { name: "image", displayname: "", fieldtype: "small" },
    { name: "lendname", displayname: "", fieldtype: "small" },
    { name: "loanamount", displayname: "", fieldtype: "small" },
    { name: "loanid", displayname: "", fieldtype: "small" },
    { name: "loanpurpose", displayname: "", fieldtype: "small" },
    { name: "loanscheme", displayname: "", fieldtype: "small" },
    { name: "loantype", displayname: "", fieldtype: "small" },
    { name: "martailstatus", displayname: "", fieldtype: "small" },
    { name: "modeofrepayment", displayname: "", fieldtype: "small" },
    { name: "monthlyinstalment", displayname: "", fieldtype: "small" },
    { name: "name", displayname: "", fieldtype: "small" },
    { name: "nationality", displayname: "", fieldtype: "small" },
    { name: "overdueintrest", displayname: "", fieldtype: "small" },
    { name: "pan", displayname: "", fieldtype: "small" },
    { name: "partnershipfirm", displayname: "", fieldtype: "small" },
    { name: "permanentaddress", displayname: "", fieldtype: "small" },
    { name: "permanentaddresssole", displayname: "", fieldtype: "small" },
    { name: "phone", displayname: "", fieldtype: "small" },
    { name: "phonepartner", displayname: "", fieldtype: "small" },
    { name: "phonesole", displayname: "", fieldtype: "small" },
    { name: "postdatecheque", displayname: "", fieldtype: "small" },
    { name: "presentoutstand", displayname: "", fieldtype: "small" },
    { name: "principalplace", displayname: "", fieldtype: "small" },
    { name: "proofofincome", displayname: "", fieldtype: "small" },

    { name: "registeredoffice", displayname: "", fieldtype: "small" },
    { name: "repaymentfrequency", displayname: "", fieldtype: "small" },
    { name: "resaddress", displayname: "", fieldtype: "small" },
    { name: "resaddresstype", displayname: "", fieldtype: "small" },
    { name: "residingyear", displayname: "", fieldtype: "small" },
    { name: "roi", displayname: "", fieldtype: "small" },
    { name: "sanctiondate", displayname: "", fieldtype: "small" },
    { name: "soleproprietor", displayname: "", fieldtype: "small" },
    { name: "tenure", displayname: "", fieldtype: "small" },
    { name: "tenure1", displayname: "", fieldtype: "small" },
  ];

  const DownloadForm = async (index) => {
    //console.log("for downloading ",data);
    const formHtml = `
        <html>
    <head>
      <style>
        /* Add your custom CSS styles here */
        body {
          font-family: Arial, sans-serif;
          padding: 20px;
        }

        label {
          font-weight:550;
          background-color: white;
          font-size: 12px;
          margin-bottom: 5px;
          color:grey

        }

        .Container{
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }
        .Container2{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

        .item{
            display:flex;
            flex-direction:column;
            margin-top: 5px;
        }

        img{
            width: 100%;
            object-fit:contain;
            aspect-ratio:16/9
            max-width:none;
            max-height:none;
        }



        .category{
            text-align: center;
            font-size: 16px;
        }

        input{
            padding: 5px;
            border-radius: 3px;
            border:0.5px solid grey;
            width: 1fr;
            font-size:1em;
            line-height: 1.5;
            box-sizing: border-box;
            color:grey;
        }

        textarea{
            color:grey
        }



      </style>
    </head>
    <body>
      <div class="Container">
        <div class="item">
            <img src=${FILending}  alt="logo" />
          </div>
        </div>
      <h1 class="category">CSP Details</h1>
      <div class="Container">
        <div class="item">
            <label>Name of applicant</label>
            <input value="${data.data[index].name}"></input>
        </div>
        <div class="item">
            <label>Father / Spouse's Name</label>
            <input value="${data.data[index].fatherspouse}"></input>
        </div>
        <div class="item">
            <label>Dob</label>
            <input value="${data.data[index].dob}"></input>
        </div>
        <div class="item">
            <label>Gender</label>
            <input value="${
              data.data[index].gender === "M" ||
              data.data[index].gender === "Male"
                ? "Male"
                : data.data[index].gender === "F" ||
                  data.data[index].gender === "Female"
                ? "Female"
                : "Others"
            }"></input>
        </div>
        <div class="item">
            <label>Marital Status</label>
            <input value="${data.data[index].martailstatus}"></input>
        </div>
        <div class="item">
            <label>Nationality</label>
            <input value="${data.data[index].nationality}"></input>
        </div>
        <div class="item">
            <label>Aadhar Number</label>
            <input value="${data.data[index].aadhar}"></input>
        </div>
        <div class="item">
            <label>Pan Number</label>
            <input value="${data.data[index].pan}"></input>
        </div>
        <div class="item">
            <label>Contact No</label>
            <input value="${data.data[index].phone}"></input>
        </div>
        <div class="item">
            <label>Email</label>
            <input value="${data.data[index].email}"></input>
        </div>
        <div class="item">
            <label>CSP Code</label>
            <input value="${data.data[index].cspcode}"></input>
        </div>
      </div>
      <div class="Container2">
        <div class="item">
            <label>Bank Name</label>
            <input value="${data.data[index].bankname}"></input>
        </div>
        <div class="item">
            <label>Branch Name</label>
            <input value=${data.data[index].branch}></input>
        </div>
        <div class="item">
            <label>Residential Address</label>
            <textarea >"${data.data[index].resaddress}"</textarea>
        </div>
        <div class="item">
            <label>Residential Type</label>
            <input value="${data.data[index].resaddresstype}"></input>
        </div>
      </div>
      <div class="Container">
        <div class="item">
            <label>No of years at above address</label>
            <input value="${data.data[index].residingyear}"></input>
        </div>
        <div class="item">
            <label>CSP Centre Address</label>
        <input value="${data.data[index].cspaddress}"></input>
        </div>
        <div class="item">
            <label>No of years of CSP Center</label>
            <input value="${data.data[index].cspyear}"></input>
        </div>
        <div class="item">
            <label>Owned/Rented</label>
            <input value="${data.data[index].cspcenter}"></input>
        </div>
        <div class="item">
            <label>Annual Income</label>
            <input value="${data.data[index].annualincome}"></input>
        </div>
      </div>
      <br/>
      <h1 class="category">Banking Details</h1>
      <div class="Container2">
        <div class="item">
            <label>Name of Account Holder</label>
            <input value="${data.data[index].accname}"></input>
        </div>
        <div class="item">
            <label>Name of Bank and branch</label>
            <input value="${data.data[index].bankbranch}"></input>
        </div>
        <div class="item">
            <label>Account Number</label>
            <input value="${data.data[index].accountno}"></input>
        </div>
        <div class="item">
            <label>IFSC Code</label>
            <input value=${data.data[index].ifsc}></input>
        </div>
        <div class="item">
            <label>Type of Account</label>
            <input value=${data.data[index].accounttype}></input>
        </div>
      </div>
      <br/>
      <h1 class="category">Loan request details</h1>
      <div class="Container">
        <div class="item">
            <label>Purpose of Loan</label>
            <input value="${data.data[index].loanpurpose}"></input>
        </div>
        <div class="item">
            <label>Loan Amount</label>
            <input value=${data.data[index].loanamount}></input>
        </div>
        <div class="item">
            <label>Loan Scheme</label>
            <input value=${data.data[index].loanscheme}></input>
        </div>
        <div class="item">
            <label>Tenure (Months)</label>
            <input value=${data.data[index].tenure}></input>
        </div>
        <div class="item">
            <label>Rate of Interest (Reducing)</label>
            <input value=${data.data[index].roi}></input>
        </div>
        <div class="item">
            <label>EMI (INR)</label>
            <input value=${data.data[index].emi}></input>
        </div>
        <div class="item">
            <label>Fee / charges</label>
            <input value="${data.data[index].feescharge}"></input>
        </div>
        <div class="item">
            <label>Borrower type</label>
            <input value="${data.data[index].borrowertype}"></input>
         </div>

        <div class="item">
            <label>Referred by</label>
            <input value="${data.data[index].referred_by}"></input>
         </div>

      </div>
    </body>
</html>
    `;
    const options = {
      margin: 16,
      filename: `${data.data[index].name}.pdf`,
      image: { type: "jpeg", quality: 100 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const pdfBlob = await html2pdf().from(formHtml).set(options).output("blob");
    const imageRequest = Object.entries(data.data[index].file_urls).map(
      ([key, value]) =>
        value
          ? axios.post(
              `${process.env.REACT_APP_BASE_URL}/user/download`,
              { url: value },
              {
                headers: {
                  Authorization: sessionStorage.getItem("admin_token"),
                },
                responseType: "blob",
              }
            )
          : null
    );
    const imageResponses = await Promise.all(imageRequest);

    const zip = new JSZip();
    zip.file(`${data.data[index].name}.pdf`, pdfBlob, { binary: true });
    imageResponses.forEach((response, subIndex) => {
      const temp = Object.keys(data.data[index].file_urls)[subIndex];
      const split = temp.split("_");
      const imageName = `${split[0]}.jpg`;
      zip.file(imageName, response ? response.data : "null", { binary: true });
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${data.data[index].name}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  if (admin_token) {
    return isLoading ? (
      <LinearProgress />
    ) : (
      <div
        style={{
          backgroundColor: "#EEF2F6",
          paddingBlock: "10px",
          height: "100vh",
        }}
      >
        <RejectDialog
          open={OpenRejectDialog}
          handleClose={handleRejectDialogClose}
          rejectreason={RejectReasons}
          handleaddrejectReason={handleRejectReasonAdd}
          customreject={customReject}
          reasondelete={handleRejectReasonDelete}
          handlereject={handleRejectPost}
        />
        <MainCard
          sx={{ margin: "30px", height: "70vh", overflowY: "scroll" }}
          title={`Pending (${data.total_count})`}
        >
          <TextField
            placeholder="Search..."
            sx={{ mb: 1 }}
            onChange={(e) => handleFilterChange(e.target.value)}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
            }}
          />
          <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={handleAlertClose}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {UpdateStatus !== "" ? UpdateStatus : "Request Approved"}
            </Alert>
          </Snackbar>
          <Snackbar
            open={StatusErr}
            autoHideDuration={4000}
            onClose={handlecloseErr}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              onClose={handlecloseErr}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Something went wrong
            </Alert>
          </Snackbar>
          {Selected ? (
            <div style={{ height: "100%" }}>
              <Stack direction="row" alignItems="center">
                <Button sx={{ width: "20px" }} onClick={handleClose}>
                  <IoMdArrowBack style={{ color: "black", fontSize: "20px" }} />
                </Button>
                <Typography variant="subtitle1" color="grey">
                  {Selected.loanscheme}
                </Typography>
              </Stack>
              <TableContainer
                component={Paper}
                style={{ height: "50vh", overflow: "scroll" }}
              >
                <FieldsTable
                  page={"pending"}
                  userType={userType}
                  isLoading={updateLoading}
                  handleApprove={handleApprove}
                  handleReject={handleReject}
                  rows={rows}
                  selectedItem={Selected}
                  setSelectedItem={setSelected}
                  updatestatus={thisstatus}
                />
              </TableContainer>
            </div>
          ) : FilterData && FilterData !== "" ? (
            data&&data.data &&data.data.length > 0 ? (
              data.data.map(
                (item, index) =>
                  ((item.application_status === "pending" &&
                    (item.name
                      .toLowerCase()
                      .includes(FilterData.toLowerCase())||item.phone.includes(FilterData))) ||
                    !item.application_status) && (
                    <div
                      style={{
                        height: "100%",
                        width: window.innerWidth < 600 ? "100%" : "50%",
                        marginBottom: "5px",
                      }}
                    >
                      <SubCard
                        title={`Loan request for ${item.name}`}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <Stack direction="column">
                          <div></div>
                          <Stack direction="column">
                            <Typography
                              fontSize={14}
                              variant="subtitle1"
                              color="grey"
                            >
                              Date:{" "}
                              {new Date(item.created_at).toLocaleDateString(
                                "en-GB"
                              )}
                            </Typography>
                            <Typography
                              fontSize={14}
                              variant="subtitle1"
                              color="grey"
                            >
                              Time:{" "}
                              {new Date(item.created_at).toLocaleTimeString(
                                "en-GB"
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          {/* <Box width='50%' sx={{ display: window.innerWidth < 600 ? 'none' : 'flex' }}></Box> */}
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={() => DownloadForm(index)}
                          >
                            Download
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: "#A556B2" }}
                            onClick={() => handleSelect(index)}
                          >
                            View
                          </Button>
                        </Stack>
                      </SubCard>
                    </div>
                  )
              )
            ) : (
              <Typography variant="subtitle1" color="grey">
                No data
              </Typography>
            )
          ) : SliceData &&SliceData.length > 0 ? (
            SliceData.map(
              (item, index) =>
                (item.application_status === "pending" ||
                  !item.application_status) && (
                  <div
                    style={{
                      height: "100%",
                      width: window.innerWidth < 600 ? "100%" : "50%",
                      marginBottom: "5px",
                    }}
                  >
                    <SubCard
                      title={`Loan request for ${item.name}`}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      <Stack direction="column">
                        <div></div>
                        <Stack direction="column">
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            color="grey"
                          >
                            Date:{" "}
                            {new Date(item.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </Typography>
                          <Typography
                            fontSize={14}
                            variant="subtitle1"
                            color="grey"
                          >
                            Time:{" "}
                            {new Date(item.created_at).toLocaleTimeString(
                              "en-GB"
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        {/* <Box width='50%' sx={{ display: window.innerWidth < 600 ? 'none' : 'flex' }}></Box> */}
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => DownloadForm(index)}
                        >
                          Download
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ backgroundColor: "#A556B2" }}
                          onClick={() => handleSelect(index)}
                        >
                          View
                        </Button>
                      </Stack>
                    </SubCard>
                  </div>
                )
            )
          ) : (
            <Typography variant="subtitle1" color="grey">
              No data
            </Typography>
          )}
          <Paginate
            data={data ? data.total_count : 0}
            fun={handlePaginate}
            ActiveIndex={ActiveIndex}
          />
        </MainCard>
      </div>
    );
  } else window.location.href = "/AdminLogin";
}

export default PendingForm;
