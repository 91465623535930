import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Stack, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const RejectDialog = (props) => {
  const [customReason, setCustomReason] = useState(null);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // sx={{width:'20rem'}}
    >
      <DialogTitle id="alert-dialog-title">{"Reject"}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Box
            sx={{
              width: "30rem",
              border: "1px solid #252525",
              height: "150px",
              borderRadius: "5px",
              padding: "20px",
              display: "flex",
              gap: "3px",
              flexWrap: "wrap",
            }}
          >
            {props.rejectreason.map((item, index) => (
              <Chip
                label={item}
                key={index}
                onDelete={() => props.reasondelete(index)}
              />
            ))}
          </Box>
          <Box sx={{ minWidth: 120 }}>
            {props.customreject ? (
              <Stack>
                <TextField onChange={(e) => setCustomReason(e.target.value)} />
                <Button
                  onClick={() => props.handleaddrejectReason(customReason)}
                >
                  Add
                </Button>
              </Stack>
            ) : (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  onChange={(e) => props.handleaddrejectReason(e.target.value)}
                >
                  <MenuItem value={"Not fulfilled minimum financial criteria."}>
                    Not fulfilled minimum financial criteria.
                  </MenuItem>
                  <MenuItem value={"Credit Bureau Score not satisfactory."}>
                    Credit Bureau Score not satisfactory.
                  </MenuItem>
                  <MenuItem value={"Not completed min. 1 year as CSP."}>
                    Not completed min. 1 year as CSP.
                  </MenuItem>
                  <MenuItem value={"others"}>Others</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handlereject}>Reject</Button>
        <Button onClick={props.handleClose} autoFocus>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectDialog;
