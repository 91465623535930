import React from "react";
import { Box, Typography, Link } from "@mui/material";
import useStyles from "../styles/styles";

const Footer = () => {
  const date = new Date().getFullYear();
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Typography className={classes.footerText}>
        Provided by{" "}
        <Link target="_blank" underline="none" sx={{ cursor: "pointer" }}>
          FI Lending
        </Link>
      </Typography>
      <Typography className={classes.footerDate}>
        Copyright 2012-{date} FI Lending - All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
