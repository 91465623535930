import axios from "axios";

const baseurl = process.env.REACT_APP_BASE_URL;

// Logout

export const LogoutApi = async (
  handleLogout,
  handleErrLogout,
  setLogErr,
  token,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    // //console.log("api hit");
    await axios
      .post(
        `${baseurl}/auth/logout`,
        { data: "data" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          //console.log("otp sent");
          handleLogout();
          setIsLoading(false);
          window.location.href = "/";
          sessionStorage.clear();
        }
      });
  } catch (error) {
    // //console.log("error",error.response.data.message);
    setLogErr(error.response.data.message);
    setIsLoading(false);
    handleErrLogout();
  }
};

//Verify otp

export const Verifyphonepapi = async (
  mobile,
  handleOtpSent,
  handleErrOtp,
  handleIncorrectPhone,
  setIsLoading
) => {
  console.log("api hit 0");
  setIsLoading(true);
  try {
    console.log("api hit");
    await axios
      .post(
        `${baseurl}/auth/login`,
        { phone_no: mobile },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("api hit 2");
        if (res.status === 200) {
          //console.log("otp sent");
          handleOtpSent();
          setIsLoading(false);
        }
      });
  } catch (error) {
    console.log("api hit 3");
    console.log("error", error);
    if(error.response.data.message==="Incorrect Mobile Number"){
    console.log("error", error.response.data.message,);
    setIsLoading(false);
      // setOtpCheck(error.response.data.message)
      handleIncorrectPhone()
    // alert("Your mobile number not in our database")
    }else{
      setIsLoading(false);
      // setOtpCheck(error.response.data.message);
      handleErrOtp();
    }
  }
};

export const Verifyotpapi = async (
  mobile,
  otp,
  handleOtpSent,
  handleErrSent,
  setresErr,
  setIsphLoading
) => {
  setIsphLoading(true);
  try {
    await axios
      .post(
        `${baseurl}/auth/verify_login`,
        { phone_no: mobile, otp: otp },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("user_token"),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsphLoading(false);
          handleOtpSent();
          window.location.href = "/home";
          sessionStorage.setItem("user_token", res.data.token);
          sessionStorage.setItem("mobile_no", res.data.user_data.mobileno1);
          sessionStorage.setItem("email", res.data.user_data.email1);
          sessionStorage.setItem("csp_code", res.data.user_data.cspcode);
          sessionStorage.setItem("aadhar", res.data.user_data.aadhaarno);
          sessionStorage.setItem("fathername", res.data.user_data.fathername);
          sessionStorage.setItem("pincode", res.data.user_data.pincode);
          sessionStorage.setItem("address1", res.data.user_data.address1);
          sessionStorage.setItem("address2", res.data.user_data.address2);
          sessionStorage.setItem("dateofbirth", res.data.user_data.dateofbirth);
          sessionStorage.setItem(
            "user_name",
            res.data.user_data.cspbankaccountname
          );
          sessionStorage.setItem("user_gender", res.data.user_data.gender);
          sessionStorage.setItem("user_pancard", res.data.user_data.pancard);
        } else {
          setIsphLoading(false);
          handleErrSent();
          setresErr(res.data.error);
          //console.log("error",res.data.error);
        }
      });
  } catch (error) {
    if (error.response.status === 500) {
      handleErrSent();
      setIsphLoading(false);
      setresErr("Somthing went wrong, Please try again later");
    }
    // else if(error.response.status===500 || error.response.status===502){
    //   window.location.href='Servererror'
    //   setIsphLoading(false);
    // }
    else {
      handleErrSent();
      setIsphLoading(false);
      setresErr(error.response.data.error);
      //console.log("error",error.response.data.error);
    }
  }
};

// verify Email
export const VerifyEmailpapi = async (
  EmailId,
  handleMailOtpSent,
  setEmailresdata,
  handleMailErrOtp,
  setEmailOtpCheck,
  setIsLoadingEmail
) => {
  try {
    setIsLoadingEmail(true);
    await axios
      .post(
        `${baseurl}/auth/check_email`,
        { email: EmailId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoadingEmail(false);
          //console.log("otp sent");
          handleMailOtpSent();
          setEmailresdata(res.data.message);
        }
      });
  } catch (error) {
    // //console.log("error",error.response.data.message);
    if(error.response.status===500 || error.response.status===502){
      window.location.href='Servererror'
    }else{
      setIsLoadingEmail(false);
      setEmailOtpCheck(error.response.data.message);
      handleMailErrOtp();
    }
  }
};

// verify emil Otp
export const VerifyEmailotpapi = async (
  email,
  otp,
  handleEmailOtp,
  SetOtpEmailres,
  handleEmailErr,
  setEmailresErr
) => {
  try {
    await axios
      .post(
        `${baseurl}/auth/verify_email`,
        { email: email, otp: otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          handleEmailOtp();
          SetOtpEmailres(res.data.message);
          sessionStorage.setItem("setDisableEmail", true);
        } else {
          handleEmailErr();
          setEmailresErr(res.data.error);
          // //console.log("error",res.data.error);
        }
      });
  } catch (error) {
    if(error.response.status===500 || error.response.status===502){
      window.location.href='Servererror'
    }else{
      handleEmailErr();
      setEmailresErr(error.response.data.error);
    }
    // //console.log("error",error.response.data.error);
  }
};

// Aadhaar api
export const Aadharapi = async (
  aadhaar,
  handleaadhaarClick,
  handleaadhaarErr,
  setIsLoading
) => {
  console.log("enter Aadhar");
  try {
    setIsLoading(true);
    await axios
      .post(
        `${baseurl}/fi-lending-pan-idfy/aadharcard`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': 'JWT fefege...'
            "Aadhar-no": aadhaar,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          console.log("enter Aadhar");
          const url = res.data.redirect_capture_link;
          handleaadhaarClick();
          setIsLoading(false);
          console.log("aadhaar", aadhaar);
          window.location.href = url;
          sessionStorage.setItem("reference_id", res.data.reference_id);
          if (url) {
            sessionStorage.setItem("aadhar", aadhaar);
          }
          // setTimeout(() => {
          // }, 3000);
        }
      })
      .catch(() => {
        // //console.log("aadhar error");
        handleaadhaarErr();
        setIsLoading(false);
        // //console.log(error.response.data.error)
      });
  } catch (error) {
    if(error.response.status===500 || error.response.status===502){
      window.location.href='Servererror'
    }
    else{
      handleaadhaarErr();
      setIsLoading(false);
    }
    // handlePanErr()
    // //console.log("errpan", error.response.data.error);
  }
};

export const AadharData = async (setState, setPincode, setCity) => {
  const ref_id = sessionStorage.getItem("reference_id");
  console.log("ref_id", ref_id);
  try {
    await axios
      .post(
        `${baseurl}/fi-lending-pan-idfy/aadhar_data`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT fefege...",
            "Reference-id": ref_id,
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        setState(res.data.state);
        setPincode(res.data.pincode);
        setCity(res.data.dist);
        if (res.data) {
          sessionStorage.setItem("Aadharfield", true);
        }
        //   if(!sessionStorage.getItem('reloaded')){
        //     sessionStorage.setItem('reloaded',true)
        //     window.location.reload();
        // }
        // if(res.data.state){
        // }
      });
  } catch (error) {
    console.log("error", error);
  }
};

// admin user get aadhaar data
export const AdminAadharData = async (
  setIsLoading,
  setState,
  setPincode,
  setCity,
  setName,
  setDob,
  setGender,
  setAddress,
  ref_no
) => {
  console.log("ref_id", ref_no);
  setIsLoading(true)
  try {
    await axios
      .post(
        `${baseurl}/fi-lending-pan-idfy/aadhar_data`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT fefege...",
            "Reference-id": ref_no,
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        setState(res.data.state);
        setPincode(res.data.pincode);
        setCity(res.data.dist);
        setName(res.data.name);
        setDob(res.data.dob);
        setGender(res.data.gender);
        setAddress(res.data.street_address);
        if (res.data) {
          sessionStorage.setItem("Aadharfield", true);
          setIsLoading(false)

        }
        //   if(!sessionStorage.getItem('reloaded')){
        //     sessionStorage.setItem('reloaded',true)
        //     window.location.reload();
        // }
        // if(res.data.state){
        // }
      });
  } catch (error) {
    console.log("error", error);
    setIsLoading(false)
  }
};

// Pan card verify

export const Panapi = async (
  PanNo,
  dob,
  name,
  handlePanClick,
  handlePanErr,
  handlePanDobErr,
  setIsLoadingPan
) => {
  // //console.log("enter Pan");
  try {
    setIsLoadingPan(true);
    await axios
      .post(
        `${baseurl}/fi-lending-pan-idfy/pancard`,
        { id_number: PanNo, dob: dob, full_name: name },
        {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': 'JWT fefege...'
          },
        }
      )
      .then((res) => {
        // //console.log("PanNo",PanNo,UserName);
        if (res.status == 200) {
          const pan_string = res.data.pan_status;
          const splitFn = pan_string.split(".");
          setIsLoadingPan(false);
          console.log(splitFn[0]);
          if (pan_string === null) {
            setIsLoadingPan(false);
            handlePanErr();
          }
          if (splitFn[0] === "Existing and Valid" && !res.data.dob_match) {
            handlePanDobErr();
            setIsLoadingPan(false);
          }
          if (splitFn[0] === "Existing and Valid" && res.data.dob_match) {
            handlePanClick();
            setIsLoadingPan(false);
          }
        }
      })
      .catch(() => {
        // //console.log("pan error");
        setIsLoadingPan(false);
        handlePanErr();
        // //console.log(error.response.data.error)
      });
  } catch (error) {
    setIsLoadingPan(false);
    handlePanErr();
    //console.log("errpan", error.response.data.error);
  }
};

// credit score api
export const CreditApi = async (
  postdata,
  handleCreditRes,
  handleCredirErr,
  handleCreditErrdesc,
  setCreditErrDes,
  setCreditData,
  setClientId,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    await axios
      .post(`${baseurl}/credit/check_cibil`, postdata, {
        headers: {
          Authorization: sessionStorage.getItem("admin_token"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.CCRResponse.CIRReportDataLst[0].Error) {
            // console.log("error",res.data.CCRResponse.CIRReportDataLst[0].Error.ErrorDesc);
            setCreditErrDes(
              res.data.CCRResponse.CIRReportDataLst[0].Error.ErrorDesc
            );
            setIsLoading(false);
            handleCreditErrdesc();
          } else {
            handleCreditRes();
            setIsLoading(false);
            // console.log("res",res.data.CCRResponse.CIRReportDataLst[0]);
            setCreditData(res.data.CCRResponse.CIRReportDataLst[0]);
            setClientId(res.data.InquiryResponseHeader)
            console.log("success");
          }
        } else {
          setIsLoading(false);
          console.log("error");
          handleCredirErr();
        }
      });
  } catch (error) {
    if (error) {
      handleCredirErr();
      setIsLoading(false);
      console.log("error", error);
    }
  }
};

// POST API
export const PostApi = async (
  PostData,
  imagefile,
  idfile,
  cheque1,
  cheque2,
  ItrUpload,
  AadharImage,
  PanImage,
  poi,
  handleTokErrOpen,
  handleClick,
  setIsLoading,
  handleErrOpen,
  handleResErrOpen,
  setImageError,
  setImageErrorMessage
) => {
  // //console.log("enter post api",PostData);
  let maxsize = 2097152; //2MB
  let MaxItrsize = 10485760; //10MB
  const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/jpg"];

  console.log("poi api", poi, "pan ", PanImage);

  // console.log("image",imagefile);

  if (imagefile.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Photo size should be less than 2MB");
  } else if (idfile && idfile.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Id proof file size should be less than 2MB");
  } else if (cheque1 && cheque1.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Cheque 1 file size should be less than 2MB");
  } else if (cheque2 && cheque2.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Cheque2 file size should be less than 10MB");
  } else if (ItrUpload && ItrUpload.size > MaxItrsize) {
    setImageError(true);
    setImageErrorMessage("ItrUpload file size should be less than 2MB");
  } else if (AadharImage && AadharImage.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Aadhar file size should be less than 2MB");
  } else if (PanImage && PanImage.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Pan file size should be less than 2MB");
  } else if (poi && poi.size > maxsize) {
    setImageError(true);
    setImageErrorMessage("Proof of income file size should be less than 2MB");
  } else if (
    imagefile &&
    !["image/jpeg", "image/png", "image/jpg"].includes(imagefile.type)
  ) {
    console.log("type file", imagefile.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported Image file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    idfile &&
    !["image/jpeg", "image/png", "image/jpg"].includes(idfile.type)
  ) {
    console.log("type file", idfile.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported ID Proof file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    cheque1 &&
    !["image/jpeg", "image/png", "image/jpg"].includes(cheque1.type)
  ) {
    console.log("type file", cheque1.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported cheque1 file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    cheque2 &&
    !["image/jpeg", "image/png", "image/jpg"].includes(cheque2.type)
  ) {
    console.log("type file", cheque2.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported cheque2 file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    ItrUpload &&
    !["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
      ItrUpload.type
    )
  ) {
    console.log("type file", ItrUpload.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported ItrUpload file format. Only jpg, jpeg, png and pdf files are allowed."
    );
  } else if (
    AadharImage &&
    !["image/jpeg", "image/png", "image/jpg"].includes(AadharImage.type)
  ) {
    console.log("type file", AadharImage.type);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported Aadhar file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    PanImage &&
    !["image/jpeg", "image/png", "image/jpg"].includes(PanImage.type)
  ) {
    console.log("type file", PanImage.type);
    console.log("file", PanImage);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported Pan file format. Only jpg, jpeg, and png files are allowed."
    );
  } else if (
    poi &&
    !["image/jpeg", "image/png", "image/jpg"].includes(poi.type)
  ) {
    console.log("type file", poi.type);
    console.log("poi", poi);
    setImageError(true);
    setImageErrorMessage(
      "Unsupported Proof of Income file format. Only jpg, jpeg, and png files are allowed."
    );
  } else {
    const data = Object.entries(PostData).map(([key, value]) => {
      // //console.log(value);
      return Object.assign({ ...value });
    });

    const mergedObjectUsingMap = Object.assign({}, ...data.map((obj) => obj));
    const formdata = new FormData();
    formdata.append("profile_image", imagefile);
    formdata.append("id_proof", idfile);
    formdata.append("cheque1", cheque1);
    formdata.append("cheque2", cheque2);
    formdata.append("itr_upload", ItrUpload);
    formdata.append("aadhar_image", AadharImage);
    formdata.append("pan_image", PanImage);
    formdata.append("proof_of_income", poi);

    Object.entries(mergedObjectUsingMap).map(([key, value]) => {
      return formdata.append(key, value);
    });
    //console.log("data ",mergedObjectUsingMap);
    try {
      //console.log('data at post',formdata);
      setIsLoading(true);
      await axios
        .post(`${baseurl}/user/post_details`, formdata, {
          headers: {
            Authorization: sessionStorage.getItem("user_token"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            handleClick();
            sessionStorage.removeItem("Aadharfield");
            sessionStorage.removeItem("reference_id");
            sessionStorage.removeItem("ifsc_code");
            sessionStorage.removeItem("account_number");
            sessionStorage.removeItem("verifyaccButton");
            sessionStorage.removeItem("aadhar");
            sessionStorage.removeItem("request_id");
            sessionStorage.removeItem("setDisableEmail");
            sessionStorage.removeItem("setDisableButton");
            setTimeout(() => {
              window.location.href = "/home";
            }, 3000);
          } else if (res.message === "Token is invalid") {
            handleTokErrOpen();
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = "/";
            }, 3000);
          } else if (res.message === "Loan request already pending/approved") {
            handleResErrOpen();
            setIsLoading(false);
            setTimeout(() => {
              window.location.href = "/home";
            }, 3000);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          if (
            "Loan request already pending/approved" ===
            error.response.data.error
          ) {
            //console.log("gfdsa",error.response.data.error)
            handleResErrOpen();
            setIsLoading(false);
            setTimeout(() => {
              window.location.href = "/home";
            }, 4000);
          } else if ("Token is invalid" === error.response.data.message) {
            handleTokErrOpen();
            setIsLoading(false);
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = "/";
            }, 5000);
          } else {
            handleErrOpen();
          }
        });
    } catch (error) {
      handleErrOpen();
      setIsLoading(false);
      //console.log("post err",error);
      // //console.log("errorsdfgh",error);
    }
  }
};

// verify bank account number
export const BankAccNumberVerify = async (
  ifsc_code,
  account_number,
  handleSuccess,
  handleError,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    await axios
      .post(
        `${baseurl}/fi-idfy-bank-verification/bank/verify_bank`,
        {
          ifsc_code,
          account_number,
          task_id: sessionStorage.getItem("csp_code"),
          group_id: sessionStorage.getItem("csp_code"),
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("user_token")}`,
          },
        }
      )
      .then((res) => {
        const request_id = res.data.details.request_id;
        if (request_id) {
          sessionStorage.setItem("ifsc_code", ifsc_code);
          sessionStorage.setItem("account_number", account_number);
          sessionStorage.setItem("request_id", request_id);
          setTimeout(() => {
            VerifyBank_Request_id(handleSuccess, handleError, setIsLoading, 0);
          }, 5000);
          console.log("request_id", request_id);
          // handleClick();
          // sessionStorage.setItem("")
        }
      });
  } catch (error) {
    handleError();
    console.log("error", error);
  }
};

export const VerifyBank_Request_id = async (
  handleSuccess,
  handleError,
  setIsLoading,
  retry
) => {
  const request_id = sessionStorage.getItem("request_id");
  const delay = retry;
  try {
    await axios
      .post(
        `${baseurl}/fi-idfy-bank-verification/bank/task_details`,
        { request_id },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("user_token")}`,
          },
        }
      )
      .then((res) => {
        console.log(
          "res.data.details.result.status",
          res.data.details.result.status
        );
        if (res.data.details.result.status === "id_found") {
          sessionStorage.setItem("verifyaccButton", true);
          setIsLoading(false);
          console.log("res", res);
          handleSuccess();
        } else if (res.data.details.result.status === "id_not_found") {
          setIsLoading(false);
          handleError();
        }
      });
  } catch (error) {
    if (delay < 3) {
      setTimeout(() => {
        VerifyBank_Request_id(
          handleSuccess,
          handleError,
          setIsLoading,
          delay + 1
        );
      }, 3000);
    } else {
      setIsLoading(false);
      console.log("error", error);
      handleError();
    }
  }
};

export const NewRegisterAdd = async(setIsLoadingNewreg,setSuccess,setError,setOpen) => {
  setIsLoadingNewreg(true)
  try{
    await axios.post(`${baseurl}/user/register_new_user`)
    .then((res)=>{
      if(res.status===200){
        console.log(res);
        setSuccess(res.data.message)
        setIsLoadingNewreg(false)
        setOpen(false)
      }
    }).catch((error)=>{
    console.log(error);
      setError(error.response?error.response.data.error:'Something went wrong')
    setIsLoadingNewreg(false)
    })
  }
  catch(error){
    console.log(error);
    setError(error.response?error.response.data.error:'Something went wrong')
    setIsLoadingNewreg(false)
  }
}