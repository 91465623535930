import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

// admin login
export const AdminLoginapi = async (
  username,
  pwd,
  handleOtpSent,
  handleErrSent,
  setresErr,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    await axios
      .post(
        `${url}/auth/verify_login_admin`,
        { user_name: username, password: pwd },
        {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': 'Bearer '+sessionStorage.getItem('admin_token')
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.clear();
          handleOtpSent();
          setIsLoading(false);
          setTimeout(() => {
            window.location.href = "/admin";
          }, 2000);
          sessionStorage.setItem("admin_token", res.data.token);
        } else {
          handleErrSent();
          setIsLoading(false);
          setresErr(res.data.error);
          //console.log("error",res.data.error);
        }
      });
  } catch (error) {
    if (error.response&&error.response.status === 500) {
      handleErrSent();
      setIsLoading(false);
      setresErr("Somthing went wrong, Please try agin later");
    } else {
      handleErrSent();
      setIsLoading(false);
      setresErr(error.response.data.error);
      //console.log("error",error.response.data.error);
    }
  }
};

// edit Loan details
export const LoanUpdateapi = async (
  LoanPurpose,
  Emi,
  Tenure,
  LoanScheme,
  Number,
  AppId,
  handleEditClick,
  handleEditErr
) => {
  //console.log("enter Aadhar");
  try {
    await axios
      .post(
        `${url}/user/update_product`,
        {
          phone_number: Number,
          application_id: AppId,
          loanpurpose: LoanPurpose,
          emi: Emi,
          tenure: Tenure,
          loanscheme: LoanScheme,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("admin_token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          handleEditClick();
          setTimeout(() => {
            // sessionStorage.setItem("setEditButton", true)
            window.location.reload();
          }, 3000);
        }
      })
      .catch(() => {
        // //console.log("aadhar error");
        handleEditErr();
        // //console.log(error.response.data.error)
      });
  } catch (error) {
    handleEditErr();
    // //console.log("errpan", error.response.data.error);
  }
};

// get all request
export const GetAllRequest = async (
  setData,
  setisLoading,
  active,
  category
) => {
  try {
    await axios
      .get(`${url}/user/retrieve_data`, {
        headers: {
          Authorization: sessionStorage.getItem("admin_token"),
        },
        params: {
          off_set: (active === 0 ? active : active - 1) * 20,
          category: category,
        },
      })
      .then((res) => {
        setData(res.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "Token is invalid") {
          sessionStorage.clear();
          window.location.href = "/adminlogin";
        }
      });
  } catch (error) {
    console.log(error);
  }
};

export const GetRequestByToken = async (token, setData, isLoading) => {
  try {
    await axios
      .get(`${url}/user/retrieve_user_data`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setData(res.data);
        isLoading(false);
      });
  } catch (error) {
    //console.log(error);
    isLoading(false);
  }
};

export const ProvideApproval = async (
  userId,
  productId,
  approverId,
  postData,
  setData,
  handleClose
) => {
  // //console.log("PostData api ",postData);
  try {
    await axios.put(
      `${url}/users/${userId}/products/${productId}/approval`,
      postData,
      {
        params: {
          approver_user_id: approverId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    GetAllRequest(setData);
    handleClose();
  } catch (error) {
    // //console.log(error);
  }
};

export const UpdateApplicationStatus = async (
  phone,
  id,
  status,
  string,
  handleClickOpen,
  handleUpdateErr,
  setSelected,
  setData,
  setisLoading,
  setUpdateStatus,
  handleRejectDialogClose,
  setRejectReasons,
  amount,
  emi
) => {
  console.log("string ", string);
  setisLoading(true);
  let postString = string && Array.isArray(string) ? string.join("\n") : null;
  try {
    await axios
      .post(
        `${url}/user/update_application_status`,
        {
          phone_number: phone,
          application_id: id,
          new_status: status,
          loan_amount: amount,
          emi_amount: emi,
          rejection_string: postString ? postString : "",
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("admin_token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleClickOpen();
          setUpdateStatus(response.data.message);
          setRejectReasons([]);
          setSelected(null);
          handleRejectDialogClose();
          GetAllRequest(setData, setisLoading, 0, "pending");
          setisLoading(false);
        } else {
          setRejectReasons([]);
          handleUpdateErr();
          setisLoading(false);
          handleRejectDialogClose();
        }
      });
    console.log(postString);
  } catch (err) {
    // console.log("err.response.data.message=",err.response.data.message);
    if (err.response.data.message === "Token is invalid") {
      sessionStorage.clear();
      window.location.href = "/AdminLogin";
    } else {
      handleUpdateErr();
      setisLoading(false);
    }
    console.log("err", err);
  }
};

export const GetImage = async (
  string,
  setselectedItem,
  setOpenImage,
  handleResImage,
  handleResCloseImage,
  handleErrImgae
) => {
  handleResImage();
  // console.log("admin_token", sessionStorage.getItem("admin_token"));
  try {
    const response = await axios.post(
      `${url}/user/download`,
      { url: string },

      {
        headers: {
          Authorization: sessionStorage.getItem("admin_token")
            ? sessionStorage.getItem("admin_token")
            : sessionStorage.getItem("user_token"),
        },
        responseType: "arraybuffer",
      }
    );
    //console.log(response);
    // handleResImage()
    if (response.status === 200) {
      setOpenImage(true);
      handleResCloseImage();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const imageUrl = URL.createObjectURL(blob);
      setselectedItem(imageUrl);
    }
  } catch (err) {
    console.log("eoeemfwkds", err.response.status);
    if (err.response.status === 401) {
      sessionStorage.clear();
      window.location.href = "/AdminLogin";
    } else {
      handleErrImgae();
    }
    console.error("Error fetching image:", err);
  }
};
export const ExpiredDocumentsApi = async(setData) => {
  try{
    await axios.get(`${url}/user/get_expired_documents`,{
      headers:{
        Authorization: sessionStorage.getItem("admin_token")
      }
    })
    .then((res)=>{
      if(res.status===200){
        console.log("res.data.application_submission_response",res.data.application_submission_response);

        setData(res.data)
      }
    })
    .catch((error)=>{
      console.log("error",error);

    })
  }
  catch(error){
    console.log("error",error)
  }
}

export const RegenrateExpiredDocumentsApi = async(setIsLoading,Phone,ID, Data,handleClick,setRes,handleError) => {
  setIsLoading(true)
  const datefor = Data.date
  const formatDate = (inputDate) => {
    const date = new Date(inputDate); // Parse the input date string
    const day = String(date.getDate()).padStart(2, '0'); // Extract day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Extract month (getMonth() returns 0-11)
    const year = date.getFullYear(); // Extract year

    return `${day}-${month}-${year}`; // Return formatted date
  };

  const formattedDate = formatDate(datefor);
  try{
    await axios.post(`${url}/user/regenerate_document`,{
      phone_number:Phone,
      application_id:ID,
      current_date:formattedDate
    },{
      headers:{
        Authorization: sessionStorage.getItem("admin_token")
      }
    })
    .then((res)=>{
      if(res.status===200){
        setIsLoading(false)
        handleClick()
        setRes(res.data.message)
        console.log("res",res);
      }
    })
    .catch((error)=>{
      setIsLoading(false)
      handleError()
      setRes(error.response?error.response.data.error:'something went wrong')
    })
  }
  catch(error){
    setIsLoading(false)
    handleError()
    setRes(error.response?error.response.data.error:'something went wrong')
    console.log("error",error);
  }
}











// // Function to upload Excel documents
// export const UploadExcelDocumentsApi = async (file) => {
//   const formData = new FormData();
//   formData.append("file", file);

//   try {
//     const res = await axios.post("https://backendapiuat.filending.in/fi-equifax-data-upload/upload", formData, {
//       headers: { "Content-Type": "multipart/form-data" },
//     });

//     if (res.status === 200) {
//       const successMessage = res.data.message || "Upload successful!";
      
//       // Assuming the response contains the data you want to export
//       const responseData = res.data; // Adjust this according to your actual response structure
      
//       // Exporting the response data to a text file
//       exportToTextFile(responseData); // Call the function to export data
      
//       return successMessage; // Return the success message
//     }
//   } catch (error) {
//     const errorMessage = error.response?.data?.message || "Error uploading data.";
//     throw new Error(errorMessage);
//   }
// };

// // Function to export data to a text file
// const exportToTextFile = (data) => {
//   // Convert the data to a string format
//   const textData = typeof data === 'object' ? JSON.stringify(data, null, 2) : data; // Format as JSON if it's an object
//   const blob = new Blob([textData], { type: 'text/plain' });
//   const url = URL.createObjectURL(blob);
  
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = 'exportedData.txt'; // Specify the name of the downloaded file
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
//   URL.revokeObjectURL(url); // Clean up the URL object
// };




// // Function to upload Excel documents
// export const UploadExcelDocumentsApi = async (file) => {
//   const formData = new FormData();
//   formData.append("file", file);

//   try {
//     const res = await axios.post(
//       "https://backendapiuat.filending.in/fi-equifax-data-upload/upload",
//       formData,
//       { headers: { "Content-Type": "multipart/form-data" } }
//     );

//     if (res.status === 200) {
//       const successMessage = res.data.message || "Upload successful!";
//       return res.data; // Return the entire response data for further use
//     }
//   } catch (error) {
//     const errorMessage = error.response?.data?.message || "Error uploading data.";
//     throw new Error(errorMessage);
//   }
// };







// Function to upload Excel documents
export const UploadExcelDocumentsApi = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await axios.post(
      "https://backendapiuat.filending.in/fi-equifax-data-upload/upload",
      formData // No need to set Content-Type, Axios does it automatically
    );

    if (res.status === 200) {
      const successMessage = res.data.message || "Upload successful!";
      return res.data; // Return the entire response data for further use
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Error uploading data.";
    console.error("Upload error:", error); // Log the entire error for debugging
    throw new Error(errorMessage);
  }
};
