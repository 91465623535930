import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthRegister from "./LendingForm/FormStructure.js";
import AuthLogin from "./LendingForm/FormStructure.js/Login.js";
import ExistingDetails from "./LendingForm/FormStructure.js/ExistingDetails.js";
import BorrowerDetails from "./LendingForm/FormStructure.js/BorrowerDetails.js";
import Home from "./LendingForm/FormStructure.js/Home";
import AdminLogin from "./LendingForm/FormStructure.js/AdminLogin.js";
import ApprovalLayout from "./LendingForm/FormFields/ApprovalLayout.js";
import LoanStatus from "./LendingForm/FormStructure.js/Components/";
import NotFoundPage from "./LendingForm/NotFoundPage.js";
import ServerError from "./LendingForm/ServerError.js";
import ExcelForm from "./LendingForm/FormStructure.js/Home/ExcelForm.js";



function App() {
  const Token = sessionStorage.getItem("user_token");
  return (
    <>
      <Router>
        {/* <Header /> */}
        <Routes>
          {!Token ? <Route exact path={"/"} element={<AuthLogin />} /> : null}
          <Route exact path={"/loan"} element={<AuthRegister />} />
          <Route exact path={"/home"} element={<Home />} />
          <Route
            exact
            path={"/existingdetails"}
            element={<ExistingDetails />}
          />
          <Route
            exact
            path={"/borrowerdetails"}
            element={<BorrowerDetails />}
          />
          <Route exact path={"/loanstatus"} element={<LoanStatus />} />
          <Route exact path={"/AdminLogin"} element={<AdminLogin />} />
          <Route exact path={"/Admin"} element={<ApprovalLayout />} />
          <Route exact path={"*"} element={<NotFoundPage />} />
          <Route exact path={"/Servererror"} element={<ServerError />} />
          <Route exact path={"/excelform"} element={<ExcelForm />} /> 
          

        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
