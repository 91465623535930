import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Section from "./Section";
import AboutUs from "./AboutUs";
import Testimonial from "./Testimonial";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import EmiCalculator from "./EmiCalculator";
import { Typography } from "@mui/material";

const index = () => {
  return (
    <>
      <Header />
      <Hero />
      {/* <Typography variant="h4" textAlign={"center"} ml={10} mb={2} fontWeight={700} >EMI Calculator</Typography>
      <EmiCalculator/> */}
      {/* <Section /> */}
      {/* <AboutUs /> */}
      {/* <Testimonial />
      <ContactUs /> */}
      <Footer />
    </>
  );
};

export default index;
