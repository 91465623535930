import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  FormHelperText,
  FormLabel,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { AdminLoginapi } from "../../Axios/Approval/api";

const AdminLoginBody = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setErrors] = useState(false);
  const Navigate = useNavigate();

  const handleLoginVerify = (username, pwd) => {
    AdminLoginapi(
      username,
      pwd,
      handleOtpSent,
      handleErrSent,
      setresErr,
      setIsLoading
    );

    // else{
    //   sessionStorage.clear();
    //   sessionStorage.setItem('admin_token', 'dwdasd');
    //   sessionStorage.setItem('user_id', userid);
    //   Navigate('/Admin')
    // }
  };
  const [AdminRes, setAdminRes] = useState(false);
  const [AdminErr, setAdminErr] = useState(false);
  const [resErr, setresErr] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const handleOtpSent = () => {
    setAdminRes(true);
    setIsLoading(false);
  };

  const handleErrSent = () => {
    setAdminErr(true);
    setIsLoading(false);
  };

  const handleClickErr = () => {
    setAdminErr(false);
  };
  const handleClickres = () => {
    setAdminRes(false)(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrors(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          phone: "",
          otp: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string("Must be a number").required(
            "UserId number is required"
          ),
          otp: Yup.string().required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (values) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (values) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Stack direction="row">
              <Snackbar
                open={AdminErr}
                autoHideDuration={6000}
                onClose={handleClickErr}
                message="Note archived"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert variant="filled" severity="error">
                  Invalid credentials
                </Alert>
              </Snackbar>
              <Snackbar
                open={AdminRes}
                autoHideDuration={6000}
                onClose={handleClickres}
                message="Note archived"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert variant="filled" severity="success">
                  Login successfully
                </Alert>
              </Snackbar>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={12} sm={10} md={10} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      sx={{ marginTop: "25px" }}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-email-register">Mobile Number</InputLabel> */}
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        id="outlined-error"
                        type="text"
                        label="User Id&#42;"
                        // disabled={props.verified.phone}
                        value={values.phone}
                        size="large"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        inputProps={{}}
                        sx={{ mb: 1 }}
                      />
                      {Boolean(touched.phone && errors.phone) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-phone-register"
                        >
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={10} md={10} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.otp && errors.otp)}
                      sx={{ marginBottom: "10px" }}
                    >
                      <TextField
                        error={errors.otp && touched.otp}
                        id="outlined-error"
                        type="password"
                        size="large"
                        value={values.otp}
                        name="otp"
                        label="Password&#42;"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        // inputProps={{}}
                      />
                      {Boolean(touched.otp && errors.otp) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-otp-register"
                        >
                          {errors.otp}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Stack>
            <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"center"}>
              <LoadingButton
                disableElevation
                disabled={isSubmitting || !isValid}
                fullWidth
                size="large"
                type="submit"
                loading={IsLoading}
                variant="contained"
                sx={{
                  backgroundColor: "#A556B2",
                  width: "50%",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#A556B2" },
                }}
                onClick={() => {
                  handleLoginVerify(values.phone, values.otp);
                }}
              >
                Login
              </LoadingButton>
            </Box>
              
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdminLoginBody;
