import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
  Box,
  FormHelperText,
  FormLabel,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  debounce,
} from "@mui/material";
import { Formik, Field } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Verifyotpapi, Verifyphonepapi } from "../../Axios/ServiceApi";

const BankDetails = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [OtpWait, setOtpWait] = useState(false);
  const [error, setErrors] = useState(false);
  const [sent, setSent] = useState(false);
  const [ErrOtp, setErrOtp] = useState(false);
  const [ErrSent, setErrSent] = useState(false);
  const [UserSent, setUserSent] = useState(false);
  const [resErr, setresErr] = useState(false);
  const [OtpCheck, setOtpCheck] = useState(false);
  console.log("OtpCheck",OtpCheck);
  const [NotFalse, setNotFalse] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsphLoading, setIsphLoading] = useState(false);
  const [IncorrectPhone,setIncorrectPhone] = useState(false)
  const Navigate = useNavigate();

  // const userId = "9876543210"
  // const password = "012345"

  // const handleLogin = (userphone, userotp) => {
  //   if(userId !== userphone || password !== userotp){
  //     handleErrClick()
  //   }
  //   else{
  //     Navigate('/home')
  //   }
  // }

  // const handleErrClick = () => {
  //   setErrors(true);
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrors(false);
    setErrOtp(false);
    setSent(false);
    setErrSent(false);
    setresErr(false);
    setIncorrectPhone(false)
  };

  const handleOtpSent = () => {
    setSent(true);
    setOtpWait(false);
    setNotFalse(true);
    setIsLoading(false);
  };

  const handleSent = () => {
    setUserSent(true);
    setOtpWait(false);
    setIsphLoading(false);
  };

  const handleErrOtp = () => {
    setErrOtp(true);
    setOtpCheck(true)
    setOtpWait(false);
    setIsLoading(false);
  };

  const handleIncorrectPhone = () => {
    setIncorrectPhone(true)
  }
  const handleErrSent = () => {
    setErrSent(true);
    setIsphLoading(false);
  };

  const handleVerifyMobile = debounce((value) => {
    //console.log("called");
    setOtpCheck("");
    Verifyphonepapi(
      value,
      handleOtpSent,
      handleErrOtp,
      handleIncorrectPhone,
      setIsLoading
    );
    setOtpWait(true);
  }, 2000);

  const handleVerifyOtp = debounce((mobile, otp) => {
    setresErr("");
    Verifyotpapi(
      mobile,
      otp,
      handleSent,
      handleErrSent,
      setresErr,
      setIsphLoading
    );
  }, 2000);

  const [remainingTime, setRemainingTime] = useState(120);
  useEffect(() => {
    if (NotFalse) {
      //console.log("timer open");
      const interval = setInterval(() => {
        if (remainingTime === 120) {
          setRemainingTime(remainingTime - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [sent]);

  useEffect(() => {
    if (NotFalse) {
      //console.log("timer open");
      const interval = setInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime(remainingTime - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [remainingTime]);

  return (
    <>
      <Formik
        initialValues={{
          phone: "",
          otp: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string("Must be a number")
            .matches(/^[0-9]{1,10}$/, "Invalid Mobile Number format")
            .matches(/^(\d)(?!\1{9}$)\d{9}$/, "Invalid Mobile Number format")
            .matches(/^[6-9]\d{9}$/, "Invalid Mobile Number format")
            .min(10, "Phone number must have min 10 digit ")
            .max(10, "Phone number must have max 10 digit")
            .required("Phone number is required"),
          otp: Yup.string()
            .required("Otp is required")
            .matches(/^[0-9]{1,6}$/, "Invalid Otp format")
            .min(6, "Otp must have 6 digit"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (values) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (values) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Snackbar
              open={error}
              autoHideDuration={6000}
              onClose={handleClose}
              message="Note archived"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="error">
                Invalid credentials
              </Alert>
            </Snackbar>
            <Snackbar
              open={ErrOtp}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="error">
                {OtpCheck !== "" ? OtpCheck : "Something went wrong!!!"}
              </Alert>
            </Snackbar>
            <Snackbar
              open={ErrSent}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="error">
                {resErr !== "" ? resErr : "Something went wrong!!!"}
              </Alert>
            </Snackbar>
            <Snackbar
              open={IncorrectPhone}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="error">
              Your mobile number is not in our database
              </Alert>
            </Snackbar>
            <Snackbar
              open={UserSent}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="success">
                Successfully Login
              </Alert>
            </Snackbar>
            <Snackbar
              open={sent}
              autoHideDuration={2000}
              onClose={handleClose}
              message="Note archived"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert variant="filled" severity="success">
                Otp Sent
              </Alert>
            </Snackbar>
            <Stack direction="row">
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={12} sm={10} md={10} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      sx={{ marginTop: "25px" }}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-email-register">Mobile Number</InputLabel> */}
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        id="outlined-error"
                        type="text"
                        label="Mobile Number&#42;"
                        // disabled={props.verified.phone}
                        value={values.phone}
                        size="large"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        inputProps={{}}
                        sx={{ mb: 1 }}
                      />
                      {Boolean(touched.phone && errors.phone) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-phone-register"
                        >
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Stack direction="row">
                      <Box
                        width="100%"
                        sx={{ visibility: OtpWait ? "visible" : "hidden" }}
                      >
                        Wait few minutes for the OTP
                      </Box>
                      <Box
                        width="190%"
                        sx={{ visibility: NotFalse ? "visible" : "hidden" }}
                        color="#F64336"
                      >
                        {/* OTP expires: {remainingTime} seconds */}
                        Your OTP expire in 2 Mintues
                      </Box>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="end"
                      >
                        <div style={{ width: "50%" }}></div>

                        {remainingTime === 120 ? (
                          <LoadingButton
                            variant="contained"
                            size="small"
                            loading={IsLoading}
                            sx={{
                              width: "10rem",
                              marginBlock: "3px",
                              color: "#fff",
                              backgroundColor: "#A556B2",
                              "&:hover": { backgroundColor: "#A556B2" },
                              marginBottom: "10px",
                            }}
                            onClick={() => handleVerifyMobile(values.phone)}
                            disabled={
                              values.phone.length < 10 ||
                              values.phone.length > 10 ||
                              errors.phone ||
                              isSubmitting
                            }
                          >
                            {" "}
                            Send OTP
                          </LoadingButton>
                        ) : (
                          <Button
                            disabled={remainingTime > 0}
                            variant="contained"
                            size="small"
                            sx={{
                              width: "10rem",
                              marginBlock: "3px",
                              color: "#fff",
                              backgroundColor: "#A556B2",
                              "&:hover": { backgroundColor: "#A556B2" },
                              marginBottom: "10px",
                              float: "right",
                            }}
                            width="50%"
                            onClick={() => handleVerifyMobile(values.phone)}
                          >
                            Resend OTP
                          </Button>
                        )}
                      </Box>
                    </Stack>
                    {/* {remainingTime > 0 || NotFalse ? ( */}
                    {/* {NotFalse ? <Typography textAlign="right" mt={2}>
                        Time Remaining: {remainingTime} seconds
                      </Typography> : null } */}

                    {/* ) : ( */}
                    {/* <Typography textAlign="right" mt={2}>
                        Didn't receive the OTP? Resend.
                      </Typography> */}
                    {/* )} */}
                    {/* <Button disabled={remainingTime > 0} width="50%" sx={{float:"right"}} onClick={() => handleVerifyMobile(values.phone)}>Resend OTP</Button> */}
                  </Grid>
                  <Grid item xs={12} sm={10} md={10} mb={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.otp && errors.otp)}
                      sx={{ marginBottom: "10px" }}
                    >
                      <TextField
                        error={errors.otp && touched.otp}
                        id="outlined-error"
                        type="text"
                        size="large"
                        value={values.otp}
                        name="otp"
                        label="Otp&#42;"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        // inputProps={{}}
                      />
                      {Boolean(touched.otp && errors.otp) && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-otp-register"
                        >
                          {errors.otp}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* <Stack direction="row" >
              <Box width="100%"></Box>
              <Box width="100%" display="flex" flexDirection="row" alignItems="end">
                <div style={{ width: '50%' }}></div>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: '10rem', marginBlock: '3px' , color: '#fff',
                  backgroundColor: '#A556B2',"&:hover": { backgroundColor: "#A556B2" }}}
                >
                  Verify Otp
                </Button>
              </Box>
            </Stack> */}
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Stack>
            <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"center"}>
              <LoadingButton
                disableElevation
                disabled={isSubmitting || !isValid}
                fullWidth
                size="large"
                type="submit"
                loading={IsphLoading}
                variant="contained"
                sx={{
                  backgroundColor: "#A556B2",
                  width: "50%",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#A556B2" },
                }}
                onClick={() => {
                  handleVerifyOtp(values.phone, values.otp);
                }}
              >
                Login
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default BankDetails;
