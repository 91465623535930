import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import myteam from "../images/Landing page-amico.png";
import useStyles from "../styles/styles";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const classes = useStyles();
  const Navigate = useNavigate();
  const admintoken = sessionStorage.getItem("admin_token");

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Let's scale your business
          </Typography>
          {/* <Typography variant="h6" className={classes.subtitle}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et lobortis neque. Morbi egestas, risus eget elementum faucibus, elit risus fermentum diam, in vehicula dui purus in quam. Pellentesque convallis vel augue commodo hendrerit. Curabitur elementum molestie tortor. Fusce elementum quis tortor vel rhoncus. Morbi eu lectus tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer luctus mi eget aliquam ullamcorper. Nunc euismod massa id libero luctus, sed sagittis nisl ultrices.
          </Typography> */}
          <Button
            variant="contained"
            color="secondary"
            sx={{ fontSize: "16px", display: admintoken ? "none" : "content" }}
            onClick={() => Navigate("/loan")}
          >
            Click on apply loan
          </Button>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={myteam} alt="My Team" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
